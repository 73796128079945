import React from 'react'
import { SelectProductType } from './SelectProductType'
import CreateQuoteClan from './CreateQuoteClan/Createquote'
//import CreateQuoteMotshelo from './CreateQuoteMotshelo/Createquote'
import CreateQuoteMonamodi from './CreateQuoteMonamodi/Createquote'
import { isFeatureFlagEnabled } from '../../../../lib/access.es6'
import CreateQuoteMotshelo from './CreateQuoteMotshelo/Createquote'

export const CreateQuoteSwitch = (props) => {
  const [productType, setProductType] = React.useState('')

  const ProductTypes = {
    Monamodi: 'Monamodi Funeral Plan',
    FamilyClan: 'Family Clan Cover',
    Motshelo: 'Motshelo Group Cover'
  };

  const FLAG_NAME = 'ACCESS_BANK_CLAN_PRODUCTS';
  const clan_flag = isFeatureFlagEnabled(FLAG_NAME)

  if(!clan_flag){
    return <CreateQuoteMonamodi {...props} productType={ProductTypes.Monamodi} createQuoteError={props.createQuoteError} />
  }


  if (!productType) {
    return <SelectProductType setProductType={setProductType} productType={productType} />
  } else {
    switch (productType) {
      case ProductTypes.Monamodi:
        return <CreateQuoteMonamodi {...props} productType={productType}  setProductType={setProductType} createQuoteError={props.createQuoteError}  />

      case ProductTypes.FamilyClan:
        return <CreateQuoteClan {...props} productType={productType} setProductType={setProductType} createQuoteError={props.createQuoteError}  />

      case ProductTypes.Motshelo:
        return <CreateQuoteMotshelo {...props} productType={productType} setProductType={setProductType} createQuoteError={props.createQuoteError}  />

      default:
        return <SelectProductType setProductType={setProductType} productType={productType} />
    }
  }
}
