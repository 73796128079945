
import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SelectPlan.css'
import { white } from 'material-ui/styles/colors';
import { getPartner } from '../../../../../../lib/access.es6';
import { COMPONENT_TYPES, familyClanCoverPremiumRates } from '../../../helpers/index';

export default function SelectPlan({plans, hasParentsPlan, selectedPlan, setPlan, setCurrentComponent, setParentsPlan, coverType}) {
 const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    console.log("plan selected", value)
    setPlan(value);
    setSelected(value)
    setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT)
  }


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };



  const currencyCode = getPartner().country.currency_code


  return (
    <div style={{ background: 'light grey', padding: '2%' }}>
        <h2 style={{
            fontFamily: 'Open Sans',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "15px",
            textAlign: "center"
          }}>Select Family Clan Cover</h2>

          <Slider {...settings}>
            {familyClanCoverPremiumRates.map((cover, coverIndex) => {
              const firstPremiumValue = cover.premiums[0].premium_amount;

              return (

                <div key={coverIndex}>
                  <div
                    onClick={() => handleSelect(cover)}
                    style={{
                      color: (selected && selected.cover_amount === cover.cover_amount) ? white : '#bdbdbd',
                      fontSize: 20,
                      transition: 'all 0.3s ease-in-out',
                      cursor: 'pointer',
                      margin: '8%',
                      paddingBottom: "10px",
                      textAlign: 'center',
                      background: (selected && selected.cover_amount === cover.cover_amount) ? '#FBA92D' : '#032A37',
                      boxShadow: "0px 2px 8px rgba(99, 99, 99, 0.2)",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{
                      textTransform: 'uppercase',
                      fontFamily: 'Open Sans',
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      lineHeight: "15px",
                      color: "#FFFFFF",
                      padding: "10px"
                    }}> {cover.option}</div>
                    <hr style={{ margin: '0px' }} />
                    <div style={{
                      fontFamily: 'Open Sans',
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#FFFFFF",
                      padding: "8px"
                    }}>
                      premium starting from : {currencyCode}  {firstPremiumValue} </div>

                  </div>
                  <hr />
                </div>
              );
            })}
          </Slider>
    </div>
  )
}
