import React from 'react';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@material-ui/pickers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";

import AppBar from '@material-ui/core/AppBar';
import LocalizedStrings from 'react-localization';
import { Button } from '@material-ui/core';
import MainPageTitle from '../../../../components/MainPageTitle/index.es6';
import * as actions from '../../../ClaimSubmission/actions.es6';
import NoRecord from '../../../../components/NoRecord/index.es6';
import { hasAuthourity, getUserGuid, getPartnerGuid } from '../../../../lib/access.es6';
import Claims from '../../../../components/Claims/index.es6';
import * as globalActions from '../../../Main/actions.es6';
import {
  buildFailureMessage,
  formatDate,
  formatStatus,
} from '../../../../lib/utils.es6';
import { localisedText } from '../../../../lib/localisation.es6';
import {
  updateCurrentLanguage,
  translateArray,
} from '../../../../lib/languageUtils.es6';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../../lib/constants';
import {BENEFIT_NAMES} from "../../../NewProductList/PoductConstants";

export class MakeClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital_admission_date: null,
      hospital_discharge_date: null,
      start_date: null,
      end_date: null,
      event_date: null,
      selectedClaimType: '',
      selectedClaimRelationToMainMember: '',
      finished: false,
      stepIndex: 0,
      claimData: {},
      validationErrors: null,
      deathDate: null,
      labels: new LocalizedStrings(localisedText),
      tabValue: 0,
      ignoreMaxDaysAllowedAfterDischargeOrDeath: false,
      person_affected_dob: null,
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.showCustomerBar());
    this.props.getPolicies()
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimsSubmission',
      this.state.labels
    );
  }

  componentDidMount() {
    const requestData = this.props.claimRequestData;
    if (
      requestData.types.length <= 1 ||
      requestData.relationToMainMember.length <= 1
    ) {
      this.props.dispatch(actions.claimEnumRequest());
    }
  }

  toggleMakeClaimDialog(){
    this.props.toggleMakeClaimDialog()
  }

  getCustomerClaimTypes() {
    const claimTypes = new Set();
    if (!this.props.currentCustomerData.currentCustomer) {
      return []
    }
    console.log(this.props.customerScreenData);
    const policies = this.props.customerScreenData.policies || [];

    for (let i = 0; i < policies.length; i++) {
      const benefits = policies[i].benefits || [];
      for (let j = 0; j < benefits.length; j++) {
        const benefitClaimType = benefits[j].type
        claimTypes.add(benefitClaimType);
      }
    }
    return ["Please Select"].concat(Array.from(claimTypes));
  }

  selectClaimType() {
    this.setState({
      selectedClaimType: document.getElementById('claimTypes').value,
    });
  }

  selectClaimRelationToMainMember() {
    this.setState({
      selectedClaimRelationToMainMember: document.getElementById(
        'claimRelationToMainMember'
      ).value,
    });
  }

  // TODO: We should remove the error message when a type is selected
  //  * Validation start mis-behaving when we move back from the next screen.
  //      Likely because there is an existing state, which current validation ignores.
  //  NOTE: This is doing more than validation (also updating payload).
  isValidClaimType() {
    const data = this.state.claimData;
    const labels = this.state.labels;
    const isValid = document.getElementById('claimTypes').value.trim() !== labels.defaultSelection;

    if (isValid) {
      data.type = document.getElementById('claimTypes').value.trim();
    } else {
      this.setState({ validationErrors: labels.errorMissingClaimType });
    }
    return isValid;
  }

  isValidClaimRelation() {
    const data = this.state.claimData;
    const labels = this.state.labels;
    const isValid = document.getElementById('claimRelationToMainMember').value.trim() !== labels.defaultSelection

    if (isValid) {
      data.relation_to_main_member = document
        .getElementById('claimRelationToMainMember')
        .value.trim();
    } else {
      this.setState({ validationErrors: labels.errorMissingClaimRelation });
    }
    return isValid;
  }

  isValidPersonAffected() {
    const data = this.state.claimData;
    const labels = this.state.labels;
    let isValid = document.getElementById('person_affected') && document.getElementById('person_affected').value;

    if (isValid) {
      data.person_affected = document
        .getElementById('person_affected')
        .value.trim();
    } else {
      isValid = this.state.selectedClaimRelationToMainMember !== 'Child';
      if (!isValid) {
        this.setState({
          validationErrors: labels.errorMissingAffectedPerson,
        });
      }
    }
    return isValid;
  }

  isValidHospitalClaim() {
    const data = this.state.claimData;
    const labels = this.state.labels;

    if (document.getElementById('claimTypes').value.trim() !== 'Hospital') {
      return true;
    }

    if (this.state.hospital_admission_date) {
      data.hospital_admission_date = this.state.hospital_admission_date;
    } else {
      this.setState({
        validationErrors: labels.errorMissingHospitalAdmission,
      });
      return false;
    }

    if (this.state.hospital_discharge_date) {
      data.hospital_discharge_date = this.state.hospital_discharge_date;
    } else {
      this.setState({
        validationErrors: labels.errorMissingHospitalDischarge,
      });
      return false;
    }

    return true;
  }

  isValidLossOfIncomeClaim() {
    const data = this.state.claimData;
    const labels = this.state.labels;

    if (document.getElementById('claimTypes').value.trim() !== 'Income_Loss') {
      return true;
    }

    if (this.state.start_date) {
      data.start_date = this.state.start_date;
    } else {
      this.setState({
        validationErrors: labels.errorMissingStartDate,
      });
      return false;
    }

    if (this.state.end_date) {
      data.end_date = this.state.end_date;
    } else {
      this.setState({
        validationErrors: labels.errorMissingEndDate,
      });
      return false;
    }

    return true;
  }

  isValidDeathClaim() {
    const data = this.state.claimData;
    const labels = this.state.labels;

    if (
     ['Death'].indexOf(document.getElementById('claimTypes').value.trim()) < 0
    ) {
      return true;
    }

    if (this.state.deathDate) {
      data.death_date = this.state.deathDate;
    } else {
      this.setState({ validationErrors: labels.errorMissingDeathDate });
      return false;
    }

    if (getPartnerGuid() === 'fnbzambiaguid' && ['Child', 'Spouse'].indexOf(this.state.selectedClaimRelationToMainMember) >= 0) {
      if (this.state.person_affected_dob) {
        data.person_affected_dob = this.state.person_affected_dob;
      } else {
        this.setState({ validationErrors: labels.errorMissingAffectedPersonDob });
        return false;
      }
    }
    return true;
  }

  isValidFixedIndemnityClaim() {
    const data = this.state.claimData;
    const labels = this.state.labels;
    const claimType = document.getElementById('claimTypes').value.trim();

    if (claimType === 'Death' || !BENEFIT_NAMES.FIXED.includes(claimType)) {
      return true;
    }

    if (this.state.event_date) {
      data.event_date = this.state.event_date;
    } else {
      this.setState({ validationErrors: labels.errorMissingEventDate });
      return false;
    }

    return true;
  }

  // TODO: Validation logic isn't update when claim type changes
  //        e.g. Get to the point of missing death date, then switch to hospital claim, error message stays the same; and we can't do anything :(
  handleNext() {
    const { stepIndex } = this.state;
    const data = this.state.claimData;
    let isValid = false;

    switch (stepIndex) {
      case 0:
        // TODO: Take an array of validation method as input
        isValid = this.isValidClaimType();
        if (!isValid) {
          return;
        }

        isValid = this.isValidClaimRelation();
        if (!isValid) {
          return;
        }

        isValid = this.isValidPersonAffected();
        if (!isValid) {
          return;
        }

        isValid = this.isValidHospitalClaim();
        if (!isValid) {
          return;
        }

        isValid = this.isValidDeathClaim();
        if (!isValid) {
          return;
        }

        isValid = this.isValidLossOfIncomeClaim();
        if (!isValid) {
          return;
        }

        isValid = this.isValidFixedIndemnityClaim();
        if (!isValid) {
          return;
        }

        break;

      case 1: {
        const payload = this.state.claimData;
        payload.customer_guid = this.props.currentCustomerData.currentCustomer.guid;
        payload.editor_guid = getUserGuid(); // NOTE: This isn't needed, backend doesn't trust client ;)
        let args = {};
        args.payload = payload;

        if (this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath) {
          args.ignoreMaxDaysAllowed = true;
        }
        this.props.dispatch(actions.claimSubmissionRequest(args));
        break;
      }
      default:
        return;
    }

    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 1,
      claimData: data,
      validationErrors: null,
    });
  }

  handleTabValueChange(event, newValue) {
    this.setState({ tabValue: newValue });
  }

  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1, finished: false });
    }
  }

  toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath(event) {
    this.setState({
      ignoreMaxDaysAllowedAfterDischargeOrDeath: !this.state
        .ignoreMaxDaysAllowedAfterDischargeOrDeath,
    });
  }

  labelForIgnoreMaxDaysAllowedCheckbox() {
    const { selectedClaimType, labels } = this.state;

    if (selectedClaimType === 'Hospital') {
      return labels.ignoreMaxDaysAllowedAfterDischarge;
    }

    if (['Death', 'Funeral'].indexOf(selectedClaimType) >= 0) {
      return labels.ignoreMaxDaysAllowedAfterDeath;
    }
  }

  canIgnoreMaxDaysAllowed() {
    return (['Death', 'Funeral', 'Hospital'].indexOf(this.state.selectedClaimType) >= 0 &&
      hasAuthourity('IGNORE_MAX_DAYS_ALLOWED_AFTER_DISCHARGE_OR_DEATH')
    );
  }

  failureMessage() {
    const labels = this.state.labels;
    const defaultErrorMessage = labels.defaultErrorMessage;
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.claimRequestData.errors
    );
    return errorMessage;
  }

  // TODO: Move this in a helper & reduce duplication
  displayUserFeedback() {
    const resultError = {
      display: this.props.claimRequestData.errors ? 'block' : 'none',
    };

    const resultSuccess = {
      display: this.props.claimRequestData.claimInfo ? 'block' : 'none',
    };

    if (this.props.claimRequestData.loader) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
    return (
      <div>
        <center style={resultError}>
          {' '}
          <p style={{ color: 'red' }}>{this.failureMessage()} </p>{' '}
        </center>
        <center style={resultSuccess}>
          {' '}
          <p style={{ color: 'green' }}>{this.successMessage()}</p>{' '}
        </center>
        <center
          style={{ display: this.state.validationErrors ? 'block' : 'none' }}
        >
          {' '}
          <p style={{ color: 'red' }}>{this.state.validationErrors}</p>{' '}
        </center>
      </div>
    );
  }

  getStepContent(index) {
    const labels = this.state.labels;
    const claimTypes = this.getCustomerClaimTypes() || [];
    switch (index) {
      case 0:
        return (
          <div className="x_panel">
            <div className="x_content">
              <br />
              <form
                id="claimForm"
                className="form-horizontal form-label-left"
                noValidate
              >
                <div className="form-group">
                  <label
                    htmlFor="claimTypes"
                    className="control-label col-md-4 col-sm-4 col-xs-4"
                  >
                    {labels.claimType}
                    <span className="required">*</span>
                  </label>
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <select
                      className="form-control"
                      defaultValue={
                        this.state.claimData.type != null
                          ? this.state.claimData.type
                          : labels.defaultSelection
                      }
                      id="claimTypes"
                      onChange={this.selectClaimType.bind(this)}
                    >
                      {translateArray(
                        labels,
                        claimTypes
                      ).map((type, count) => (
                        <option key={count} value={type}>{type.split("_").join(" ")}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="relation_to_main_member"
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                  >
                    {labels.relationToMainMember}
                    <span className="required">*</span>
                  </label>
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <select
                      className="form-control"
                      defaultValue={
                        this.state.claimData.relationToMainMember != null
                          ? this.state.claimData.relationToMainMember
                          : labels.defaultSelection
                      }
                      id="claimRelationToMainMember"
                      onChange={this.selectClaimRelationToMainMember.bind(this)}
                    >
                      {translateArray(
                        labels,
                        this.props.claimRequestData.relationToMainMember
                      ).map((relationToMainMember, i) => (
                        <option key={i}>{relationToMainMember}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {this.state.selectedClaimRelationToMainMember === 'Child' ? (
                  <div className="form-group">
                    <label
                      htmlFor="person_affected"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.personAffected}
                      <span className="required">*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <input
                        id="person_affected"
                        name="person_affected"
                        required
                        className="form-control col-md-7 col-xs-12"
                        type="text"
                        value={this.state.claimData.person_affected}
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                {['Child', 'Spouse'].indexOf(this.state.selectedClaimRelationToMainMember) >= 0
                  && getPartnerGuid() === 'fnbzambiaguid' ? (
                  <div className="form-group">
                    <label
                      htmlFor="person_affected_dob"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.personAffectedDob}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.person_affected_dob}
                        onChange={this.dateHandler.bind(
                          this,
                          'person_affected_dob'
                        )}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                {/* TODO: Split this in components, maybe by claim type */}

                <div
                  style={{
                    display:
                      this.state.selectedClaimType === 'Hospital'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="hospital_admission_date"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.hospitalAdmissionDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.hospital_admission_date}
                        onChange={this.dateHandler.bind(
                          this,
                          'hospital_admission_date'
                        )}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="hospital_discharge_date"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.hospitalDischargeDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.hospital_discharge_date}
                        onChange={this.dateHandler.bind(
                          this,
                          'hospital_discharge_date'
                        )}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display:
                      this.state.selectedClaimType === 'Income_Loss'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="start_date"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.startDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.start_date}
                        onChange={this.dateHandler.bind(
                          this,
                          'start_date'
                        )}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="end_date"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.endDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.end_date}
                        onChange={this.dateHandler.bind(
                          this,
                          'end_date'
                        )}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display:
                      ['Death'].indexOf(this.state.selectedClaimType) >= 0
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="deathDate"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.deathDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.deathDate}
                        onChange={this.dateHandler.bind(this, 'deathDate')}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display:
                      BENEFIT_NAMES.FIXED.includes(
                        this.state.selectedClaimType
                      ) && !['Death'].includes(this.state.selectedClaimType)
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="event_date"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {labels.eventDate}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <DatePicker
                        value={this.state.event_date}
                        onChange={this.dateHandler.bind(this, 'event_date')}
                        format="YYYY-MM-DD"
                        placeholder={labels.defaultSelection}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: this.canIgnoreMaxDaysAllowed()
                      ? 'block'
                      : 'none',
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="deathDate"
                      className="control-label col-md-4 col-sm-4 col-xs-12"
                    >
                      {this.labelForIgnoreMaxDaysAllowedCheckbox()}
                      <span>*</span>
                    </label>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <Checkbox
                        id="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                        name="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                        onChange={this.toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath.bind(this)}
                        required
                        checked={this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath}
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      case 1: {
        const labelStyle = { width: '200px' };
        return (
          <div className="x_panel">
            <div className="x_content">
              <div style={{ paddingLeft: '37%' }}>
                <label style={labelStyle}>{labels.claimType}</label> :{' '}
                <label style={labelStyle}>{this.state.claimData.type}</label>
                <br />
                {this.state.selectedClaimRelationToMainMember === 'Child' ? (
                  <div>
                    <label style={labelStyle}>{labels.personAffected}</label> :{' '}
                    <label style={labelStyle}>
                      {this.state.claimData.person_affected}
                    </label>
                  </div>
                ) : (
                  <div />
                )}
                <br />
                <label style={labelStyle}>
                  {labels.relationToMainMember}
                </label>{' '}
                :{' '}
                <label style={labelStyle}>
                  {this.state.claimData.relation_to_main_member}
                </label>
                <br />

                <div
                  style={{
                    display:
                      this.state.selectedClaimType === 'Hospital'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label style={labelStyle}>
                    {labels.hospitalAdmissionDate}
                  </label>{' '}
                  :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.hospital_admission_date}{' '}
                  </label>
                  <br />
                  <label style={labelStyle}>
                    {labels.hospitalDischargeDate}
                  </label>{' '}
                  :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.hospital_discharge_date}
                  </label>
                  <br />
                </div>

                <div
                  style={{
                    display:
                      this.state.selectedClaimType === 'Income_Loss'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label style={labelStyle}>
                    {labels.startDate}
                  </label>{' '}
                  :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.start_date}{' '}
                  </label>
                  <br />
                  <label style={labelStyle}>
                    {labels.endDate}
                  </label>{' '}
                  :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.end_date}
                  </label>
                  <br />
                </div>

                <div
                  style={{
                    display:
                      ['Death'].indexOf(this.state.selectedClaimType) >= 0
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label style={labelStyle}>{labels.deathDate}</label> :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.death_date}{' '}
                  </label>
                  <br />
                </div>

                <div
                  style={{
                    display:
                      BENEFIT_NAMES.FIXED.includes(
                        this.state.selectedClaimType
                      ) && !['Death'].includes(this.state.selectedClaimType)
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label style={labelStyle}>{labels.eventDate}</label> :{' '}
                  <label style={labelStyle}>
                    {this.state.claimData.event_date}{' '}
                  </label>
                  <br />
                </div>

                <div
                  style={{
                    display:
                      this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath === true
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label style={labelStyle}> {this.labelForIgnoreMaxDaysAllowedCheckbox()} </label>
                  <label style={labelStyle}>
                    <Checkbox
                      id="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                      name="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                      readOnly
                      checked={this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath}
                      color="primary"
                    />{' '}
                  </label>
                  <br />
                </div>

              </div>
            </div>
          </div>
        );
      }
      case 2: {
        const claimInfo = this.props.claimRequestData.claimInfo;
        const benefits = claimInfo ? claimInfo.benefits : [];
        const feedback = claimInfo ? claimInfo.feedback : '';
        const feedbackArray = feedback.split('&');
        const nightsApplies = this.state.selectedClaimType === 'Hospital';
        const benefitColumnLabel = nightsApplies ? 'nightlyBenefit' : 'benefit';

        return (
          <div>
            <AppBar position="static" style={{ backgroundColor: '#ff8349' }}>
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabValueChange.bind(this)}
                variant="fullWidth"
                centered
              >
                <Tab fullWidth label={labels.outcome} />
                <Tab fullWidth label={labels.notes} />
              </Tabs>
            </AppBar>

            {this.state.tabValue === 0 && (
              <div className="x_panel">
                <div className="x_content">
                  <form className="form-horizontal form-label-left" noValidate>
                    {this.claimRequestResultHeader(claimInfo)}
                    <p
                      style={{
                        display: benefits.length === 0 ? 'block' : 'none',
                      }}
                    >
                      {labels.noPoliciesFound}
                    </p>
                    <table
                      className="table table-striped table-hover"
                      style={{
                        display: benefits.length === 0 ? 'none' : 'block',
                      }}
                    >
                      <thead>
                        <tr>
                          <th>{labels.productName}</th>
                          <th>{labels.benefit}</th>
                          <th
                            style={{
                              display: nightsApplies ? 'block' : 'none',
                            }}
                          >
                            {labels.nights}
                          </th>
                          <th>{labels[benefitColumnLabel]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {benefits.map(benefit => (
                          <tr key={benefit.guid}>
                            <td>{benefit.product_name}</td>
                            <td>{formatStatus(benefit.type)}</td>
                            <td
                              style={{
                                display: nightsApplies ? 'block' : 'none',
                              }}
                            >
                              {benefit.night ? benefit.night : 'N/A'}
                            </td>
                            <td>{benefit.amount_benefit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            )}

            {this.state.tabValue === 1 && (
              <div className="x_panel">
                <div className="x_content">
                  <p>
                    {feedbackArray.map((value, index) => (
                      <p key={index}>{value} </p>
                    ))}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
      default:
        return "You're a long way from home sonny jim!";
    }
  }

  // TODO: Localise the labels below
  claimRequestResultHeader(claimInfo) {
    const labels = this.state.labels;
    if (claimInfo) {
      return (
        <div>
          <p>
            {labels.claimReference}: # {claimInfo.guid}
          </p>
          <p>
            {labels.claimStatus}: {formatStatus(claimInfo.status)}
          </p>
        </div>
      );
    }
    return null;
  }

  successMessage() {
    const labels = this.state.labels;
    let successMessage = labels.successClaimSubmit;
    const policyPurchaseInfo = this.props.currentCustomerData.claimInfo;
    if (policyPurchaseInfo && policyPurchaseInfo.message) {
      successMessage = this.props.currentCustomerData.claimInfo.message;
    }
    return successMessage;
  }

  // TODO: type value formatting should be consistent
  dateHandler(type, moment) {
    if (type === 'hospital_admission_date') {
      this.setState({ hospital_admission_date: formatDate(new Date(moment)) });
    } else if (type === 'hospital_discharge_date') {
      this.setState({ hospital_discharge_date: formatDate(new Date(moment)) });
    } else if (type === 'deathDate') {
      this.setState({ deathDate: formatDate(new Date(moment)) });
    } else if (type === 'start_date') {
      this.setState({ start_date: formatDate(new Date(moment)) });
    } else if (type === 'end_date') {
      this.setState({ end_date: formatDate(new Date(moment)) });
    } else if (type === 'event_date') {
      this.setState({ event_date: formatDate(new Date(moment)) });
    } else if (type === 'person_affected_dob') {
      this.setState({ person_affected_dob: formatDate(new Date(moment)) });
    }
  }

  resetForm(e) {
    e.preventDefault();
    this.setState({
      stepIndex: 0,
      finished: false,
      claimData: {},
      selectedClaimType: '',
      deathDate: '',
      hospital_admission_date: '',
      hospital_discharge_date: '',
    });
    this.props.dispatch(actions.claimSubmissionReset());
  }

  displayClaimForm() {
    // TODO: Move this style into a css class
    const { finished, stepIndex } = this.state;
    const labels = this.state.labels;
    const contentStyle = { margin: '0 16px' };
    const openClaims = (this.props.currentCustomerData.claims || []).filter(
      claim => claim.open === true
    );
    if (
      !this.props.currentCustomerData ||
      !this.props.currentCustomerData.currentCustomer
    ) {
      return (
        <NoRecord
          noTextLabel={labels.titleNoCustomer}
          searchLabel={labels.searchCustomer}
          redirectTo="admin/search"
        />
      );
    }
    return (
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div style={{ marginLeft: '2%' }}>
          <MainPageTitle pageTitle={labels.makeClaim} />
        </div>
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>{labels.claimDetailsTitle}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{labels.claimSummaryTitle}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{labels.claimResultsTitle}</StepLabel>
          </Step>
        </Stepper>
        {this.displayUserFeedback()}
        <div style={contentStyle}>
          {finished ? (
            <center>
              {this.getStepContent(stepIndex)}
              <Button variant="contained" onClick={this.resetForm.bind(this)}>
                {' '}
                {labels.resetMakeClaims}{' '}
              </Button>
              <div style={{ marginTop: 12 }}>
                <Button
                  disabled={stepIndex === 0}
                  onClick={this.handlePrev.bind(this)}
                  style={{ marginRight: 12 }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {labels.backMakeClaims}
                </Button>

              </div>
            </center>
          ) : (
            <div>
              <div>{this.getStepContent(stepIndex)}</div>
              <div style={{ marginTop: 12 }}>
                <Button
                  disabled={stepIndex === 0}
                  onClick={this.handlePrev.bind(this)}
                  style={{ marginRight: 12 }}
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                  {labels.backMakeClaims}
                </Button>
                <Button
                  onClick={this.handleNext.bind(this)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {
                    stepIndex === 2
                      ? labels.submitMakeClaims
                      : labels.nextMakeClaims
                  }
                </Button>
              </div>
              <br />
              <Claims
                columnWidth="col-md-12"
                extended
                title={labels.currentOpenClaims}
                showMore={false}
                claims={openClaims}
              />
            </div>
          )}
        </div>

        <hr />
      </div>
    );
  }

  render() {
    const labels = this.state.labels
    return (
      <div>
        <Dialog open={true} fullWidth fullScreen maxWidth='lg' onClose={this.toggleMakeClaimDialog.bind(this)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.toggleMakeClaimDialog.bind(this)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {labels.makeAClaim}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div className="row">{this.displayClaimForm()}</div>
          </DialogContent>
        </Dialog>

      </div>
    );
  }
}

export default connect(state => ({
  claimRequestData: state.claimRequest,
  currentCustomerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
  globalData: state.global,
}))(MakeClaim);
