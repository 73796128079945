import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { Box, CircularProgress, DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";
import MultipleSelectChip from '../../PartnerStatus/components/Edit/MultipleSelectChip';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getPartnerGuid } from '../../../lib/access.es6';
import { BankData } from '../../../partner-apps/AccessBank/lib/BankData';
const AddUser = (props) => {

	const [firsName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [userName, setUserName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmpassword] = useState('');
	const [allRolesdata, setAllRoles] = useState(props.allRoles);
	const [currentRoles, setCurrentRoles] = useState([]);
	const [allPartnersdata] = useState(props.allPartners);
	const [currentPartners, setCurrentPartners] = useState([]);

	const [isAgentCheck, setIsAgentCheck] = useState(false)
	const [openBranches, setOpenBranches] = useState(false);
	const [inputBranchValue, setInputBranchValue] = useState('');


	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') {
			setFirstName(value)
		}
		if (name === 'lastname') {
			setLastName(value)
		}
		if (name === 'Username') {
			setUserName(value)
		}
		if (name === 'email') {
			setEmail(value)
		}
		if (name === 'password') {
			setPassword(value)
		}
		if (name === 'confirmpassword') {
			setConfirmpassword(value)
		}

	}

	const handleBranchInputChange = (event, newValue) => {
		setInputBranchValue(newValue);
	};

	const handleIsAgentChange = (event) => {
		setIsAgentCheck(event.target.checked);
	};

	useEffect(() => { setAllRoles(props.allRoles) }, [props.allRoles])
	useEffect(() => {
		ValidatorForm.addValidationRule('isvalidemail', (email) => {
			//eslint-disable-next-line
			return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		});

		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			return password === confirmPassword
		});

		ValidatorForm.addValidationRule('isvalidpassword', (password) => {
			return password.match(
				/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
			);
		});

		return function cleanup() {
			ValidatorForm.removeValidationRule('isvalidemail');
			ValidatorForm.removeValidationRule('isPasswordMatch');
			ValidatorForm.removeValidationRule('isvalidpassword');
		}
	});

	const handleSubmit = () => {

		const payload = {
			"username": userName,
			"groups": [
				...currentRoles.map((role) => role.path.substring(1))
			],
			"lastName": lastName,
			"firstName": firsName,
			"email": email,
			"attributes": {
				"partners": isAgentCheck ? ['accessbank'] : [...currentPartners.map((partner) => partner.guid)],
				"isAgent": isAgentCheck ? isAgentCheck : false,
				"branch": inputBranchValue || "N/A",
			},
			"enabled": true,
			"credentials": [
				{
					"type": "password",
					"value": password,
					"temporary": false
				}
			]
		}
		console.log("add user payload", payload)
		props.addUser(payload)
	}
	const reset = () => {
		setFirstName('');
		setLastName('');
		setUserName('');
		setEmail('');
		setPassword('');
		setConfirmpassword('');
		setCurrentRoles([]);
		setCurrentPartners([]);
	}
	const handleUseCreatedSuccessfully = () => {
		props.resetAddUserSuccessAlert();
		reset();
		props.closeAddUserDialog();
		props.refreshUsers();
	}
	return (
		<>
			<Dialog
				open={props.openAddUserDialog} fullWidth
				maxWidth='lg'
				onClose={props.closeAddUserDialog}
				fullScreen
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
						<IconButton
							edge="start"
							color="inherit"
							onClick={props.closeAddUserDialog}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Create a User
						</Typography>
					</Toolbar>
				</AppBar>

				<DialogContent>
					<div style={{ marginTop: "2%" }}>
						<Box sx={{ width: "100%" }}>
							<AlertDialog
								custom
								show={props.showAddUserProgressAlert}
								size="sm"
								style={{ marginTop: '0', top: '30vh' }}
								confirmBtnText={'ok'}
								showCancel={false}
								showConfirm={false}
								cancelBtnText={'cancel'}
								showTitle={false}
								confirmBtnCssClass
							>
								<CircularProgress />
								<h2>Adding User</h2>
							</AlertDialog>
							<AlertDialog
								success
								show={props.showAddUserSuccessAlert}
								size="sm"
								title={'User added'}
								style={{ marginTop: '0', top: '30vh' }}
								onConfirm={() => { handleUseCreatedSuccessfully() }}
								confirmBtnText={'ok'}
								showCancel={false}
								showConfirm={false}
								cancelBtnText={'cancel'}
								confirmBtnCssClass
							>
								<div >
									<Button variant="contained" onClick={() => { handleUseCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
								</div>
							</AlertDialog>
							{
								getPartnerGuid() === 'accessbank' && <Grid container spacing={2}>
									<Grid item xs={12} md={12} sm={12}>
										<FormGroup aria-label="position" row>
											<FormControlLabel
												checked={isAgentCheck}
												onChange={handleIsAgentChange}
												control={<Checkbox size="small" />}
												label="Is Agent?"
												labelPlacement="start"
											/>
										</FormGroup>
									</Grid>
								</Grid>
							}

							<ValidatorForm onSubmit={() => handleSubmit()}  >
								<Grid container spacing={2}>

									<Grid item md={4} sm={4} xs={12}>
										<TextValidator
											onChange={handleChange}
											label={'First name'}
											value={firsName}
											name='name'
											fullWidth
											variant="outlined"
											type="text"
											placeholder='First name'
											validators={['required']}
											errorMessages={['Please enter first name']}
										/>
									</Grid>
									<Grid item md={4} sm={4} xs={12}>
										<TextValidator
											onChange={handleChange}
											label={'Last Name'}
											value={lastName}
											name='lastname'
											fullWidth
											variant="outlined"
											type="text"
											placeholder='Last Name'
											validators={['required']}
											errorMessages={['Please enter last name']}
										/>
									</Grid>
									<Grid item md={4} sm={4} xs={12}>

										<TextValidator
											onChange={handleChange}
											label={'Username'}
											value={userName}
											name='Username'
											fullWidth
											variant="outlined"
											type="text"
											placeholder='Username'
											validators={['required']}
											errorMessages={['Please enter user name']}
										/>
									</Grid>
									<Grid item md={4} sm={4} xs={12}>
										<TextValidator
											onChange={handleChange}
											label={'Email'}
											value={email}
											name='email'
											fullWidth
											variant="outlined"
											type="email"
											placeholder='Email'
											validators={['required', 'isvalidemail']}
											errorMessages={['Please enter an email address', 'Please enter a valid email address']}
										/>
									</Grid>
									<Grid item md={4} sm={4} xs={12}>
										<TextValidator
											onChange={handleChange}
											label={'Password'}
											value={password}
											id='password'
											name='password'
											fullWidth
											variant="outlined"
											type="password"
											placeholder='Password'
											validators={['required', 'isvalidpassword']}
											errorMessages={['Please enter password', 'Please make sure your password has at least a number, and at least a special character.']}
										/>
									</Grid>
									<Grid item md={4} sm={4} xs={12}>
										<TextValidator
											onChange={handleChange}
											label={'Confirm Password'}
											value={confirmPassword}
											id="confirmpassword"
											name='confirmpassword'
											fullWidth
											variant="outlined"
											type="password"
											placeholder='Confirm Password'
											validators={['required', 'isPasswordMatch']}
											errorMessages={['Please enter password', 'Please make sure your password match']}
										/>
									</Grid>
									{
										isAgentCheck ? (<>
											<Grid item xs={12} sm={4} md={4}>
												<Autocomplete
													open={openBranches}
													onOpen={() => {
														setOpenBranches(true);
													}}
													onClose={() => {
														setOpenBranches(false);
													}}
													ListboxProps={{
														sx: {
															"& li": {
																fontFamily: 'Open Sans',
																fontStyle: "normal",
																fontSize: "12px",
																lineHeight: "25px",
																color: "#000000",
															},
														}
													}}
													id="branch"
													name='branch'
													options={BankData.flatMap(bank => bank.branch_details)}
													getOptionLabel={(option) => `${option.name} (${BankData.find(bank => bank.branch_details.includes(option)).bank_name})`}
													inputValue={inputBranchValue}
													onInputChange={handleBranchInputChange}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Select Branch"
															variant="outlined"

														/>
													)}
												/>
											</Grid>
										</>) : (<>
											<Grid item md={4} sm={4} xs={12}>
												<MultipleSelectChip
													constantFields={allRolesdata}
													fieldLabel="roles"
													fields={currentRoles}
													setFields={setCurrentRoles}
												/>

											</Grid>
											<Grid item md={4} sm={4} xs={12}>
												<MultipleSelectChip
													constantFields={allPartnersdata}
													fieldLabel="partners"
													fields={currentPartners}
													setFields={setCurrentPartners}
												/>
											</Grid>

										</>)
									}

									<Grid item md={4} sm={4} xs={12}>
										<Stack spacing={2} direction="row" style={{ marginTop: '10px', marginLeft: '30px' }}>
											<Button variant="contained" fullWidth type="submit" style={{ background: '#032a37' }}>Create  User</Button>
										</Stack>
									</Grid>
								</Grid>
							</ValidatorForm>
						</Box>
					</div>

				</DialogContent>
			</Dialog>
		</>
	)
}

export default AddUser