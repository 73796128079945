import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { localisedText } from "../../lib/localisation.es6";
import GenerateReports from './components/GenerateReports.js'
import HeaderRibbonInc from "../../shared-ui/HeaderRibbonInc/index.js";
import { getReportTemplateRequest } from "../ReportsConfigs/Redux/actions.js";
import { generateReportRequest, resetGenerateReport } from "./Redux/actions.js";
class Reports extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(getReportTemplateRequest());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  generateReport(payload) {
    // const type = document.getElementById('type').value;
    // const payload = {
    //   type,
    // };
    this.props.dispatch(generateReportRequest(payload));
    console.log("payload", payload)
  }
  resetGenerateReport(){
    this.props.dispatch(resetGenerateReport());
  }


  render() {

    if (this.props.ReportsTemplateData.reportTemplates) {

      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <HeaderRibbonInc title="Generate Reports" />
              <section style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                height: "auto",
                marginTop: "30px",
                padding: "30px"
              }}>
                <GenerateReports
                  templates={this.props.ReportsTemplateData.reportTemplates}
                  loader={this.props.ReportsTemplateData.getReportTemplatesloader}
                  generateReport={this.generateReport.bind(this)}

                  showGenerateReportProgressAlert={
                    this.props.GenerateReportData.getReportloader
                  }
                  showGenerateReportSuccessAlert={
                    this.props.GenerateReportData.getReportSuccess
                  }
                  showGenerateReportErrorAlert={
                    !!this.props.GenerateReportData.getReportError
                  }
                  generateReportError={
                    this.props.GenerateReportData.getReportError
                  }
                  resetGenerateReport= {this.resetGenerateReport.bind(this)}
                  reportBlob={this.props.GenerateReportData.reportBlob}
                />


              </section>
            </Grid>
          </Grid>
        </>
      )

    } else {
      return "No  reports found"
    }



  }

}

export default connect((state) => ({
  ReportsTemplateData: state.ReportsTemplateData,
  GenerateReportData: state.GenerateReportData,
  globalData: state.global,
}))(Reports);