import React,{useState} from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";

import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { AddCircleRounded } from '@mui/icons-material';
import {  getMotsheloPremiumRate } from '../../../helpers';
import find from 'lodash/find';


export default function ViewMembers(props) {

  const [showError, setShowError] = useState(false)

  const classes = useStyle();

  console.log('view members: ', props.dependants);

  const principal_member = props.dependants.filter(member => ['Principal','Alternate_Member'].includes(member.relationship))

  const additional_members = props.dependants.filter(member  => !['Principal','Alternate_Member'].includes(member.relationship))

  const totalMembers = props.dependants.length

  const coverPlan = props.selectedPlan.option

  const coverAmount = props.selectedPlan.cover_amount

  const premiumRate =  getMotsheloPremiumRate(coverAmount, totalMembers)


  const handleCreateQuote = () =>{
    const principle = find(props.dependants, { relationship: 'Principal' })
    const alternateMember = find(props.dependants, { relationship: 'Alternate_Member' })

    if(principle && alternateMember){
      props.handleCreateQuote()
    }else{
      setShowError(true)
    }
  }


  return (
    <>
  
      {
         <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h6><strong>Principal  member </strong> ({coverPlan}) </h6>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'left', marginTop: '2px' }}>
              <ul className={classes.addadditionalmember}>
                <li><strong>Add  alternative member</strong></li>
                <li><AddCircleRounded
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginTop: "-5px"
                  }}
                  onClick={() => props.addAnotherMember('alternative_member')}
                /></li>
              </ul>

            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right', marginTop: '2px' }}>
              <h6 style={{ textAlign: 'right' }} ><strong> Premium:</strong> (BWP {premiumRate}) </h6>
              <h6 style={{ textAlign: 'right' }} ><strong>Total Members:</strong>  ({totalMembers} ) </h6>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Relationship</th>
                    <th>Action</th>
                  </tr>

                  {
                    principal_member.map((d, index) => {

                      return (
                        <tr key={index}>
                          <td>{d.first_name + " " + d.last_name}</td>
                          <td>{d.relationship}</td>
                          <th><ButtonInc style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.uuid) }}>remove member</ButtonInc></th>

                        </tr>
                      )

                    })
                  }

                </table>

              </div>
            </Grid>
          </Grid>
        </section>
      }

      {
       <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'left', marginTop: '2px' }}>
              <ul className={classes.addadditionalmember} style={{ padding: "0" }}>
                <li><strong>Additional  Dependant</strong></li>
                <li><AddCircleRounded
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginTop: "-5px"
                  }}
                  onClick={() => props.addAnotherMember('Additional_dependant')}

                /></li>
              </ul>

            </Grid>


            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Relationship</th>
                    <th>Action</th>
                  </tr>

                  {
                    additional_members.map((d, index) => {
                      return (
                        <tr key={index}>
                          <td>{d.first_name + " " + d.last_name}</td>
                          <td>{d.relationship}</td>
                          <th><ButtonInc style={{ cursor: "pointer" }}  onClick={() => { props.removeDependant(d.uuid) }} >remove member</ButtonInc></th>

                        </tr>
                      )

                    })
                  }

                </table>

              </div>
            </Grid>
          </Grid>
          <hr />
          {props.dependants.length > 0 &&
            <div className={classes.createquote}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateQuote}
              >
                Create Quote
              </ButtonInc>
              {
                showError && <p style={{color: "red"}}>Please make sure you have principal and alternate member on the quote</p>
              }
            </div>
          }
        </section>
      }

    </>
  )
}
