import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { hashHistory } from 'react-router';

//components
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import Audits from '../Audits/Audits'
import ButtonInc from '../../../../../shared-ui/ButtonInc';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NavigationWrapper = (props) => {


  React.useEffect(() => {
    props.getAllPaymentAudits()
  }, [])

  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const viewPolicyDetail = (policyNumber) =>{
    if(policyNumber){
      hashHistory.push(`admin/policy-processing/${policyNumber}`)
    }else{
      return
    }
  }
  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: "auto" }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "18%" }}
          className={classes.tabs}
        >
          <Tab label="Payment"  {...a11yProps(0)} />
          <Tab label="Audits"  {...a11yProps(1)} />
        </Tabs>


        <TabPanel value={value} index={0} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Payment</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => viewPolicyDetail(props.policyNumber)}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px"

                }}
              >
                <span style={{ color: "#000000" }}>{"View Policy"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }}
                />
              </ButtonInc>


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <PaymentDetails
                payment={props.payment}
                loader={props.loader}
              />
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={1} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Audits</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Audits
                audits={props.audits}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  )
}

export default NavigationWrapper