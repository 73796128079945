import React from 'react'
import { useStyle } from "./Style";
import MaterialTable from 'material-table';
import { formatDateLocale } from '../../lib/utils.es6';
const EventsTableInc = (props) => {
  const classes = useStyle();
  return (
    <>
      <section className={classes.tableInc}>

            <MaterialTable
              title={props.title}
              columns={[
                { title: 'Event Type', field: 'event_type' },
                { title: 'Description', field: 'description' },
                { title: 'Full Name', field: 'full_name' },
                { title: 'Created At', field: 'created_at',   render: rowData => formatDateLocale(rowData.created_at) },
              ]}
              data={props.data}
              {...props}
              options={{
                ...props.options
              }}
              {...props.otherProps}
            />

      </section>

    </>
  )
}

export default EventsTableInc