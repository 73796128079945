import * as types from '../../../../lib/actionTypes.es6'

export const getPaymentProcessingDcpRequest = (guid) =>{
  return{
    type: types.GET_PAYMENT_PROCESSING_DCP_REQUEST,
    payload: guid,
  }
}

export const getPaymentProcessingDcpSuccess = (payload) =>{
  return{
    type: types.GET_PAYMENT_PROCESSING_DCP_SUCCESS,
    payload: payload,
  }
}

export const getPaymentProcessingDcpFailure = (error) =>{
  return{
    type: types.GET_PAYMENT_PROCESSING_DCP_FAILURE,
    payload: error,
  }
}


export function paymentApproveActionRequest(payload) {
  return {
    type: types.PAYMENT_APPROVE_ACTION_REQUEST,
    payload,
  };
}

export function paymentApproveActionSuccess(response) {
  return {
    type: types.PAYMENT_APPROVE_ACTION_SUCCESS,
    payload: response,
  };
}

export function paymentApproveActionFailure(error) {
  return {
    type: types.PAYMENT_APPROVE_ACTION_FAILURE,
    payload: error,
  };
}


export function resetPaymentApproveActionSuccess(error) {
  return {
    type: types.RESET_PAYMENT_APPROVE_ACTION_SUCCESS,
  };
}

export function resetPaymentApproveActionError(error) {
  return {
    type: types.RESET_PAYMENT_APPROVE_ACTION_ERROR,
  };

}



export function paymentRejectActionRequest(payload) {
  return {
    type: types.PAYMENT_REJECT_ACTION_REQUEST,
    payload,
  };
}

export function paymentRejectActionSuccess(response) {
  return {
    type: types.PAYMENT_REJECT_ACTION_SUCCESS,
    payload: response,
  };
}

export function paymentRejectActionFailure(error) {
  return {
    type: types.PAYMENT_REJECT_ACTION_FAILURE,
    payload: error,
  };
}



export function addPolicyNotesRequest(payload) {
  return {
    type: types.ADD_POLICY_NOTES_REQUEST,
    payload,
  };
}

export function addPolicyNotesSuccess(response) {
  return {
    type: types.ADD_POLICY_NOTES_SUCCESS,
    payload: response,
  };
}

export function addPolicyNotesFailure(error) {
  return {
    type: types.ADD_POLICY_NOTES_FAILURE,
    payload: error,
  };
}


export function resetAddPolicyNotesSuccess(error) {
  return {
    type: types.RESET_ADD_POLICY_NOTES_SUCCESS,
  };
}

export function resetAddPolicyNotesError(error) {
  return {
    type: types.RESET_ADD_POLICY_NOTES_ERROR,
  };
}

export const getPaymentsAuditsDcpRequest = (guid) =>{
  return{
    type: types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_REQUEST,
    payload: guid,
  }
}

export const getPaymentAuditsDcpSuccess = (payload) =>{
  return{
    type: types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_SUCCESS,
    payload: payload,
  }
}

export const getPaymentsAuditsDcpFailure = (error) =>{
  return{
    type: types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_FAILURE,
    payload: error,
  }
}