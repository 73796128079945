import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';

const ClaimDetails = (props) => {



  // claimed_items

  const itemColor = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].itemColor : 'N/A';

  const itemDescription = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].itemDescription : 'N/A';

  const itemImageUrl = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].itemImageUrl : 'N/A';

  const itemLabel = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].itemLabel : 'N/A';

  const itemQuantity = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].itemQuantity : 'N/A';

  const packageHeightCm = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].packageHeightCm : 'N/A';

  const packageLenghtCm = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].packageLenghtCm : 'N/A';

  const packageTotalCost = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].packageTotalCost : 'N/A';

  const packageWeightKg = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].packageWeightKg : 'N/A';

  const packageWidthCm = props.claim.claim_details.additional_details ? props.claim.claim_details.additional_details.claimed_items[0].packageWidthCm : 'N/A';



  const currencyCode = getPartner().country.currency_code



  const classes = useStyle();

  if (!props.claim.claim_details) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center' }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }




  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
          <Grid container spacing={2} className={classes.claimdetailswrapper}>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Policy Number</h4>
              <p>{props.claim.claim_details.policy_number || "N/A"}</p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
              <h4>Policy Holder Full Names</h4>
              <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Policy Holder clientUid</h4>
              <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
              <h4>Agent Name</h4>
              <p>{props.claim.web_agent_name || "N/A"}</p>
            </Grid>
            <div className={classes.borderbottom}></div>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Item Color</h4>
              <p>{itemColor}</p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Item Description</h4>
              <p>{itemDescription}</p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Item Image Url</h4>
              <p> <a href={itemImageUrl} rel="noreferrer" target='_blank'>View Image</a></p>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
              <h4>Item Label</h4>
              <p>{itemLabel}</p>
            </Grid>

            <div className={classes.borderbottom}></div>
            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Item Quantity</h4>
              <p>{itemQuantity}</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>package Height Cm</h4>
              <p>{packageHeightCm}</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>package Lenght Cm</h4>
              <p>{packageLenghtCm}</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
              <h4>Package Total Cost</h4>
              <p>{currencyCode + " " + packageTotalCost.toLocaleString()}</p>
            </Grid>


            <div className={classes.borderbottom}></div>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Package Weight Kg</h4>
              <p>{packageWeightKg}</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Package Width Cm</h4>
              <p>{packageWidthCm}</p>  
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Created At</h4>
              <p>{ formatDateLocale(props.claim.created_at) || "N/A"}</p>  
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
              <h4>Event Date</h4>
              <p>{ formatDateLocale(props.claim.created_at) || "N/A"}</p>  
            </Grid>

            <div className={classes.borderbottom}></div>

          </Grid>

          <Grid container spacing={2} className={classes.notesheader}>
            <Grid item xs={12} md={12} sm={12}>
              <ul>
                <li><h4>Claim Notes</h4></li>
                <li >
                  <Tooltip title="Add claim Notes">
                    <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                  </Tooltip>
                </li>
              </ul>

            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.noteswrapper}>
            <Grid item xs={12} md={4} sm={4}>
              <div className={classes.notesbox}>
                <p>{props.claim.notes || "No claim notes available"}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}

export default ClaimDetails