import React from "react";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions.js"
import { ClaimStatusEnum } from "../../enum.js";
import find from 'lodash/find';
import { getPartnerGuid, hasAuthourity } from "../../../../../lib/access.es6.js";
import ClaimsHeader from "../ClaimsHeader/ClaimsHeader.js";
import ClaimsWrapper from "../ClaimsWrapper/ClaimsWrapper.js";


class PropertyClaims extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }  

  componentWillMount() {
    const payload = {
      "partner": getPartnerGuid(),
      "statuses": ClaimStatusEnum.docs_pending
    }
    this.props.dispatch(actions.getClaimsDcpRequest(payload))
  }

  getClaims(payload) {
    this.props.dispatch(actions.getClaimsDcpRequest(payload
    ))
  }

  handleBulkApproval(payload) {
    this.props.dispatch(actions.bulkApproveRequest(payload))
  }



  isPropertyClaim(claim) {
    const hasPropertyBenefit = !!find(claim.benefits, { type: "Property" })
    return hasPropertyBenefit
  }

  filterPropertyClaim(claims) {
    return claims.filter(claim => this.isPropertyClaim(claim))
  }

  render() {
    const claimEx = this.props.bulkApprovalData.claims_dcp || []
    const claims = this.filterPropertyClaim(claimEx)

    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claims.</p>
    }

    return (
      <>
        <ClaimsHeader
          title="Property Claims Dashboard"
        />

        <ClaimsWrapper
          claimsData={claims}
          type="Property"
          hasAlerts={true}
          hasBulkApprove={true}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.bulkApprovalData.get_claims_dcp_loader}
          handleBulkApproval={this.handleBulkApproval.bind(this)}

          showBulkApproveProgressAlert={this.props.bulkApprovalData.bulkApprovalClaimLoader}
          showBulkApproveSuccessAlert={this.props.bulkApprovalData.bulkApprovalClaimSuccess}
          showBulkApproveErrorAlert={!!this.props.bulkApprovalData.bulkApprovalClaimError}
          bulkApproveError={this.props.bulkApprovalData.bulkApprovalClaimError}

          resetBulkApproveSuccessAlert={() => { this.props.dispatch(actions.resetbulkApprovalSuccess()) }}
          resetBulkApproveErrorAlert={() => { this.props.dispatch(actions.resetbulkApprovalFailure()) }}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  bulkApprovalData: state.bulkApprovalData,
  globalData: state.global,
}))(PropertyClaims);