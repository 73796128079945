import React, { useState } from 'react';
import TableInc from '../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../lib/requests.es6';
import { v4 as uuidv4 } from 'uuid';

const BenefitsNuclear = (props) => {
  let dataContent = [];

  if (props.membership_configs && props.membership_configs.content) {
    dataContent = props.membership_configs.content.benefits_nuclear;
  }

  const [data, setData] = useState(dataContent || []);

  const currencyCode = props.currencyCode;

  const updateMembershipConfigs = async (newData) => {
    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        benefits_nuclear: newData
      }
    }

    await updateExtendedConfig(payload)

    window.location.reload(true);
  };

  return (
    <TableInc
      title="Main Benefit"
      columns={[
        { title: 'name', field: 'name', 
        lookup: {
          'Funeral': 'Funeral',
          'Death': 'Death',
          'Property': 'Property',
          'Personal_Accident': 'Personal_Accident',
          'Medical_Expense': 'Medical_Expense',
        } },
        { title: 'Benefit Amount', field: 'benefit_amount', type: 'numeric',
        render: rowData =>  currencyCode + " " + (rowData.benefit_amount/100).toLocaleString() },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length,
      }}
      actionsColumnIndex={-1}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            newData.benefit_amount = newData.benefit_amount * 100;
            newData.uuid = uuidv4()
            const newDataArray = [...data, newData];
            setData(newDataArray);
            updateMembershipConfigs(newDataArray);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.benefit_amount = newData.benefit_amount * 100;
            dataUpdate[index] = newData;
            setData(dataUpdate);
            updateMembershipConfigs(dataUpdate);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData(dataDelete);
            updateMembershipConfigs(dataDelete);
            resolve();
          }),
      }}
    />
  );
};

export default BenefitsNuclear;
