import React from "react";
import './PremiumsTable.css'
import TableInc from "../../../../shared-ui/TableInc";
import { updateExtendedConfig } from "../../../../lib/requests.es6";
import { getPartner } from "../../../../lib/access.es6";

export default function PremiumsConfigs(props) {
  const currencyCode = getPartner().country.currency_code

  if (props.premiums) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <TableInc
            title='Premiums for extended members'
            columns={[
              { title: 'Entry age	(year)', field: 'entry_age' , type: 'numeric' },
              { title: 'Exit age 	(year)', field: 'exit_age' , type: 'numeric' },
              { title: 'Benefit Amount', field: 'benefit_amount', type: 'numeric', render: rowData => <>
              {
                currencyCode + " " +  rowData.benefit_amount/100 || "N/A"
              }
              </> },
              {
                title: 'Premium Amount', field: 'premium_rate', type: 'numeric', render: rowData => <>
                {
                  currencyCode + " " + (rowData.premium_rate/100).toLocaleString()
                }
                </>
              },
            ]}
            data={
              props.premiums
            }
            options={{
              pageSize: props.premiums.length,
              actionsColumnIndex: -1,
              addRowPosition: 'first',
              showTitle: true,
            }}

            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                   
                    const premiumsConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      premiums:  [...props.extendedConfig.content.premiums , {
                        ...newData,
                        benefit_amount: Number(newData.benefit_amount) * 100,
                        premium_rate: Number(newData.premium_rate) * 100,
                        entry_age: Number(newData.entry_age),
                        exit_age: Number(newData.exit_age),
                      }]
                    }
                  }
                  updateExtendedConfig(premiumsConfig).then(
                    (response) => {
                      console.log("here is response", response);
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);
                    }
                  ).catch(
                    (error) => {
                      console.log("did not work", error)
                      reject();
                    }
                  )

                }),
              onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                console.log('you are deleting this: ', oldData)
                const indexToDelete = oldData.tableData.id
                const premiums = props.extendedConfig.content.premiums
                premiums.splice(indexToDelete, 1)
                const extendedConfig = {
                  "product_guid": props.productGuid,
                  ...props.extendedConfig,
                  "content": {
                    ...props.extendedConfig.content,
                    premiums: premiums
                  }
                }
                updateExtendedConfig(extendedConfig).then(
                  (response) => {
                    resolve();
                    props.refreshProduct(props.productGuid);
                    window.location.reload(true);
                  }
                ).catch(
                  (error) => {
                    console.log("did not work", error)
                    reject();
                  }
                )
                resolve();
              }),
            }}

          />
        </div>
      </>
    );
  }
  return "No premium configs found, click on the add button to add new premums."
}
