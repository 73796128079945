import { Button } from '@mui/material'
import { useStyle } from "./Style";
import React from 'react'
import loader from './loader.svg'

export default function ButtonInc(props) {
  const classes = useStyle();

  if(props.hasPermision === false){
    return null
  }

  if (props.loading) {
    return <div className={classes.ButtonInc}>
      <Button
        {...props}
      >
        <img src={loader} alt="loader" />
      </Button>
    </div>
  }
  return (
    <div className={classes.ButtonInc}>
      <Button
        {...props}
      >{props.children}</Button>
    </div>

  )
}
