import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,



  get_paymentProcessingDcp_errors: null,
  get_paymentProcessingDcp_loader: false,
  get_paymentProcessingDcp_success_message: '',
  paymentProcessingDcp: null,


  paymentApproveActionLoader: false,
  paymentApproveActionsuccess: false,
  paymentApproveActionError: null,
  paymentApproveAction: null,
  paymentToApprove: null,


  paymentRejectActionLoader: false,
  paymentRejectActionsuccess: false,
  paymentRejectActionError: null,
  paymentRejectAction: null,


  paymentNotes: null,
  paymentToView: null,
  paymentNotesSuccess: false,
  paymentNotesLoader: false,
  paymentNotesError: null,

  get_PaymentAuditsDcp_errors: null,
  get_PaymentAuditsDcp_loader: false,
  get_PaymentAuditsDcp_success_message: '',
  paymentsAuditsDcp: null,


};

export default function paymentProcessingReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_PAYMENT_PROCESSING_DCP_REQUEST:
      return { ...state, get_paymentProcessingDcp_loader: true, get_paymentProcessingDcp_errors: null };
    case types.GET_PAYMENT_PROCESSING_DCP_SUCCESS:
      return { ...state, get_paymentProcessingDcp_loader: false, get_paymentProcessingDcp_errors: null, paymentProcessingDcp: action.payload };
    case types.GET_PAYMENT_PROCESSING_DCP_FAILURE:
      return { ...state, get_paymentProcessingDcp_loader: false, get_paymentProcessingDcp_errors: action.payload };

    case types.PAYMENT_APPROVE_ACTION_REQUEST:
      return {
        ...state,
        paymentApproveActionError: null,
        paymentApproveActionLoader: true,
        paymentApproveActionsuccess: false,
        paymentApproveAction: null,
        paymentToApprove: action.payload
      };

    case types.PAYMENT_APPROVE_ACTION_SUCCESS:
      return {
        ...state,
        paymentApproveAction: action.payload,
        paymentApproveActionsuccess: true,
        paymentApproveActionLoader: false,
        paymentApproveActionError: null,
      };

    case types.PAYMENT_APPROVE_ACTION_FAILURE:
      return { ...state, paymentApproveActionLoader: false, paymentApproveActionError: action.payload };


    case types.PAYMENT_REJECT_ACTION_REQUEST:
      return {
        ...state,
        paymentRejectActionError: null,
        paymentRejectActionLoader: true,
        paymentRejectActionsuccess: false,
        paymentRejectAction: null,
      };

    case types.PAYMENT_REJECT_ACTION_SUCCESS:
      return {
        ...state,
        paymentRejectAction: action.payload,
        paymentRejectActionsuccess: true,
        paymentRejectActionLoader: false,
        paymentRejectActionError: null,
      };

    case types.PAYMENT_REJECT_ACTION_FAILURE:
      return { ...state, paymentRejectActionLoader: false, paymentRejectActionError: action.payload };



    case types.ADD_POLICY_NOTES_REQUEST:
      return {
        ...state,
        paymentNotesError: null,
        paymentNotesLoader: true,
        paymentNotesSuccess: false,
        paymentNotes: null
      };

    case types.ADD_POLICY_NOTES_SUCCESS:
      return {
        ...state,
        paymentNotes: action.payload,
        paymentNotesSuccess: true,
        paymentNotesLoader: false,
        paymentNotesError: null,
      };

    case types.ADD_POLICY_NOTES_FAILURE:
      return { ...state, paymentNotesLoader: false, paymentNotesError: action.payload };

    case types.RESET_ADD_POLICY_NOTES_SUCCESS:
      return { ...state, paymentNotesSuccess: false };
      

      case types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_REQUEST:
        return { ...state, get_PaymentAuditsDcp_loader: true, get_PaymentAuditsDcp_errors: null };
      case types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_SUCCESS:
        return { ...state, get_PaymentAuditsDcp_loader: false, get_PaymentAuditsDcp_errors: null, paymentsAuditsDcp: action.payload };
      case types.GET_PAYMENTS_AUDITS_PROCESSING_DCP_FAILURE:
        return { ...state, get_PaymentAuditsDcp_loader: false, get_PaymentAuditsDcp_errors: action.payload };

    default:
      return state;
  }
}
