import React from "react";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions.js"
import { ClaimStatusEnum } from "../../enum";
import find from 'lodash/find';
import { getPartnerGuid, hasAuthourity } from "../../../../../lib/access.es6";
import ClaimsHeader from "../ClaimsHeader/ClaimsHeader";
import ClaimsWrapper from "../ClaimsWrapper/ClaimsWrapper.js";


class ClaimDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentWillMount() {
    const isFinanceUser = hasAuthourity('BULK_PAY_APPROVE_CLAIMS');
    const payload = {
      "partner": getPartnerGuid(),
      "statuses": isFinanceUser ? ClaimStatusEnum.Approved : ClaimStatusEnum.docs_pending
    }
    this.props.dispatch(actions.getClaimsDcpRequest(payload))
  }

  getClaims(payload) {
    this.props.dispatch(actions.getClaimsDcpRequest(payload
    ))
  }

  handleBulkApproval(payload) {
    this.props.dispatch(actions.bulkApproveRequest(payload))
  }

  filterHospitalClaim(claims) {
    return claims.filter(claim => claim.preauthorization_number !== null)
  }

  isDeathClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return hasDeathBenefit && hasNoLou
  }

  isReimbusermentClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return !hasDeathBenefit && hasNoLou
  }

  filterDeathClaim(claims) {
    return claims.filter(claim => this.isDeathClaim(claim))
  }
  filterReimbursement(claims){
    return claims.filter(claim => this.isReimbusermentClaim(claim))
  }
  render() {
    const claimEx = this.props.bulkApprovalData.claims_dcp || []
    const claims = this.filterReimbursement(claimEx)

    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claims.</p>
    }

    return (
      <>
        <ClaimsHeader
          title="Reimbursement Claims Dashboard"
        />

        <ClaimsWrapper
          isFinanceUser={hasAuthourity('BULK_PAY_APPROVE_CLAIMS')}
          claimsData={claims}
          type="reimbursement"
          hasAlerts={true}
          hasBulkApprove={true}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.bulkApprovalData.get_claims_dcp_loader}
          handleBulkApproval={this.handleBulkApproval.bind(this)}

          showBulkApproveProgressAlert={this.props.bulkApprovalData.bulkApprovalClaimLoader}
          showBulkApproveSuccessAlert={this.props.bulkApprovalData.bulkApprovalClaimSuccess}
          showBulkApproveErrorAlert={!!this.props.bulkApprovalData.bulkApprovalClaimError}
          bulkApproveError={this.props.bulkApprovalData.bulkApprovalClaimError}

          resetBulkApproveSuccessAlert={() => { this.props.dispatch(actions.resetbulkApprovalSuccess()) }}
          resetBulkApproveErrorAlert={() => { this.props.dispatch(actions.resetbulkApprovalFailure()) }}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  bulkApprovalData: state.bulkApprovalData,
  globalData: state.global,
}))(ClaimDashboard);