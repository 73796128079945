import React from 'react';
import { connect } from "react-redux";
import { getCustomerAuditsDcpRequest } from './Redux/actions';
import Audits from './Components/Audits/Audits';

class BboxxCustomerAudits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    //this.setState({ customer_guid: customer_guid })
    //this.refreshCustomerStatus(customer_guid);
    if (this.props.customerData) {
      const entity_guid = this.props.customerData.currentCustomer.guid
      this.props.dispatch(getCustomerAuditsDcpRequest(entity_guid));
      console.log("this.props.customerData", entity_guid)
    }

  }



  render() {

    return (
      <>
        <Audits
         auditsLoader={this.props.AccessBankCustomerAudits.get_CustomerAuditsDcp_loader}
        audits={this.props.AccessBankCustomerAudits.customerAuditsDcp}
        />

      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankCustomerAudits: state.AccessBankCustomerAudits
}))(BboxxCustomerAudits);