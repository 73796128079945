import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  ButtonInc:{
    "& button":{
      background: "#fba92c",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      "&:hover": {
          background: "#fba92c",
          boxShadow: "none",
      },
   
    }
  }

}));