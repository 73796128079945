import React from 'react'
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper'
import EventsTableInc from '../../../../../shared-ui/EventsTableInc'

const Audits = (props) => {
  if (!props.audits || !props.audits.events || props.audits.events.length === 0) {
    return null; 
  }

  if (props.auditsLoader) {
    return <SkeletonWrapper loading={props.auditsLoader} />
  }

  return (
    <>
    
      <EventsTableInc
        data={props.audits.events}

        options={{
          pageSize: props.audits.events.length < 5
            ? props.audits.events.length
            : 5,
          showTitle: false,
        }}

      />
    </>
  )
}

export default Audits