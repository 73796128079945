import React from "react";
import './adonsTable.css'
import TableInc from "../../../../shared-ui/TableInc";
import { updateExtendedConfig } from "../../../../lib/requests.es6";
import { RELATIONSHIP_POOL } from "../../PoductConstants";
import { getPartnerGuid } from "../../../../lib/access.es6";

export default function PayOutPlans(props) {
  if (props.members_payout_plan) {


    const addPlan = (payOuts, index, newPlan) => {
      const currentPayout = payOuts[index]
      currentPayout.plans.push(newPlan)
      payOuts[index] = currentPayout
      return payOuts
    }

    const deletePlan = (payOuts, parentIndex, oldData) => {
      const itemIndexToDelete = oldData.tableData.id
      const currentPayout = payOuts[parentIndex]
      
      let plans = currentPayout.plans
      plans.splice(itemIndexToDelete, 1)
      currentPayout['plans'] = plans

      payOuts[parentIndex] = currentPayout
      return payOuts
    }
    
    const deletePayOutPlans = (payOuts, oldData) => {
      const itemIndexToDelete = oldData.tableData.id
      payOuts.splice(itemIndexToDelete, 1)
      console.log({
        payOuts, oldData
      });
      return payOuts
    }


    return (
      <>
        <div style={{ marginTop: "2%" }}>
          {


            <TableInc
              title="Payout Plans"
              columns={[
                { title: 'Relationship', field: 'relationship', lookup: RELATIONSHIP_POOL },

              ]}
              data={
                props.members_payout_plan
              }
              options={{
                showTitle: true,
                pageSize: props.members_payout_plan.length,
                actionsColumnIndex: -1,
                addRowPosition: 'first',
              }}
              editable={{
                onBulkUpdate: changes =>
                  new Promise((resolve, reject) => {
                    console.log('you are doing this bulk changes: ', changes)
                    resolve();
                  }),

                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    console.log("index", 0)
                    const membershipConfig = {
                      "product_guid": props.productGuid,
                      ...props.extendedConfig,
                      "content": {
                        ...props.extendedConfig.content,
                        members_payout_plan: [...props.extendedConfig.content.members_payout_plan, {relationship: newData.relationship, plans: []} ]
                      }
                    }
                    console.log("membershipConfig", membershipConfig)
                    updateExtendedConfig(membershipConfig).then(
                      (response) => {
                        console.log("here is response", response);
                        resolve();
                        window.location.reload(true);
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )


                  }),
                  onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    console.log("index", 1)
                    const membershipConfig = {
                      "product_guid": props.productGuid,
                      ...props.extendedConfig,
                      "content": {
                        ...props.extendedConfig.content,
                        members_payout_plan: deletePayOutPlans(props.members_payout_plan, oldData)
                      }
                    }
                    updateExtendedConfig(membershipConfig).then(
                      (response) => {
                        console.log("here is response", response);
                        resolve();
                        window.location.reload(true);
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )


                  }),
              }}
              detailPanel={rowData => {
                return (
                  <div style={{ width: '96%', margin: "auto", zIndex: '999999' }}>
                    {
                      <TableInc
                        columns={[
                          { title: 'Minimimum Age', field: 'min_age', type: 'numeric' },
                          { title: 'Maximum Age', field: 'max_age' , type: 'numeric' },
                          getPartnerGuid() === 'accessbank' 
                          ? { title: 'Payout amount', field: 'amount' , type: 'numeric' }
                          : { title: 'Claim Payout Percentage', field: 'payout_percentage' , type: 'numeric' },
                        ]}
                        data={
                          rowData.plans
                        }
                        options={{
                          pageSize: rowData.plans.length,
                          showTitle: false,
                          actionsColumnIndex: -1,
                          addRowPosition: 'first',
                        }}

                        editable={{
                          onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                              console.log("index", 1)
                              const membershipConfig = {
                                "product_guid": props.productGuid,
                                ...props.extendedConfig,
                                "content": {
                                  ...props.extendedConfig.content,
                                  members_payout_plan: addPlan(props.members_payout_plan, rowData.tableData.id, newData)
                                }
                              }
                              console.log("membershipConfig", membershipConfig)
                              updateExtendedConfig(membershipConfig).then(
                                (response) => {
                                  console.log("here is response", response);
                                  resolve();
                                  window.location.reload(true);
                                }
                              ).catch(
                                (error) => {
                                  console.log("did not work", error)
                                  reject();
                                }
                              )


                            }),
                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              console.log("index", 1)
                              const membershipConfig = {
                                "product_guid": props.productGuid,
                                ...props.extendedConfig,
                                "content": {
                                  ...props.extendedConfig.content,
                                  members_payout_plan: deletePlan(props.members_payout_plan, rowData.tableData.id, oldData)
                                }
                              }
                              updateExtendedConfig(membershipConfig).then(
                                (response) => {
                                  console.log("here is response", response);
                                  resolve();
                                  window.location.reload(true);
                                }
                              ).catch(
                                (error) => {
                                  console.log("did not work", error)
                                  reject();
                                }
                              )


                            }),
                        }}

                      />
                    }
                  </div>
                )
              }}

            />

          }

        </div>
      </>
    );
  }
  return "No payOutPlans found, click on the add button to add new premums."
}
