import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,



  get_PolicyProcessingDcp_errors: null,
  get_PolicyProcessingDcp_loader: false,
  get_PolicyProcessingDcp_success_message: '',
  policyProcessingDcp: null,


  policyActionLoader: false,
  policyActionsuccess: false,
  policyActionError: null,
  policyAction: null,
  policyToApprove: null,


  policyRejectActionLoader: false,
  policyRejectActionsuccess: false,
  policyRejectActionError: null,
  policyRejectAction: null,

  cancelPolicyActionErrors: null,
  cancelPolicyActionLoader: false,
  cancelPolicyActionSuccess: false,


  policyNotes: null,
  policyToView: null,
  policyNotesSuccess: false,
  policyNotesLoader: false,
  policyNotesError: null,


  get_PolicyAuditsDcp_errors: null,
  get_PolicyAuditsDcp_loader: false,
  get_PolicyAuditsDcp_success_message: '',
  policyAuditsDcp: null,

  deleteDocumentError: null,
  deleteDocumentLoader: false,
  deleteDocument: null,
  hardDeleteDocumentsSuccess: false,
  hardDeleteDocumentLoader: false,
  hardDeleteDocumentSuccess: false,

  documentsToDelete: null,


  get_PaymentsByPolicyGuid_errors: null,
  get_PaymentsByPolicyGuid_loader: false,
  get_PaymentsByPolicyGuid_success_message: '',
  policiesPaymentByGuid: null,

};

export default function policyProcessingReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_POLICY_PROCESSING_DCP_REQUEST:
      return { ...state, get_PolicyProcessingDcp_loader: true, get_PolicyProcessingDcp_errors: null };
    case types.GET_POLICY_PROCESSING_DCP_SUCCESS:
      return { ...state, get_PolicyProcessingDcp_loader: false, get_PolicyProcessingDcp_errors: null, policyProcessingDcp: action.payload };
    case types.GET_POLICY_PROCESSING_DCP_FAILURE:
      return { ...state, get_PolicyProcessingDcp_loader: false, get_PolicyProcessingDcp_errors: action.payload };

    case types.POLICY_ACTION_REQUEST:
      return {
        ...state,
        policyActionError: null,
        policyActionLoader: true,
        policyActionsuccess: false,
        policyAction: null,
        policyToApprove: action.payload
      };

    case types.POLICY_ACTION_SUCCESS:
      return {
        ...state,
        policyAction: action.payload,
        policyActionsuccess: true,
        policyActionLoader: false,
        policyActionError: null,
      };

    case types.POLICY_ACTION_FAILURE:
      return { ...state, policyActionLoader: false, policyActionError: action.payload };

    case types.RESET_POLICY_ACTION_SUCCESS:
      return { ...state, policyActionsuccess: false };

    case types.RESET_POLICY_ACTION_ERROR:
      return { ...state, policyActionError: null };


    case types.SET_POLICY_TO_APPROVE:
      return { ...state, policyToApprove: action.payload };

    case types.RESET_POLICY_TO_APPROVE:
      return { ...state, policyToApprove: null };


    case types.POLICY_REJECT_ACTION_REQUEST:
      return {
        ...state,
        policyRejectActionError: null,
        policyRejectActionLoader: true,
        policyRejectActionsuccess: false,
        policyRejectAction: null,
      };

    case types.POLICY_REJECT_ACTION_SUCCESS:
      return {
        ...state,
        policyRejectAction: action.payload,
        policyRejectActionsuccess: true,
        policyRejectActionLoader: false,
        policyRejectActionError: null,
      };

    case types.POLICY_REJECT_ACTION_FAILURE:
      return { ...state, policyRejectActionLoader: false, policyRejectActionError: action.payload };



    case types.ADD_POLICY_NOTES_REQUEST:
      return {
        ...state,
        policyNotesError: null,
        policyNotesLoader: true,
        policyNotesSuccess: false,
        policyNotes: null
      };

    case types.ADD_POLICY_NOTES_SUCCESS:
      return {
        ...state,
        policyNotes: action.payload,
        policyNotesSuccess: true,
        policyNotesLoader: false,
        policyNotesError: null,
      };

    case types.ADD_POLICY_NOTES_FAILURE:
      return { ...state, policyNotesLoader: false, policyNotesError: action.payload };

    case types.RESET_ADD_POLICY_NOTES_SUCCESS:
      return { ...state, policyNotesSuccess: false };


    case types.CANCEL_POLICY_ACTION_REQUEST:
      return {
        ...state,
        cancelPolicyActionErrors: null,
        cancelPolicyActionLoader: true,
        cancelPolicyActionSuccess: false,
      };

    case types.CANCEL_POLICY_ACTION_SUCCESS:
      return {
        ...state,
        cancelPolicyActionSuccess: true,
        cancelPolicyActionLoader: false,
        cancelPolicyActionErrors: null,
      };

    case types.CANCEL_POLICY_ACTION_FAILURE:
      return { ...state, cancelPolicyActionLoader: false, cancelPolicyActionErrors: action.payload };

    case types.GET_POLICY_AUDITS_PROCESSING_DCP_REQUEST:
      return { ...state, get_PolicyAuditsDcp_loader: true, get_PolicyAuditsDcp_errors: null };
    case types.GET_POLICY_AUDITS_PROCESSING_DCP_SUCCESS:
      return { ...state, get_PolicyAuditsDcp_loader: false, get_PolicyAuditsDcp_errors: null, policyAuditsDcp: action.payload };
    case types.GET_POLICY_AUDITS_PROCESSING_DCP_FAILURE:
      return { ...state, get_PolicyAuditsDcp_loader: false, get_PolicyAuditsDcp_errors: action.payload };


    case types.DELETE_POLICY_DOCUMENT_REQUEST:
      return {
        ...state, loader: true, error: null, document: null, deleteClaimDocumentLoader: true,
        deleteDocumentError: null,
        deleteDocument: null,
        hardDeleteDocumentLoader: true,
      };
    case types.DELETE_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state, loader: false, deleteDocumentLoader: false,
        deleteDocument: true,
        hardDeleteExpenseLoader: false,
        hardDeleteDocumentSuccess: true
      };


    case types.DELETE_POLICY_DOCUMENT_FAILURE:
      return {
        ...state, loader: false, error: action.payload, deleteDocumentLoader: false,
        deleteDocumentError: action.payload,
        hardDeleteExpenseLoader: false,
      };

    case types.RESET_DELETE_POLICY_DOCUMENT_SUCCESS:
      return { ...state, hardDeleteDocumentsSuccess: false, hardDeleteDocumentSuccess: false };

    case types.SET_POLICY_DOCUMENT_TO_DELETE:
      return { ...state, documentsToDelete: action.payload };

    case types.RESET_POLICY_DOCUMENT_TO_DELETE:
      return { ...state, documentsToDelete: null };



      case types.GET_PAYMENTS_BY_POLICY_GUID_REQUEST:
        return { ...state, get_PaymentsByPolicyGuid_loader: true, get_PaymentsByPolicyGuid_errors: null };
      case types.GET_PAYMENTS_BY_POLICY_GUID_SUCCESS:
        return { ...state, get_PaymentsByPolicyGuid_loader: false, get_PaymentsByPolicyGuid_errors: null, policiesPaymentByGuid: action.payload };
      case types.GET_PAYMENTS_BY_POLICY_GUID_FAILURE:
        return { ...state, get_PaymentsByPolicyGuid_loader: false, get_PaymentsByPolicyGuid_errors: action.payload };

    default:
      return state;
  }
}

