/*
 * Combine all reducers in this file and export the combined reducers.
 */
import * as redux from 'redux';
import registrationReducer from './containers/RegisterCustomer/reducer.es6';
import loginReducer from './containers/Login/reducer.es6';
import searchCustomerByMsisdnReducer from './containers/Search/reducer.es6';
import currentCustomerReducer from './containers/Customer/reducer.es6';
import customerStatusReducer from './containers/CustomerStatus/reducer.es6';
import policyPurchaseReducer from './containers/BuyPolicy/reducer.es6';
import claimSubmissionReducer from './containers/ClaimSubmission/reducer.es6';
import policyListReducer from './containers/PolicyList/reducer.es6';
import profileReducer from './containers/Profile/reducer.es6';
import updateLanguageReducer from './containers/Main/reducer.es6';
import mainLoaderReducer from './lib/loaderReducer.es6';
import openClaimReducer from './containers/ClaimsOpen/reducer.es6';
import payableClaimReducer from './containers/ClaimsPayable/reducer.es6';
import closedClaimReducer from './containers/ClaimsClosed/reducer.es6';
import claimStatusReducer from './containers/ClaimsStatus/reducer.es6';
import claimSearchReducer from './containers/ClaimSearch/reducer.es6';
import summaryClaimReducer from './containers/ClaimsDashboard/reducer.es6';
import processingClaimReducer from './containers/ClaimsProcessing/reducer.es6';
import reportsReducer from './containers/ReportsPremium/reducer.es6';
import userAdminReducer from './containers/Users/reducer.es6';
import claimCallbackReducer from './containers/ClaimCallback/reducer.es6';
import claimFinalisationReportReducer from './containers/ReportClaimFinalisation/reducer.es6';
import reportOpenClaimReducer from './containers/ReportOpenClaim/reducer.es6';
import reportRegistrationReducer from './containers/ReportRegistration/reducer.es6';
import tigoLoyaltyReducer from './containers/LoyaltyEligibilityTigoRwanda/reducer.es6';
import loyaltyRenewalReducer from './containers/LoyaltyRenewal/reducer.es6';
import createDebitReducer from './containers/DebitOrderManagement/reducer.es6';
import dailyDebitDebitTransactionReducer from './containers/DebitOrderTransactions/reducer.es6';
import policyReportReducer from './containers/ReportPolicies/reducer.es6';
import reportRefundReducer from './containers/ReportRefund/reducer.es6';
import reportPaidClaimReducer from './containers/ClaimPaidReport/reducer.es6';
import vasAgentReportReducer from './containers/ReportsAgent/reducer.es6';
import reportAgentWebReducer from './containers/ReportsAgentWeb/reducer.es6';
import reportPayableClaimsReducer from './containers/ReportsPayableClaims/reducer.es6';
import reportPaymentReducer from './containers/ReportsPayment/reducer.es6';
import reportClaimNotificationReducer from './containers/ReportClaimNotification/reducer.es6';
import reportClaimRegisterReducer from './containers/ReportClaimRegister/reducer.es6';
import reportPremiumRegisterReducer from './containers/ReportPremiumRegister/reducer.es6';
import reportSuspendedPoliciesReducer from './containers/ReportSuspendedPolicies/reducer.es6';
import reportPremiumDueReducer from './containers/ReportPremiumDue/reducer.es6';
import reportPreAuthorizationReducer from './containers/ReportPreAuthorization/reducer.es6';
import reportClaimDeathReducer from './containers/DeathClaimReport/reducer.es6';
import reportHospitalClaimReducer from './containers/HospitalClaimReport/reducer.es6';
import reportClaimReimbursementReducer from './containers/ReimbursementReport/reducer.es6';
import reportClaimFinalizationReimbursementReducer from './containers/ReimbursementFinalisationReport/reducer.es6';
import reportClaimFinalizationDeathReducer from './containers/DeathFinalizationClaimReport/reducer.es6';
import reportFinalizationHospitalClaimReducer from './containers/HospitalFinalizationClaimReport/reducer.es6';
import bulkSmsReducer from './containers/BulkSms/reducer.es6';
import countryReducer from './containers/Country/reducer.es6';
import languagesReducer from './containers/Languages/reducer.es6';
import partnersReducer from './containers/Partners/reducer.es6';
import productsReducer from './containers/ProductList/reducer.es6';
import productPremiumsReducer from './containers/ProductPremiums/reducer.es6';
import manualPaymentReducer from './containers/ManualPayment/reducer.es6';
import smsTemplatesReducer from './containers/SmsTemplate/reducer.es6'
import britamQuoteReducer from './containers/BritamLandingPage/reducer.es6.js'
import groupRegistrationReducer from './containers/Group/GroupRegistration/reducer.es6';
import currentGroupReducer from './containers/Group/GroupMembers/reducer.es6';
import listGroupsReducer from './containers/Group/MyGroups/reducer.es6';
import listAllGroupsReducer from './containers/Group/AllGroups/reducer.es6';
import manualRemittancesReducer from "./containers/ManualRemittance/reducer.es6";
import cashbackReducer from './containers/Cashback/reducer.es6';
import loyaltyUploadReducer from './containers/LoyaltyEligibility/reducer.es6';
import addEditMembersReducer from './containers/Group/AddEditMembers/reducer.es6';
import newProductsReducer from './containers/NewProductList/reducer.es6';
import partnerStatusReducer from './containers/PartnerStatus/reducer.es6';
import customerDependantsReducer from "./containers/DependantsList/reducer.es6";
import customerDocumentsReducer from "./containers/CustomerFiles/reducer.es6";
import newDebitOrderReducer from './containers/CustomerScreen/components/DebitOrder/state/reducer.es6';
import customerScreenReducer from './containers/CustomerScreen/reducer.es6';
import customerbeneficiarysReducer from "./containers/BeneficiaryList/reducers"
import customerBeneficiarybankdetailsReducer from "./containers/BeneficiaryBankDetailsList/reducers"
import customerbankdetailsReducer from "./containers/CustomerBankDetailsList/Reducers"
import quoteReducer from    './containers/Policyv2/Redux/reducer.es6';
import customerStatusv2Reducer from './containers/PolicyV2CustomerStatus/Redux/reducers.js'
import claimInitiationReducer from './containers/ClaimInitiationv2/Redux/reducers.js'
import claimDashboardReducer from './containers/ClaimDashboardv2/Redux/reducers.js';
import claimProcessingV2Reducer from './containers/ClaimsProcessingV2/Redux/reducers.js'
import documentGeneration from './containers/DocumentGeneration/reducer.es6';
import LouReducer from './partner-apps/ktda/PreAuth/Redux/reducers';
import dailyReportReducer from './containers/DailyReports/reducer'
import bulkPolicyOnboardingReducer from './partner-apps/ktda/BulkPolicyOnboarding/Redux/reducers'
import claimDashboardBulkApproveReducer from './partner-apps/ktda/ClaimDashboard/Redux/reducers';
import customerStatusKonyanaReducer from './partner-apps/Konyana/CustomerStatus/Redux/reducers';
import bulkPaymentsReducer from './partner-apps/ktda/BulkPayments/Redux/reducers';
import drawerReducer from './containers/Drawer/redux/reducers';
import orangePoliciesReducer from './partner-apps/Orange/CustomerStatus/Containers/Policies/Redux/reducers.js'
import orangePaymentReducer from './partner-apps/Orange/CustomerStatus/Containers/Payments/Redux/reducers.js'
import orangePremiumsReducer from './partner-apps/Orange/CustomerStatus/Containers/Premiums/Redux/reducers.js'
import orangeClaimsReducer from './partner-apps/Orange/CustomerStatus/Containers/Claims/Redux/reducers.js'
import orangeSmsActivityReducer from './partner-apps/Orange/CustomerStatus/Containers/SMSActivity/Redux/reducers.js'
import orangeCustomerDocumentsReducer from './partner-apps/Orange/CustomerStatus/Containers/Documents/Redux/reducers.js'
import featureFlags from './containers/FeatureFlags/reducer.es6.js';
import AccessBankRegistrationReducer from './partner-apps/AccessBank/CustomerStatus/Containers/RegisterCustomer/redux/reducers.js'
import policiesDashboardReducer from './partner-apps/AccessBank/PoliciesDashboard/Redux/reducers.js'
import policyProcessingReducer from './partner-apps/AccessBank/PoliciesProcessing/Redux/reducers.js'
import customerStatusAccessBankReducer from './partner-apps/AccessBank/CustomerStatus/Redux/reducers.js'
import paymentsDashboardReducer from './partner-apps/AccessBank/PaymentsDashboard/Redux/reducers.js'
import paymentProcessingReducer from './partner-apps/AccessBank/PaymentProcessing/Redux/reducers.js'
import accessBankQuoteReducer from './partner-apps/AccessBank/CustomerStatus/Containers/Quotes/Redux/reducers.js'
import accessBankPremiumsReducer from './partner-apps/AccessBank/CustomerStatus/Containers/Premiums/Redux/reducers.js'
import accessBankClaimsReducer from './partner-apps/AccessBank/CustomerStatus/Containers/Claims/redux/reducers.js'
import accessBankSmsActivityReducer from './partner-apps/AccessBank/CustomerStatus/Containers/SmsActivity/Redux/reducers.js'
import emailTemplatesReducer from './containers/EmailTemplates/Redux/reducer.js'
import customerAuditsAccessBankReducer from './partner-apps/AccessBank/CustomerStatus/Containers/Audits/Redux/reducers.js'
import reportsTemplatesReducer from './containers/ReportsConfigs/Redux/reducer.js'
import generateReportReducer from './containers/Reports/Redux/reducer.js'
import knowledgeBaseReducer from './partner-apps/AccessBank/Knowledgebase/Redux/reducer.js';
import elasticSearchReducer from './containers/ElasticSearch/Redux/reducers.js';
import customerStatusNGSGroupReducer from './partner-apps/NgsUganda/CustomerStatus/Redux/reducers.js'
import NGSGroupQuoteReducer from './partner-apps/NgsUganda/CustomerStatus/Container/Quotes/Redux/reducers.js'
import ngsGroupClaimsReducer from './partner-apps/NgsUganda/CustomerStatus/Container/Claims/redux/reducers.js'
import customerAuditsNgsGroupReducer from './partner-apps/NgsUganda/CustomerStatus/Container/Audits/Redux/reducers.js'
// Return a function to create the main reducer
export default function createReducer() {
  // connecting the explorer page state/store to its corresponding reducer
  return redux.combineReducers({
    customerRegistration: registrationReducer,
    login: loginReducer,
    policyPurchase: policyPurchaseReducer,
    search: searchCustomerByMsisdnReducer,
    currentCustomer: currentCustomerReducer, // Needs to map the tag in reducer initialstate
    customerStatus: customerStatusReducer,
    claimRequest: claimSubmissionReducer,
    policyList: policyListReducer,
    loader: mainLoaderReducer,
    profile: profileReducer,
    global: updateLanguageReducer,
    openClaimsList: openClaimReducer,
    payableClaimsList: payableClaimReducer,
    closedClaimsList: closedClaimReducer,
    claimsSummariesList: summaryClaimReducer,
    claimsStatusList: claimStatusReducer,
    claimSearch: claimSearchReducer,
    processingClaim: processingClaimReducer,
    reports: reportsReducer,
    createdPoliciesReportReducer: policyReportReducer,
    reportOpenClaimReducers: reportOpenClaimReducer,
    reportRegistrationReducers: reportRegistrationReducer,
    userAdmin: userAdminReducer,
    claimsCallBack: claimCallbackReducer,
    claimFinalisationReport: claimFinalisationReportReducer,
    tigoLoyalty: tigoLoyaltyReducer,
    loyaltyRenewal: loyaltyRenewalReducer,
    createDebitOrder: createDebitReducer,
    debitOrderTransactions: dailyDebitDebitTransactionReducer,
    reportRefund: reportRefundReducer,
    reportVasAgents: vasAgentReportReducer,
    reportAgentsWeb: reportAgentWebReducer,
    reportPayableClaimsReducers: reportPayableClaimsReducer,
    reportPayment: reportPaymentReducer,
    dailyReport: dailyReportReducer,
    bulkSms: bulkSmsReducer,
    reportPaidClaim: reportPaidClaimReducer,
    reportClaimNotification: reportClaimNotificationReducer,
    reportClaimRegister: reportClaimRegisterReducer,
    reportPremiumRegister: reportPremiumRegisterReducer,
    reportSuspendedPolicies: reportSuspendedPoliciesReducer,
    reportPremiumDue: reportPremiumDueReducer,
    country: countryReducer,
    languages: languagesReducer,
    partners: partnersReducer,
    products: productsReducer,
    productPremiums: productPremiumsReducer,
    manualPayment: manualPaymentReducer,
    smsTemplates: smsTemplatesReducer,
    britamQuote: britamQuoteReducer,
    groupRegistration: groupRegistrationReducer,
    currentGroup: currentGroupReducer,
    listGroups: listGroupsReducer,
    listAllGroups: listAllGroupsReducer,
    remittances: manualRemittancesReducer,
    cashback: cashbackReducer,
    loyaltyUpload: loyaltyUploadReducer,
    addEditMembers: addEditMembersReducer,
    newProducts: newProductsReducer,
    partnerStatus: partnerStatusReducer,
    customerBeneficiary: customerbeneficiarysReducer,
    customerDependants: customerDependantsReducer,
    customerDocuments: customerDocumentsReducer,
    newDebitOrder: newDebitOrderReducer,
    customerScreen: customerScreenReducer,
    customerBeneficiarybankdetails: customerBeneficiarybankdetailsReducer,
    customerbankdetails: customerbankdetailsReducer,
    quoteData: quoteReducer,
    customerStatusV2Data: customerStatusv2Reducer,
    customerStatusKonyanaState: customerStatusKonyanaReducer,
    claimInitiationData: claimInitiationReducer,
    claimDashboardData: claimDashboardReducer,
    claimProcessingv2Data: claimProcessingV2Reducer,
    documentGeneration: documentGeneration,
    LouData: LouReducer,
    bulkPolicyOnboarding: bulkPolicyOnboardingReducer,
    bulkApprovalData: claimDashboardBulkApproveReducer,
    bulkPayments:bulkPaymentsReducer,
    drawerData: drawerReducer,
    orangePoliciesData: orangePoliciesReducer,
    orangePaymentData: orangePaymentReducer,
    orangePremiumsData: orangePremiumsReducer,
    orangeClaimsData: orangeClaimsReducer,
    orangeSmsActivityData: orangeSmsActivityReducer,
    orangeDocumentsData: orangeCustomerDocumentsReducer,
    featureFlags: featureFlags,
    AccessBankRegistrationData: AccessBankRegistrationReducer,
    PoliciesDashboardData: policiesDashboardReducer,
    PolicyProcessingData: policyProcessingReducer,
    AccessBankPolicyData: customerStatusAccessBankReducer,
    PaymentsDashboardData:  paymentsDashboardReducer,
    PaymentProcessingData: paymentProcessingReducer,
    AccessBankQuoteData: accessBankQuoteReducer,
    AccessBankPremiumData: accessBankPremiumsReducer,
    AccessBankClaimsData: accessBankClaimsReducer,
    AccessBankSmsActivityData: accessBankSmsActivityReducer,
    emailTemplates: emailTemplatesReducer,
    reportPreAuthorization: reportPreAuthorizationReducer,
    reportClaimDeath: reportClaimDeathReducer,
    reportHospitalClaim: reportHospitalClaimReducer,
    reportClaimReimbursement: reportClaimReimbursementReducer,
    reportClaimFinalizationReimbursement: reportClaimFinalizationReimbursementReducer,
    reportClaimFinalizationDeath: reportClaimFinalizationDeathReducer,
    reportFinalizationHospitalClaim: reportFinalizationHospitalClaimReducer,
    AccessBankCustomerAudits: customerAuditsAccessBankReducer,
    ReportsTemplateData: reportsTemplatesReducer,
    GenerateReportData: generateReportReducer,
    knowledgeBaseState: knowledgeBaseReducer,
    elasticSearchData: elasticSearchReducer,
    NGSGroupcustomerStatusData: customerStatusNGSGroupReducer,
    NGSGroupQuoteData: NGSGroupQuoteReducer,
    NGSGroupClaimsData: ngsGroupClaimsReducer,
    customerAuditsNgsGroupData: customerAuditsNgsGroupReducer
  });
}
