import React from "react";
import { connect } from "react-redux";
import PaymentHeader from "./Components/PolicyHeader/PaymentHeader.js";
import NavigationWrapper from "./Components/NavigationWrapper/NavigationWrapper";
import { getPaymentProcessingDcpRequest, getPaymentsAuditsDcpRequest, paymentApproveActionRequest, paymentRejectActionRequest } from "./Redux/actions";
import ApprovePayment from "./Components/ApprovePayment/ApprovePayment.js";
import RejectPayment from "./Components/RejectPayment/RejectPayment.js";

class PaymentProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openRejectPayment: false
    };
  }

  componentDidMount() {
    let guid = this.props.params.guid
    this.props.dispatch(getPaymentProcessingDcpRequest({
      guid: guid,
    }));

  }
  toggleOpenRejectPayment() {
    this.setState({ openRejectPayment: !this.state.openRejectPayment })

  }
  approvePayment() {
    this.props.dispatch(paymentApproveActionRequest({
      guid: this.props.PaymentProcessingData.paymentProcessingDcp.guid,
      body: {
        "processing_status": "Received",
      }
    }));
  }

  rejectPayment(reasonsForRejectingPayment) {
    this.props.dispatch(paymentRejectActionRequest({
      guid: this.props.PaymentProcessingData.paymentProcessingDcp.guid,
      body: {
        "processing_status": "Rejected",
        "payment_failure_reason": reasonsForRejectingPayment
      }
    }));
  }


  getAllPaymentAudits() {
    if (this.props.PaymentProcessingData.paymentProcessingDcp) {

      const payment_guid = this.props.PaymentProcessingData.paymentProcessingDcp.guid

      this.props.dispatch(getPaymentsAuditsDcpRequest(payment_guid));

    }
  }



  render() {


    if (this.props.PaymentProcessingData.paymentProcessingDcp) {
      return (
        <>
          <PaymentHeader
            payment={this.props.PaymentProcessingData.paymentProcessingDcp}
            approvePayment={this.approvePayment.bind(this)}
            toggleOpenRejectPayment={this.toggleOpenRejectPayment.bind(this)}

          />
          <ApprovePayment
            showApprovePaymentProgressAlert={this.props.PaymentProcessingData.paymentApproveActionLoader}
            showApprovePaymentSuccessAlert={this.props.PaymentProcessingData.paymentApproveActionsuccess}
            showApprovePaymentErrorAlert={!!this.props.PaymentProcessingData.paymentApproveActionError}
            approvePaymentError={this.props.PaymentProcessingData.paymentApproveActionError}

          />
          <RejectPayment
            openRejectPayment={this.state.openRejectPayment}
            toggleOpenRejectPayment={this.toggleOpenRejectPayment.bind(this)}
            rejectPayment={this.rejectPayment.bind(this)}
            showRejectPaymentProgressAlert={this.props.PaymentProcessingData.paymentRejectActionLoader}
            showRejectPaymentSuccessAlert={this.props.PaymentProcessingData.paymentRejectActionsuccess}
            showRejectPaymentErrorAlert={!!this.props.PaymentProcessingData.paymentRejectActionError}
            rejectPaymentError={this.props.PaymentProcessingData.paymentRejectActionError}

          />
          <section style={{
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "auto",
            marginTop: "30px",
            padding: "30px"
          }}>
            <NavigationWrapper
              payment={this.props.PaymentProcessingData.paymentProcessingDcp}
              policyNumber={this.props.PaymentProcessingData.paymentProcessingDcp.additional_details ? this.props.PaymentProcessingData.paymentProcessingDcp.additional_details.policy_number : null}
              loader={this.props.PaymentProcessingData.get_paymentProcessingDcp_loader}

              getAllPaymentAudits={this.getAllPaymentAudits.bind(this)}
              audits={this.props.PaymentProcessingData.paymentsAuditsDcp}
            />
          </section>
        </>
      )
    } else {
      return "loading"
    }


  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  PaymentProcessingData: state.PaymentProcessingData,
  customerScreenData: state.customerScreen,
  globalData: state.global,
}))(PaymentProcessing);
