import React from 'react';
import { connect } from "react-redux";
import { getCustomerAuditsDcpRequest } from './Redux/actions';
import Audits from './Components/Audits/Audits';

class NGSGroupCustomerAudits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    if (this.props.customerData) {
      const entity_guid = this.props.customerData.currentCustomer.guid
      this.props.dispatch(getCustomerAuditsDcpRequest(entity_guid));
    }

  }



  render() {

    return (
      <>
        <Audits
          auditsLoader={this.props.customerAuditsNgsGroupData.get_CustomerAuditsDcp_loader}
          audits={this.props.customerAuditsNgsGroupData.customerAuditsDcp}
        />

      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  customerAuditsNgsGroupData: state.customerAuditsNgsGroupData
}))(NGSGroupCustomerAudits);