import * as types from '../../../../../../lib/actionTypes.es6'

export const initialState = {

  get_CustomerAuditsDcp_errors: null,
  get_CustomerAuditsDcp_loader: false,
  customerAuditsDcp: null,
};

export default function customerAuditsAccessBankReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_REQUEST:
      return { ...state, get_CustomerAuditsDcp_loader: true, get_CustomerAuditsDcp_errors: null };
    case types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_SUCCESS:
      return { ...state, get_CustomerAuditsDcp_loader: false, get_CustomerAuditsDcp_errors: null, customerAuditsDcp: action.payload };
    case types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_FAILURE:
      return { ...state, get_CustomerAuditsDcp_loader: false, get_CustomerAuditsDcp_errors: action.payload };

    default:
      return state;
  }
}

