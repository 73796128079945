import React, { useState } from 'react'
import { Box, Grid, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper'
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ViewDocument from '../../../../../shared-ui/ViewDocument/ViewDocument';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Payments = (props) => {
  const classes = useStyle();

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }

  if (!props.payments) {
    return <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
      <Grid container spacing={2} className={classes.claimdetailswrapper}>
        <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
          <p style={{ textAlign: 'center', marginTop: "30px" }}>No payments found</p>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  }


  const [expanded, setExpanded] = useState(false)
  const [viewDocumentUrl, setViewDocumentUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);





  const paymemtDisplay = expanded ? props.payments : props.payments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2)

  const currencyCode = getPartner().country.currency_code


  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }



  if (paymemtDisplay.length > 0) {
    return (
      <>
        {
          paymemtDisplay.map((payment, index) => (
            <>
              <Grid container className={classes.policyWrapper} key={index} >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{payment.product_name || "N/A"}</h6>
                          <p> Product Name</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                          <Box display="grid" justifyContent="flex-end">
                            <p> Processing Status</p>
                            <h6>{payment.processing_status || "N/A"}</h6>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Payment Reference </p>
                          <h6> {payment.mno_reference || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> Created At </p>
                      <h6>{formatDateLocale(payment.created_at) || "N/A"}
                      </h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Effected At </p>
                      <h6>{formatDateLocale(payment.effected_at) || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Proof Of Payment </p>
                      <h6>
                        {
                          payment.proof_of_payment_url ? (<>
                            <Tooltip title={"View Document"}>
                              <span style={{ cursor: "pointer" }} onClick={() => setViewDocumentUrl(payment.proof_of_payment_url)}><VisibilityIcon sx={{ color: " #4B7E34" }} /></span>
                            </Tooltip>
                            <Tooltip title="Download Document">
                              <span style={{ cursor: "pointer", marginLeft: "10px" }} ><a href={payment.proof_of_payment_url} download target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                            </Tooltip>
                          </>) : "N/A"
                        }</h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={6} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>Payment Failure Reason</p>
                              <h6>{payment.payment_failure_reason || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <p> Message</p>
                          <h6>{payment.message || "N/A"}</h6>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyBalance}>
                        <p>Amount</p>
                        <h6>{`${currencyCode} ${payment.amount_in_cents / 100}` || "0.00"}   </h6>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              paymemtDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>
        <ViewDocument
          title="Proof Of Payment Document"
          open={viewDocumentUrl}
          close={() => setViewDocumentUrl(null)}
          viewDocumentUrl={viewDocumentUrl}
          numPages={numPages}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        />
      </>
    )
  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No payments found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

}

export default Payments