import React, { Component } from 'react'
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import Navigation from './components/Navigation/Navigation';
import Banner from './components/Banner/Banner';
import Stats from './components/Stats/Stats';
import Aspinprocess from './components/AspinProcess/Aspinprocess';
import Partners from './components/Partners/Partners';
import AspinFeatures from './components/Aspinfeatures/AspinFeatures';
import Products from './components/Products/Products';
import BuiltForDevelopers from './components/BuiltForDeveloper/BuiltForDevelopers';
import Faqs from './components/Faqs/Faqs';
import Testimonials from './components/Testominials/Testimonials';
import Footer from './components/Footer/Footer';
import { ThemeProvider } from "@material-ui/styles";
import { LandingPageTheme } from './LandingPageTheme';
import { userLoginRequest, getStartedRequest,resetgetStartedSuccess,resetGetStartedError } from '../actions.es6'
import { sesssionMonitor } from '../../../lib/access.es6';
import { getEnvironmentInformation, reloadPage } from '../../../lib/utils.es6';
import { updateGlobalLanguage } from '../../Main/actions.es6'
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../../lib/localisation.es6';
import LoginPopup from './components/LoginPopup/LoginPopup';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';



const getForgortPasswordURL = () => {
  const EnvironmentalInformation = getEnvironmentInformation();
  const { realm } = EnvironmentalInformation.credentials;
  return `${EnvironmentalInformation.servicesURLs.centralAuthentication}auth/realms/${realm}/login-actions/reset-credentials?client_id=${EnvironmentalInformation.credentials.clientID}`
}

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openLoginPop: false
    };
  }



  login({ username, password }) {
    this.props.dispatch(userLoginRequest({ username, password }));
  }


  openForgetPassword() {
    const forgotPasswordURL = getForgortPasswordURL();
    window.location.href = forgotPasswordURL;
  }

  updateGlobalLanguage(language) {
    this.props.dispatch(updateGlobalLanguage(language));
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.updateGlobalLanguage(language);
    }
  }
  toggleLoginPopup() {
    this.setState({
      openLoginPop: !this.state.openLoginPop
    })
  }
  toogleClose() {
    this.setState({
      openLoginPop: false
    })
  }
  componentWillMount() {
    const FQDN = window.location.origin;
    const britamURLS = [
      'https://staging.grouplastexpense.com',
      'https://grouplastexpense.com'
    ];
    if (britamURLS.includes(FQDN)) {
      hashHistory.push('britam');
    }

    this.updateLanguage();
  }

  componentDidUpdate() {
    if (
      this.props.loginData.AuthData &&
      this.props.loginData.AuthData.access_token &&
      this.props.loginData.sessionOpen
    ) {
      sesssionMonitor();
      hashHistory.push('admin/home');
      reloadPage();
    }
    this.updateLanguage();
  }

  getStarted(payload) {
    this.props.dispatch(getStartedRequest({ payload }));
  }

  render() {
    return (
      <>
        <ThemeProvider theme={LandingPageTheme}>
          {/* <Snackbar 
            open={true} 
            onClose={() => { console.log("close") }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => { console.log("close") }}
              severity="warning"
              variant="filled"
              sx={{ width: '100%' }}
            >
             <span role="img" aria-label="main">🛠️</span> Scheduled maintenance in progress. We’ll return in a moment!
            </Alert>
          </Snackbar> */}
          
          <Navigation
            toggleLoginPopup={this.toggleLoginPopup.bind(this)}
          />
          <LoginPopup
            open={this.state.openLoginPop}
            close={this.toogleClose.bind(this)}
            login={this.login.bind(this)}
            loading={this.props.loginData.loader}
            loginError={this.props.loginData.errors}
            forgetpassword={this.openForgetPassword.bind(this)}
            language={this.props.globalData.language}
            updateGlobalLanguage={this.updateGlobalLanguage.bind(this)}
            labels={this.state.labels}
          />
          <Banner
            getStarted={this.getStarted.bind(this)}
            getStartedError={this.props.loginData.getStartedMessageError}
            getStartedProgressAlert={this.props.loginData.getStartedMessageLoader}
            getStartedSuccessAlert={this.props.loginData.getStartedMessageSuccess}
            getStartedErrorAlert={!!this.props.loginData.getStartedMessageError}

            resetgetStartedSuccessAlert={() => { this.props.dispatch(resetgetStartedSuccess()) }}
            resetgetStartedErrorAlert={() => { this.props.dispatch(resetGetStartedError()) }}
          />
          <Stats />
          <Aspinprocess />
          <Partners />
          <AspinFeatures />
          <Products />
          <BuiltForDevelopers />
          <Faqs />
          <Testimonials />
          <Footer />
        </ThemeProvider>

      </>
    )
  }
}

export default connect(state => ({
  loginData: state.login,
  globalData: state.global,
}))(LandingPage);