import React from "react";
import './adonsTable.css'
import TableInc from "../../../../shared-ui/TableInc";
import { updateExtendedConfig } from "../../../../lib/requests.es6";
import { RELATIONSHIP_POOL } from "../../PoductConstants";

export default function Extended(props) {

  const tempArr = props.extended.map(entry => {

    return {
      ...entry,
      relationship: entry.relationship,
      min: entry.age_range.min,
      max: entry.age_range.max,
      max_count: entry.max_count,
      male_count: entry.male_count,
      female_count: entry.female_count

    }

  })

  if (props.extended) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>


          <TableInc
            title="Extended members relationship"
            columns={[
              { title: 'Relationship', field: 'relationship', lookup: RELATIONSHIP_POOL },
              { title: 'Minimium Age', field: 'min' , type: 'numeric' },
              { title: 'Maximum Age', field: 'max' , type: 'numeric' },
              { title: '# of Males allowed', field: 'male_count' , type: 'numeric' },
              { title: '# of Females allowed', field: 'female_count' , type: 'numeric' },
              { title: 'Max Members Allowed', field: 'max_count' , type: 'numeric' },
            ]}
            data={
              tempArr
            }
            options={{
              pageSize: tempArr.length,
              showTitle: true,
              actionsColumnIndex: -1,
              addRowPosition: 'first'

            }}

            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {

                  const formattedData = {
                    "relationship": newData.relationship,
                    "age_range": {
                      "min": Number(newData.min),
                      "max": Number(newData.max)
                    },
                    "max_count": Number(newData.max_count),
                    'male_count': newData.male_count,
                    'female_count': newData.female_count
                  };
                  const extendedConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      extended: [...props.extendedConfig.content.extended, formattedData]
                    }
                  }
                  updateExtendedConfig(extendedConfig).then(
                    (response) => {
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);
                    }
                  ).catch(
                    (error) => {
                      console.log("did not work", error)
                      reject();
                    }
                  )

                }),

              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  console.log('you are deleting this: ', oldData)
                  const indexToDelete = oldData.tableData.id
                  const extended = props.extendedConfig.content.extended
                  extended.splice(indexToDelete, 1)
                  const extendedConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      extended: extended
                    }
                  }
                  updateExtendedConfig(extendedConfig).then(
                    (response) => {
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);
                    }
                  ).catch(
                    (error) => {
                      reject();
                    }
                  )
                  resolve();
                }),
            }}

          />
        </div>
      </>
    );
  }
  return "No extended found, click on the add button to add new premums."
}
