import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { canViewDashboard, hasAuthourity } from '../../lib/access.es6';
import SlideshowIcon from '@mui/icons-material/Slideshow';


export const airtelMalaiMenu = (labels) => {
  return [
    {
      id: 'application',
      title: 'Home',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [],
      children: [
        {
          id: 'admin/home',
          title: labels.home,
          type: 'item',
          url: 'admin/home',
          icon: HomeOutlinedIcon,
          permisions: []
        },
        {
          id: 'admin/dashboards',
          title: labels.dashboard,
          type: 'item',
          url: 'admin/dashboards',
          icon: DashboardCustomizeOutlinedIcon,
          permisions: [canViewDashboard()]
        },
        {
          id: 'admin/internal_dashboards',
          title: labels.internalDashboard,
          type: 'item',
          url: 'admin/internal_dashboards',
          icon: DashboardIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')]
        },
        {
          id: 'Claims',
          title: labels.Claims,
          type: 'collapse',
          url: 'admin/claims',
          icon: InventoryOutlinedIcon,
          permisions: [hasAuthourity('VIEW_CLAIMS'),],
          children: [{
              id: 'admin/claims_dashboard',
              title: labels.ClaimsDashboard,
              type: 'item',
              url: 'admin/claims_dashboard',
              permisions: []
            },
            {
              id: 'admin/claim_search',
              title: labels.searchClaim,
              type: 'item',
              url: 'admin/claim_search',
              permisions: []
            },
            {
              id: 'admin/claim_callback',
              title: labels.claimCallback,
              type: 'item',
              url: 'admin/claim_callback',
              permisions: []
            },
            {
              title: labels.openClaims,
              type: 'item',
              id: 'admin/claims_open',
              url: 'admin/claims_open',
              permisions: []
            },
            {
              title: labels.claimPayable,
              type: 'item',
              url: 'admin/claims_payable',
              id: 'admin/claims_payable',
              permisions: []
            },
            {
              title: labels.closedClaims,
              type: 'item',
              id: 'admin/claims_closed',
              url: 'admin/claims_closed',
              permisions: []
            },
            {
              title: labels.cashback,
              type: 'item',
              id: 'admin/cashback',
              url: 'admin/cashback',
              permisions: [hasAuthourity('VIEW_CASHBACK')]
            }
            ]

        },
        {
          id: 'Payments',
          title: labels.payments,
          type: 'collapse',
          url: 'admin/missing/payments',
          icon: AccountBalanceOutlinedIcon,
          permisions: [hasAuthourity('PREMIUM_UPDATE')],
          children:
            [
              {
                id: 'admin/bulk-payments',
                title: labels.bulkPayments,
                type: 'item',
                url: 'admin/bulk-payments',
                permisions: [hasAuthourity('PREMIUM_UPDATE')]
              },
            ]
        },
        {
          id: 'admin/reports',
          title: 'New Reports',
          type: 'item',
          url: 'admin/reports',
          icon: DashboardIcon,
          permisions: [hasAuthourity('VIEW_NEW_REPORTS_INTERNAL')]
        },
        {
          id: 'Reports',
          title: labels.reports,
          type: 'collapse',
          url: 'reports',
          icon: AssessmentOutlinedIcon,
          permisions: [hasAuthourity('VIEW_BUSINESS_REPORT')],
          children:

            [
              {
                title: "Daily Report",
                type: 'item',
                url: 'admin/daily_reports',
                id: 'admin/daily_reports',
                permisions: []
              },
              {
                title: labels.premiumReport,
                type: 'item',
                url: 'admin/premium_report',
                id: 'admin/premium_report',
                permisions: []
              },
              {
                title: labels.payments,
                type: 'item',
                url: 'admin/payment_report',
                id: 'admin/payment_report',
                permisions: []
              },
              {
                title: labels.ClaimFinalisation,
                type: 'item',
                url: 'admin/claim_finalisation_report',
                id: 'admin/claim_finalisation_report',
                permisions: []
              },
              {
                title: labels.openClaims,
                type: 'item',
                url: 'admin/open_claim_report',
                id: 'admin/open_claim_report',
                permisions: []
              },
              {
                title: labels.registrations,
                type: 'item',
                url: 'admin/registration_report',
                id: 'admin/registration_report',
                permisions: []
              },
              {
                title: labels.titlePolicies,
                type: 'item',
                url: 'admin/created_policies_report',
                id: 'admin/created_policies_report',
                permisions: []
              },
              {
                title: labels.vasReport,
                type: 'item',
                url: 'admin/refunds_report',
                id: 'admin/refunds_report',
                permisions: []
              },
              {
                title: labels.reportAgentWeb,
                type: 'item',
                url: 'admin/vas_agents_report',
                id: 'admin/vas_agents_report',
                permisions: []
              },
              {
                title: labels.reportSuspendedPolicies,
                type: 'item',
                url: 'admin/suspended_policies_report',
                id: 'admin/suspended_policies_report',
                permisions: []
              },
              {
                title: labels.reportPayableClaims,
                type: 'item',
                url: 'admin/claims_payable_report',
                id: 'admin/claims_payable_report',
                permisions: []
              },
              {
                title: labels.premiumDueReport,
                type: 'item',
                url: 'admin/premium_due_report',
                id: 'admin/premium_due_report',
                permisions: []
              },
            ]
        },
        {
          id: 'Loyalty',
          title: labels.loyalty,
          partnes: ['mfs', 'equity', 'orange'],
          type: 'collapse',
          url: 'Loyalty',
          icon: LocalOfferOutlinedIcon,
          permisions: [hasAuthourity('LOYALTY_ELIGIBILITY')],
          children:
            [{
              title: labels.loyaltyEligibility,
              type: 'item',
              url: 'admin/loyalty_eligibility',
              id: 'admin/loyalty_eligibility',
              permisions: []
            },

            ]
        },
        {
          id: 'SMS',
          title: labels.bulkSms,
          type: 'collapse',
          url: 'SMS',
          icon: ChatBubbleOutlineOutlinedIcon,
          permisions: [hasAuthourity('BULK_SMS_BLAST')],
          children:
            [{
              title: labels.bulkSms,
              type: 'item',
              url: 'admin/bulk_sms',
              id: 'admin/bulk_sms',
              permisions: []
            },

            ]
        },
        {
          title: 'Knowledge Base',
          label: labels.partners,
          icon: SlideshowIcon,
          type: 'item',
          url: 'admin/knowledge_base',
          id: 'admin/knowledge_base',
          permisions: []
        }

      ]
    },
    {
      id: 'application',
      title: 'Configs',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [canViewDashboard()],
      children: [
        {
          title: 'Configurations',
          id: 'Configurations',
          label: labels.Configurations,
          type: 'collapse',
          url: 'configs',
          icon: SettingsOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [{
              title: 'Products',
              label: labels.products,
              type: 'item',
              url: 'admin/products',
              id: 'admin/products',
              permisions: [hasAuthourity('VIEW_ALL_PRODUCTS')]
            },
            {
              title: 'SMS Templates',
              label: labels.smsTemplates,
              type: 'item',
              url: 'admin/view_sms_templates',
              id: 'admin/view_sms_templates',
              permisions: [hasAuthourity('VIEW_ALL_SMS_TEMPLATES')]
            },
            {
              title: 'Document Templates',
              label: labels.Templates,
              type: 'item',
              url: 'admin/view_doc_templates',
              id: 'admin/view_doc_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Partner',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners/view/partner',
              id: 'admin/partners/view/partner',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            }
            ]
        },
        {
          title: labels.InternalConfigurations,
          id: 'Internal Configurations',
          label: labels.InternalConfigurations,
          type: 'collapse',
          url: 'Internal Configurations',
          icon: TuneOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [
              {
                title: 'Reports Configs',
                label: 'Reports Configs',
                type: 'item',
                url: 'admin/view_reports_configs',
                id: 'admin/view_reports_configs',
                permisions: []
              },
              {
              title: 'Partners',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners',
              id: 'admin/partners',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            },
            {
              title: 'User Administration',
              label: labels.userAdministration,
              type: 'item',
              url: 'admin/users',
              id: 'admin/users',
              permisions: [hasAuthourity('VIEW_ALL_USER')]
            },
            {
              title: 'Country Administration',
              label: labels.countryAdministration,
              type: 'item',
              url: 'admin/country',
              id: 'admin/country',
              permisions: [hasAuthourity('VIEW_COUNTRIES')]
            },
            {
              title: 'Languages',
              label: labels.languages,
              type: 'item',
              url: 'admin/languages',
              id: 'admin/languages',
              permisions: []
            },
            {
              title: 'Feature Flags',
              label: 'Feature Flags',
              type: 'item',
              url: 'admin/feature-flags',
              id: 'admin/feature-flags',
              permisions: []
            },
            ]
        },
      ]
    },
  ]
}

