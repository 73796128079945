import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import LocalizedStrings from 'react-localization';
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress, DialogActions } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";
import AlertDialog from "../../../components/AlertDialog/index.es6";
import MultipleSelectChip from "../../PartnerStatus/components/Edit/MultipleSelectChip";
import { useEffect } from "react";
import { localisedText } from "../../../lib/localisation.es6";

const EditUser = (props) => {
    if (!props.user) {
        return ""
    }

    const [labels] = useState(new LocalizedStrings(localisedText))
    const [firsName, setFirstName] = useState(props.user.firstName);
    const [lastName, setLastName] = useState(props.user.lastName);
    const [currentUserReableRoles, setCurrentUserReableRoles] = useState(props.currentUserReableRoles);
    const [currentPartners, setCurrentPartners] = useState(props.currentUserPartners);


    useEffect(() => {
        setCurrentUserReableRoles(props.currentUserReableRoles)
    }, [props.currentUserReableRoles])

    useEffect(() => {
        setCurrentPartners(props.currentUserPartners)
    }, [props.currentUserPartners])


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setFirstName(value)
        }
        if (name === 'lastname') {
            setLastName(value)
        }
    }
    const handleSubmit = () => {
        const payload = {
            "id": props.user.id,
            "groups": [
                ...props.allData.readableRoles.filter((e) => currentUserReableRoles.some((ae) => e.name === ae)).map(i => i.path.substring(1))
            ],
            "lastName": lastName,
            "firstName": firsName,
            "attributes": {
                "partners": [
                    ...props.allData.reablePartners.filter((e) => currentPartners.some((ae) => e.name === ae)).map(i => i.guid)
                ],

            },
            "enabled": true
        }
        props.editUser(payload);
    }

    const reset = () => {
        setFirstName('');
        setLastName('');
        setCurrentUserReableRoles([]);
        setCurrentPartners([]);
    }

    const handleUserEditedSuccessfully = () => {
        props.resetEditUserSuccessAlert()
        props.resetUserToEdit();
        reset()
    }
    const handleUserCreateError = () => {
        props.resetEditUserErrorAlert()
    }

    const cancelEdit = () => {
        props.resetUserToEdit()
    }

    return (
        <>
            <AlertDialog
                custom
                show={props.showEditUserProgressAlert}
                size="sm"
                style={{ marginTop: '0', top: '30vh' }}
                confirmBtnText={'ok'}
                showCancel={false}
                showConfirm={false}
                cancelBtnText={'cancel'}
                showTitle={false}
                confirmBtnCssClass
            >
                <CircularProgress />
                <h2>Updating User</h2>
            </AlertDialog>
            <AlertDialog
                success
                show={props.showEditUserSuccessAlert}
                size="sm"
                title={'User updated'}
                style={{ marginTop: '0', top: '30vh' }}
                onConfirm={() => { handleUserEditedSuccessfully() }}
                confirmBtnText={'ok'}
                showCancel={false}
                showConfirm={false}
                cancelBtnText={'cancel'}
                confirmBtnCssClass
            >
                <div >
                    <Button variant="contained" onClick={() => { handleUserEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
                </div>
            </AlertDialog>
            <AlertDialog
                show={props.showEditUserErrorAlert}
                danger
                title={'Error updating User'}
                onConfirm={() => handleUserCreateError()}
                confirmBtnText={'Try again'}
                confirmBtnCssClass
                showCancel={false}
                style={{ marginTop: '0', top: '30vh' }}
            >
                {"Error updating User"}
            </AlertDialog>
            <Dialog
                open={props.user} fullWidth
                maxWidth='lg'
                onClose={props.resetUserToEdit}
                // fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.resetUserToEdit}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Edit User
                        </Typography>
                    </Toolbar>
                </AppBar>
                    <ValidatorForm onSubmit={() => handleSubmit()}  >
                <DialogContent>

                        <Grid container spacing={4} style={{marginTop: '2%'}}>
                            <Grid item md={6} sm={6} xs={12}>
                                <TextValidator
                                    onChange={handleChange}
                                    label={'First name'}
                                    value={firsName}
                                    name='name'
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    validators={['required']}
                                    errorMessages={['Please enter first name']}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <TextValidator
                                    onChange={handleChange}
                                    label={'Last Name'}
                                    value={lastName}
                                    name='lastname'
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    validators={['required']}
                                    errorMessages={['Please enter last name']}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                {<MultipleSelectChip
                                    constantFields={props.allPartners}
                                    fieldLabel="partners"
                                    fields={currentPartners}
                                    setFields={setCurrentPartners}
                                />}
                            </Grid>
                        </Grid>
                </DialogContent>

                <DialogActions >
                    <Button variant="contained" type="submit"  style={{ background: PRIMARY_THEME_COLOR_SHADE_1, }}> {labels.updateUser}</Button>
                    <Button variant="outlined" onClick={cancelEdit} style={{ marginRight: '3%', borderColor: PRIMARY_THEME_COLOR_SHADE_1, color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bold' }}> {labels.cancel}</Button>
                </DialogActions>
                    </ValidatorForm>
            </Dialog>
        </>
    )
}

export default EditUser