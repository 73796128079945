import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getAllPolicies, getAllPayments, getAllPremiums, getAllProducts, getAllCustomerClaims, getTotalPayablePremium ,getAllMandates} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getExtendedFamilyProductConfigsRequest } from '../NewProductList/actions.es6';

export function* getAllPoliciesWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_POLICIES_REQUEST);
    let response = {};
    try {
      const msisdn = requestAction.payload;
      response = yield call(getAllPolicies, msisdn);
      yield put(actions.getAllPoliciesSuccess(response.data));
      const product_guid = getProductGuid(response.data)
      yield put(getExtendedFamilyProductConfigsRequest(product_guid));
      const policy_guid = getPolicyGuid(response.data)
      yield put(actions.getTotalPayablePremiumRequest(policy_guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllPoliciesFail(errorPayload));
    }
  }
}


export function* getAllPaymentsWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_PAYMENTS_REQUEST);
    let response = {};
    try {
      const msisdn = requestAction.payload;
      response = yield call(getAllPayments, msisdn);
      yield put(actions.getAllPaymentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllPaymentsFail(errorPayload));
    }
  }
}


export function* getAllPremiumsWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_PREMIUMS_REQUEST);
    let response = {};
    try {
      const msisdn = requestAction.payload;
      response = yield call(getAllPremiums, msisdn);
      yield put(actions.getAllPremiumsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllPremiumsFail(errorPayload));
    }
  }
}


export function* getAllProductsWatcher() {
  while (true) {
    yield take(types.GET_ALL_PRODUCTS_REQUEST);
    let response = {};
    try {
      response = yield call(getAllProducts);
      yield put(actions.getAllProductsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllProductsFail(errorPayload));
    }
  }
}


export function* getAllCustomerClaimsWatcher() {
  while (true) {
    const req = yield take(types.GET_ALL_CUSTOMER_CLAIMS_REQUEST);
    let response = {};
    try {
      response = yield call(getAllCustomerClaims, req.payload);
      yield put(actions.getAllCustomerClaimsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllCustomerClaimsFail(errorPayload));
    }
  }
}

export function* getTotalPayablePremiumWatcher() {
  while (true) {
    const req = yield take(types.GET_TOTAL_PAYABLE_PREMIUM_REQUEST);
    let response = {};
    try {
      response = yield call(getTotalPayablePremium, req.payload);
      yield put(actions.getTotalPayablePremiumSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getTotalPayablePremiumFail(errorPayload));
    }
  }
}


export function* getAllMandatesWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_MANDATES_REQUEST);
    let response = {};
    try {
      console.log("requestAction.payload", requestAction.payload)
      const msisdn = requestAction.payload;
      response = yield call(getAllMandates, msisdn);
      yield put(actions.getAllMandatesSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllMandatesFail(errorPayload));
    }
  }
}



function getProductGuid(policies){
  for(let p of policies){
    if(p.active){
      return p.product_guid
    }
  }
  return null
}

function getPolicyGuid(policies){
  for(let p of policies){
    if(p.active){
      return p.guid
    }
  }
  return null
}