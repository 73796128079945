import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,

  get_claims_dcp_errors: null,
  get_claims_dcp_loader: false,
  get_claims_dcp_success_message: '',
  claims_dcp: [],

  bulkApprovalClaim: null,
  bulkApprovalClaimSuccess: false,
  bulkApprovalClaimLoader: false,
  bulkApprovalClaimError: null,

};

export default function claimDashboardBulkApproveReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_CLAIMS_DCP_REQUEST:
      return { ...state, get_claims_dcp_loader: true, get_claims_dcp_errors: null,  claims_dcp: [] };
    case types.GET_CLAIMS_DCP_SUCCESS:
      return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: null, claims_dcp: action.payload };
    case types.GET_CLAIMS_DCP_FAILURE:
      return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: action.payload };


    case types.BULK_APPROVE_REQUEST:
      return {
        ...state,
        bulkApprovalClaimError: null,
        bulkApprovalClaimLoader: true,
        bulkApprovalClaimSuccess: false,
        bulkApprovalClaim: null
      };

    case types.BULK_APPROVE_SUCCESS:
      return {
        ...state,
        bulkApprovalClaim: action.payload,
        bulkApprovalClaimSuccess: true,
        bulkApprovalClaimLoader: false,
        bulkApprovalClaimError: null,
      };

    case types.BULK_APPROVE_FAILURE:
      return  { ...state, bulkApprovalClaimLoader: false, bulkApprovalClaimError: action.payload };


    case types.RESET_BULK_APPROVE_SUCCESS:
      return { ...state, bulkApprovalClaimSuccess: false };

    case types.RESET_BULK_APPROVE_FAILURE:
      return { ...state, bulkApprovalClaimError: null };

    default:
      return state;
  }
}
