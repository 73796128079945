import React, { useState } from 'react';
import TableInc from '../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../lib/requests.es6';
import { v4 as uuidv4 } from 'uuid';

const PremiumsNuclear = (props) => {
  let dataContent = [];

  if (props.membership_configs && props.membership_configs.content) {
    dataContent = props.membership_configs.content.premium_nuclear;
  }

  const [data, setData] = useState(dataContent || []);

  const currencyCode = props.currencyCode;

  const updateMembershipConfigs = async (newData) => {
    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        premium_nuclear: newData
      }
    }

    await updateExtendedConfig(payload)

    window.location.reload(true);
  };

  return (
    <TableInc
      title="Main Premiums"
      columns={[
        { title: 'type', field: 'type', 
        lookup: {
          'Member Only': 'Member Only',
          'Member & Family': 'Member & Family',
        } },
        { title: 'Premium Amount', field: 'premium_amount', type: 'numeric',
        render: rowData =>  currencyCode + " " + (rowData.premium_amount/100).toLocaleString() },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length,
      }}
      actionsColumnIndex={-1}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            newData.premium_amount = newData.premium_amount * 100;
            newData.uuid = uuidv4()
            delete newData.tableData
            const newDataArray = [...data, newData];
            setData(newDataArray);
            updateMembershipConfigs(newDataArray);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.premium_amount = newData.premium_amount * 100;
            dataUpdate[index] = newData;
            setData(dataUpdate);
            updateMembershipConfigs(dataUpdate);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData(dataDelete);
            updateMembershipConfigs(dataDelete);
            resolve();
          }),
      }}
    />
  );
};

export default PremiumsNuclear;
