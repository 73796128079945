import React from "react";
import { connect } from "react-redux";
import ClaimsHeader from "./Components/ClaimsHeader/ClaimsHeader";
import ClaimsWrapper from "./Components/ClaimsWrapper/ClaimsWrapper";
import * as actions from "./Redux/actions.js"
import { ClaimStatusEnum } from "./enum";
import { getPartnerGuid } from "../../../lib/access.es6";


class ClaimDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentWillMount() {
    const payload = {
      "partner": getPartnerGuid(),
      "statuses": ClaimStatusEnum.docs_pending
    }
    this.props.dispatch(actions.getClaimsDcpRequest(payload))
  }

  getClaims(payload) {
    this.props.dispatch(actions.getClaimsDcpRequest(payload
    ))
  }

  handleBulkApproval(payload) {
    this.props.dispatch(actions.bulkApproveRequest(payload))
  }


  render() {

    return (
      <>
        <ClaimsHeader
          title="Claims Dashboard"
        />

        <ClaimsWrapper
          claimsData={this.props.claimDashboardData.claims_dcp }
          type="Death"
          hasAlerts={true}
          hasBulkApprove={true}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.bulkApprovalData.get_claims_dcp_loader}
          handleBulkApproval={this.handleBulkApproval.bind(this)}

          showBulkApproveProgressAlert={this.props.bulkApprovalData.bulkApprovalClaimLoader}
          showBulkApproveSuccessAlert={this.props.bulkApprovalData.bulkApprovalClaimSuccess}
          showBulkApproveErrorAlert={!!this.props.bulkApprovalData.bulkApprovalClaimError}
          bulkApproveError={this.props.bulkApprovalData.bulkApprovalClaimError}

          resetBulkApproveSuccessAlert={() => { this.props.dispatch(actions.resetbulkApprovalSuccess()) }}
          resetBulkApproveErrorAlert={() => { this.props.dispatch(actions.resetbulkApprovalFailure()) }}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  bulkApprovalData: state.bulkApprovalData,
  globalData: state.global,
}))(ClaimDashboard);