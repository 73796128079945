import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getPolicyProcessingDcp , policyActions, policyRejectActions,policyNotes, cancelV2Policy, getAuditsDcp,deletePolicyDocument, getPaymentByPolicyGuid} from '../../../../lib/requests.es6';

import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';

export function* getPolicyProcessingDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_POLICY_PROCESSING_DCP_REQUEST);
    try {
      const response = yield call(getPolicyProcessingDcp, request.payload);
      yield put(actions.getPolicyProcessingDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPolicyProcessingDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* policyActionsRequestWatcher() {
  for (;;) {
    const request = yield take(types.POLICY_ACTION_REQUEST);
    try {
      const response = yield call(policyActions, request.payload);
      yield put(actions.policyActionSuccess(response.data))
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.policyActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* policyRejectActionsRequestWatcher() {
  for (;;) {
    const request = yield take(types.POLICY_REJECT_ACTION_REQUEST);
    try {
      const response = yield call(policyRejectActions, request.payload);
      yield put(actions.policyRejectActionSuccess(response.data))
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.policyRejectActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* cancelPolicyActionRequestWatcher() {
  for (;;) {
    const request = yield take(types.CANCEL_POLICY_ACTION_REQUEST);
    try {
      const response = yield call(cancelV2Policy, request.payload.policy.guid);
      yield put(actions.addPolicyNotesRequest(request.payload.note));
      yield put(actions.cancelPolicyActionSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.cancelPolicyActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* addPolicyNotesAddRequestWatcher() {
  for (;;) {
    const request = yield take(types.ADD_POLICY_NOTES_REQUEST);
    try {
      const response = yield call(policyNotes, request.payload);
      yield put(actions.addPolicyNotesSuccess(response.data));
    } catch (e) {

      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.addPolicyNotesFailure(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPolicyAuditsDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_POLICY_AUDITS_PROCESSING_DCP_REQUEST);
    try {
      const response = yield call(getAuditsDcp, request.payload);
      yield put(actions.getPolicyAuditsDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPolicyAuditsDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteCustomerPolicyDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_POLICY_DOCUMENT_REQUEST);

      const response = yield call(deletePolicyDocument, payload);
      yield put(actions.deletepolicyDocumentSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deletepolicyDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* getPaymentsByPolicyGuidRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_PAYMENTS_BY_POLICY_GUID_REQUEST);
    try {
      const response = yield call(getPaymentByPolicyGuid, request.payload);
      yield put(actions.getPaymentsByPolicyGuidSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPaymentsByPolicyGuidFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}