import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { hashHistory } from 'react-router';
import * as actions from "./Redux/actions"
import Grid from "@material-ui/core/Grid";
import Principal from '../Policyv2/Components/Principal/Principal';
import Policies from './Components/Policies/Policies'
import Quotes from './Components/Quotes/Quotes'
import Premiums from './Components/Premiums/Premiums'
import Payments from './Components/Payments/Payments';
import { getCustomer } from '../Customer/actions.es6';
import { addMissingPaymentRequest, addMissingPaymentReset } from '../CustomerStatus/actions.es6';
import { Container } from '@material-ui/core';
import PolicyHeader from './Components/PolicyHeader/PolicyHeader'
import RegisterCustomer from './Components/RegisterCustomer/RegisterCustomer'
import { registerCustomerRequest, resetRegistration } from '../CustomerScreen/components/RegisterCustomer/actions.es6';
import { POLICY_V2_PARTNERS } from '../../lib/constants';
import { getPartnerGuid, hasAuthourity } from '../../lib/access.es6';
import { updateCustomerRequest, resetUpdateCustomerl } from '../Customer/actions.es6.js';
import CancelPolicy from './Components/Policies/CancelPolicy';
import Claims from './Components/Claims/Claims'
import MakePayment from './Components/Payments/MakePayment';
import BoxInc from '../../shared-ui/BoxInc/Index';
import ButtonInc from '../../shared-ui/ButtonInc';
import { amendQuoteRequest, changeQuoteRequest, createQuoteRequest, resetAmendQuoteError, resetAmendQuoteSuccess, resetChangeQuoteError, resetChangeQuoteSuccess, resetQuoteError, resetQuoteSuccess, setQuotationToReCreate } from '../Policyv2/Redux/actions';
import PolicyNotes from './Components/PolicyNotes/PolicyNotes';
import AddPolicyNotes from './Components/PolicyNotes/AddPolicyNotes';
import * as documentActions from '../../containers/DocumentGeneration/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import DownloadDocument from '../../components/DownloadDocument/DownloadDocument';
import PreAuth from '../../partner-apps/ktda/PreAuth';
import CreateLOU from '../../partner-apps/ktda/PreAuth/Components/CreateLOU/CreateLOU';
import {
	createLOURequest, getLOUSRequest, setLouToView, resetLouToView, resetCreateLOUFailure,
	resetCreateLOUSuccess, searchDiagnosisRequest, searchHospitalsRequest, EditLOURequest, setLouToEdit, resetLOUEdit, resetLouEditSuccess, resetLouEditError,
	deleteLouRequest, resetDeleteLouSuccess, resetDeleteLouError, setLouToDelete, resetLouToDelete
} from '../../partner-apps/ktda/PreAuth/Redux/actions';
import DisplayLOUS from '../../partner-apps/ktda/PreAuth/Components/DisplayLOUS/DisplayLOUS';
import ViewLOU from '../../partner-apps/ktda/PreAuth/Components/ViewLOU/ViewLOU';
import EditLOU from '../../partner-apps/ktda/PreAuth/Components/EditLOU/EditLOU';
import { getAllPaymentsRequest } from '../CustomerScreen/actions.es6';
import { getCustomerQuotesRequest, getAllPoliciesRequest as getAllV2PoliciesRequest, getAllCustomerPremiumsRequest } from '../PolicyV2CustomerStatus/Redux/actions';
import { getCustomerClaimsDcpRequest } from '../ClaimInitiationv2/Redux/actions';
import { reloadPage } from '../../lib/utils.es6';
import DeleteLOU from '../../partner-apps/ktda/PreAuth/Components/DeleteLOU/DeleteLOU';
import PolicyUpgrade from './Components/PolicyUpgrade/PolicyUpgrade';
import SmsActivity from '../../partner-apps/Konyana/CustomerStatus/Components/SmsActivity/SmsActivity';
import { getSmsActivityRequest } from '../../partner-apps/Konyana/CustomerStatus/Redux/actions';
import AmendPolicy from './Components/AmendPolicy/AmendPolicy.js';

class CustomerStatusv2 extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openRegisterCustomerDialog: false,
			openAddPaymentDialog: false,
			openPolicyNotesDialog: false,
			openCreateLOUDialog: false,
			openEditLOUDialog: true,
			openPolicyToUpgrade: false,
			selectedGroup: {},
			labels: new LocalizedStrings(localisedText),
			louToDownload: null,
			policyToDownload: null,
			expanded: false,
			componentLoadedWithoutCustomerGuid: false,
			componentLoadedWithMsisdn: false,
			policyToUpgrade: null,
			openPolicyAmend: false,
			policyToAmend: null,
		};
	}

	componentWillMount() {
		let customer_guid = this.props.params.customer_guid

		if (customer_guid) {
			this.refreshCustomerStatus(customer_guid);
		} else {
			this.setState({ componentLoadedWithoutCustomerGuid: true })
		}

		const partnerGuid = getPartnerGuid()
		if (!POLICY_V2_PARTNERS.includes(partnerGuid)) {
			hashHistory.push('admin/home');
			return
		}
	}

	componentDidUpdate() {
		if (this.state.componentLoadedWithoutCustomerGuid) {
			let customer_guid = this.props.params.customer_guid
			if (customer_guid) {
				this.refreshCustomerStatus(customer_guid);
				this.setState({ componentLoadedWithoutCustomerGuid: false })
			}
		}


		const customer = this.props.customerData.currentCustomer;
		const componentLoadedWithMsisdn = this.state.componentLoadedWithMsisdn;

		if (customer && !componentLoadedWithMsisdn) {
			this.refreshCustomerStatusWithMsisdn(customer.msisdn)
			this.setState({ componentLoadedWithMsisdn: true })
		}
	}


	refreshCustomerStatus(customer_guid) {
		this.props.dispatch(getCustomer(customer_guid));
		this.props.dispatch(getAllV2PoliciesRequest({ guid: customer_guid }));
		this.props.dispatch(getCustomerQuotesRequest({ guid: customer_guid }));
		this.props.dispatch(getAllCustomerPremiumsRequest({ guid: customer_guid }));
		this.props.dispatch(getAllPaymentsRequest({ guid: customer_guid }));
		this.props.dispatch(getCustomerClaimsDcpRequest({ guid: customer_guid }));
		this.props.dispatch(getLOUSRequest({ customer_guid: customer_guid }));
	}

	refreshCustomerStatusWithMsisdn(msisdn) {
		this.props.dispatch(getSmsActivityRequest(msisdn));
	}



	toggleRegisterCustomerDialog() {
		this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
	}
	addPayment(payment) {
		const payload = {
			paymentData: payment,
			customerGuid: this.props.customerData.currentCustomer.guid,
		};
		this.props.dispatch(addMissingPaymentRequest(payload));
	}
	resetAddPayment() {
		this.props.dispatch(addMissingPaymentReset());
		reloadPage();
	}
	registerCustomer(customerData) {
		this.props.dispatch(registerCustomerRequest(customerData));
	}
	resetRegistrationSuccess() {
		this.props.dispatch(resetRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
	}

	resetRegistrationError() {
		this.props.dispatch(resetRegistration())
	}
	editCustomer(payload) {
		this.props.dispatch(updateCustomerRequest(payload))
	}
	resetAfterSucesfulyEdit() {
		this.props.dispatch(resetUpdateCustomerl());
	}

	resetAfterFailedEdit() {
		this.props.dispatch(resetUpdateCustomerl());
	}

	cancelPolicy(policy) {
		this.props.dispatch(actions.cancelV2PolicyRequest(policy)
		);
	}
	setPolicyToCancel(policy) {
		this.props.dispatch(actions.setPolicyV2ToCancel(policy))
	}
	resetPolicyToCancel(policy) {
		this.props.dispatch(actions.resetPolicyV2ToCancel(policy))
	}
	handleMakeClaim() {
		hashHistory.push('admin/claim-initiation-v2');
	}

	handleCreateQuote() {
		hashHistory.push('admin/create-quote/')
	}

	setQuotationToReCreate(policy) {
		this.props.dispatch(setQuotationToReCreate(policy))
		this.handleCreateQuote()
	}
	togglePolicyNotesDialog() {
		this.setState({ openPolicyNotesDialog: !this.state.openPolicyNotesDialog })
	}
	handlePolicyNotes(payload) {
		this.props.dispatch(actions.policyNotesRequest(payload));
	}

	setPolicyToView(policy) {
		this.props.dispatch(actions.setPolicyNoteToView(policy))
	}

	handlePolicyNotesAdd(payload) {
		this.props.dispatch(actions.policyNotesAddRequest(payload));
	}

	setPolicyNoteToAdd(policy) {
		this.props.dispatch(actions.setPolicyNoteToAdd(policy))
	}


	resetPolicyNoteToAdd(policy) {
		this.props.dispatch(actions.resetPolicyNoteToAdd(policy))
	}

	resetPolicyToView(policy) {
		this.props.dispatch(actions.resetPolicyNoteToView(policy))
	}

	setPolicyToDownloadDocumentFor(policy) {
		this.props.dispatch(documentActions.setDocumentEntity(policy));
	}

	toggleCreateLOUDialog() {
		this.setState({ openCreateLOUDialog: !this.state.openCreateLOUDialog, expanded: true })
	}
	createLou(payload) {
		this.props.dispatch(createLOURequest(payload))

	}
	toggleViewLOUDialog() {
		this.setState({ openViewLOUDialog: !this.state.openViewLOUDialog })
	}
	toggleEditLOUDialog() {
		this.setState({ openEditLOUDialog: !this.state.openEditLOUDialog })
	}

	setLouToView(lou) {
		this.props.dispatch(setLouToView(lou))
	}

	searchhospital(query) {
		this.props.dispatch(searchHospitalsRequest({ query: query }));
	}
	searchDiagnosis(query) {
		this.props.dispatch(searchDiagnosisRequest({ query: query }));
	}

	setLouToEdit(lou) {
		this.props.dispatch(setLouToEdit(lou))
	}
	editLou(payload) {
		this.props.dispatch(EditLOURequest(payload))
	}

	setLouToDelete(lou) {
		this.props.dispatch(setLouToDelete(lou))
	}

	deleteLou(lou) {
		this.props.dispatch(deleteLouRequest(lou))
	}

	togglePolicyUpgradeDialog() {
		this.setState({ openPolicyToUpgrade: !this.state.openPolicyToUpgrade })
	}

	togglePolicyAmendDialog() {
		this.setState({ openPolicyAmend: !this.state.openPolicyAmend })

	}

	render() {
		const templateDocuments = this.props.documentGenerationData.templateDocuments;

		if (this.props.customerData.currentCustomer) {

			return (
				<>
					<PolicyHeader openRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)} />
					<RegisterCustomer
						openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
						toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
						registerCustomer={this.registerCustomer.bind(this)}
						registrationData={this.props.registrationData}
						resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
						resetRegistrationError={this.resetRegistrationError.bind(this)}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3} md={3}>
							<Principal
								customerData={this.props.customerData.currentCustomer}
								showEditButton={true}
								showAutofillButton={false}
								showClaimButton={true}
								handleMakeClaim={this.handleMakeClaim.bind(this)}
								editCustomer={this.editCustomer.bind(this)}
								loader={this.props.customerData.loader}
								customerEditedSuccessfully={this.props.customerData.customer_edited_succesfully}
								customerEditedFailed={this.props.customerData.customer_edited_failed}
								resetAfterSucesfulyEdit={this.resetAfterSucesfulyEdit.bind(this)}
								resetAfterFailedEdit={this.resetAfterFailedEdit.bind(this)}
							/>
						</Grid>
						<Grid item xs={12} sm={9} md={9} style={{ marginTop: "74px" }}>
							<Container>




								<BoxInc
									title="Policies"
									expanded
									hasPermision={hasAuthourity('VIEW_POLICIES')}
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.handleCreateQuote.bind(this)}
										hasPermision={hasAuthourity('BUY_NEW_POLICY')}
									>
										Create New Quote
									</ButtonInc>]}
								>
									<Policies
										togglePolicyNotesDialog={this.togglePolicyNotesDialog.bind(this)}
										togglePolicyUpgradeDialog={this.togglePolicyUpgradeDialog.bind(this)}
										togglePolicyAmendDialog={this.togglePolicyAmendDialog.bind(this)}

										policies={this.props.customerStatusV2Data.policies}
										loading={this.props.customerStatusV2Data.get_policies_loader}
										cancelPolicy={this.cancelPolicy.bind(this)}
										setPolicyToCancel={this.setPolicyToCancel.bind(this)}
										setPolicyToView={this.setPolicyToView.bind(this)}
										setPolicyNoteToAdd={this.setPolicyNoteToAdd.bind(this)}
										setQuotationToReCreate={this.setQuotationToReCreate.bind(this)}

										templateDocuments={this.props.documentGenerationData.templateDocuments}
										setPolicyToDownloadDocumentFor={(policy) => this.setState({ policyToDownload: policy })}
										setPolicyToUpgrade={(policy) => this.setState({ policyToUpgrade: policy })}
										setPolicyToAmend={(policy) => this.setState({ policyToAmend: policy })}
									/>

									<DownloadDocument
										labels={this.state.labels}
										identifier="Policies"
										entityDocument={
											this.props.documentGenerationData.entityDocument
										}
										entity={this.state.policyToDownload}
										resetEntity={() => this.setState({ policyToDownload: null })}
										templates={templateDocuments}
										getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
										getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
										isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
										downloadDocument={guid => {
											this.props.dispatch(
												documentActions.downloadDocumentRequest({
													entity_guid: this.state.policyToDownload.guid,
													template_guid: guid,
												})
											);
										}}
										resetGetTemplateDocuments={() => {
											this.props.dispatch(documentActions.resetGetTemplateDocuments());
										}}
										resetDownloadDocument={() => {
											this.props.dispatch(documentActions.resetDocumentEntity());
										}}
										showDownloadDocumentProgressAlert={
											this.props.documentGenerationData.downloadDocumentLoader
										}
										showDownloadDocumentSuccessAlert={
											this.props.documentGenerationData.downloadDocumentSuccess
										}
										showDownloadDocumentErrorAlert={
											!!this.props.documentGenerationData.downloadDocumentError
										}
										downloadDocumentError={
											this.props.documentGenerationData.downloadDocumentError
										}
										resetDownloadDocumentSuccessAlert={() => {
											this.props.dispatch(
												documentActions.resetDownloadDocumentSuccess()
											);
										}}
										resetDownloadDocumentErrorAlert={() => {
											this.props.dispatch(
												documentActions.resetDownloadDocumentError()
											);
										}}

										goToTemplatesPage={() => {
											hashHistory.push('admin/view_doc_templates');
										}}
									/>


									<PolicyNotes
										close={() => { this.props.dispatch(actions.resetPolicyNoteToView()) }}
										open={!!this.props.customerStatusV2Data.policyToView}
										policy={this.props.customerStatusV2Data.policyToView}
									/>

									<AddPolicyNotes
										handlePolicyNotesAdd={this.handlePolicyNotesAdd.bind(this)}
										policy={this.props.customerStatusV2Data.policyNotesToAdd}


										close={() => { this.props.dispatch(actions.resetPolicyNoteToAdd()) }}
										open={!!this.props.customerStatusV2Data.policyNotesToAdd}

										showAddPolicyNoteProgressAlert={this.props.customerStatusV2Data.policyNotesToAddLoader}
										showAddPolicyNoteSuccessAlert={this.props.customerStatusV2Data.policyNotesToAddSuccess}
										showAddPolicyNoteErrorAlert={!!this.props.customerStatusV2Data.policyNotesToAddError}
										AddPolicyNoteError={this.props.customerStatusV2Data.policyNotesToAddError}

										resetAddPolicyNoteSuccessAlert={() => {
											this.props.dispatch(actions.resetPolicyNotesAddSuccess());
											reloadPage();
										}
										}
										resetAddPolicyNoteErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesAddError()) }}

										getAllPolicies={() => { this.props.dispatch(actions.getAllPoliciesRequest({ guid: this.props.customerData.currentCustomer.guid })) }}
										resetPolicyNoteToAdd={this.resetPolicyNoteToAdd.bind(this)}

									/>

									<CancelPolicy

										handlePolicyNotes={this.handlePolicyNotes.bind(this)}

										closeCancelPolicyDialog={() => { this.props.dispatch(actions.resetPolicyV2ToCancel()) }}
										openPolicyCancelDialog={!!this.props.customerStatusV2Data.policyToCancel}

										policy={this.props.customerStatusV2Data.policyToCancel}
										cancelPolicy={this.cancelPolicy.bind(this)}
										resetPolicyToCancel={this.resetPolicyToCancel.bind(this)}

										showCancelPolicyProgressAlert={this.props.customerStatusV2Data.policyNotesLoader}
										showCancelPolicySuccessAlert={this.props.customerStatusV2Data.policyNotesSuccess}
										showCancelPolicyErrorAlert={!!this.props.customerStatusV2Data.policyNotesError}
										cancelPolicyError={this.props.customerStatusV2Data.policyNotesError}

										resetCancelPolicySuccessAlert={() => { this.props.dispatch(actions.resetPolicyNotesSuccess()) }}
										resetCancelPolicyErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesError()) }}

										getAllPolicies={() => { this.props.dispatch(actions.getAllPoliciesRequest({ guid: this.props.customerData.currentCustomer.guid })) }}
									/>
									<PolicyUpgrade
										open={this.state.openPolicyToUpgrade}
										close={this.togglePolicyUpgradeDialog.bind(this)}
										policy={this.state.policyToUpgrade}

										// create quote props
										createQuote={(quote) => {
											this.props.dispatch(createQuoteRequest(quote))
										}}
										createdQuote={this.props.quoteData.createdQuote}
										showAddQuoteProgressAlert={this.props.quoteData.createQuoteLoader}
										resetAddQuoteSuccessAlert={() => { this.props.dispatch(resetQuoteSuccess()) }}
										resetAddQuoteErrorAlert={() => { this.props.dispatch(resetQuoteError()) }}
										showAddQuoteErrorAlert={!!this.props.quoteData.createQuoteError}

										//change quote policy props
										changeQuote={(quote) => {
											this.props.dispatch(changeQuoteRequest(quote))
										}}
										changeQuoteProgressAlert={this.props.quoteData.changeQuoteLoader}
										changeQuoteSuccessAlert={this.props.quoteData.changeQuoteSuccess}
										changedQuote={this.props.quoteData.changeQuote}
										changeQuoteErrorAlert={!!this.props.quoteData.changeQuoteError}
										changeQuoteError={this.props.quoteData.changeQuoteError}

										resetChangeQuoteSuccessAlert={() => { this.props.dispatch(resetChangeQuoteSuccess()) }}
										resetChangeQuoteErrorAlert={() => { this.props.dispatch(resetChangeQuoteError()) }}

										// add policy notes props
										addPolicyNote={this.handlePolicyNotesAdd.bind(this)}
										showAddPolicyNoteProgressAlert={this.props.customerStatusV2Data.policyNotesToAddLoader}
										showAddPolicyNoteSuccessAlert={this.props.customerStatusV2Data.policyNotesToAddSuccess}
										showAddPolicyNoteErrorAlert={!!this.props.customerStatusV2Data.policyNotesToAddError}
										AddPolicyNoteError={this.props.customerStatusV2Data.policyNotesToAddError}

										resetAddPolicyNoteSuccessAlert={() => {
											this.props.dispatch(actions.resetPolicyNotesAddSuccess());
											reloadPage();
										}
										}
										resetAddPolicyNoteErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesAddError()) }}

									/>
									<AmendPolicy

										open={this.state.openPolicyAmend}
										close={this.togglePolicyAmendDialog.bind(this)}
										policy={this.state.policyToAmend}

										// create quote props
										createQuote={(quote) => {
											this.props.dispatch(createQuoteRequest(quote))
										}}
										createdQuote={this.props.quoteData.createdQuote}
										// changeQuoteProgressAlert={this.props.quoteData.changeQuoteLoader}
										// changeQuoteSuccessAlert={this.props.quoteData.changeQuoteSuccess}
										showAddQuoteProgressAlert={this.props.quoteData.createQuoteLoader}
										resetAddQuoteSuccessAlert={() => { this.props.dispatch(resetQuoteSuccess()) }}
										resetAddQuoteErrorAlert={() => { this.props.dispatch(resetQuoteError()) }}
										showAddQuoteErrorAlert={!!this.props.quoteData.createQuoteError}

										//amend quote policy props
										amendQuote={(quote) => {
											this.props.dispatch(amendQuoteRequest(quote))
										}}



										amendQuoteProgressAlert={this.props.quoteData.amendQuoteLoader}
										amendQuoteSuccessAlert={this.props.quoteData.amendQuoteSuccess}
										amendedQuote={this.props.quoteData.amendQuote}
										amendQuoteErrorAlert={!!this.props.quoteData.amendQuoteError}
										amendQuoteError={this.props.quoteData.amendQuoteError}
										resetAmendQuoteSuccessAlert={() => { this.props.dispatch(resetAmendQuoteSuccess()) }}
										resetAmendQuoteErrorAlert={() => { this.props.dispatch(resetAmendQuoteError()) }}

										// add policy notes props
										addPolicyNote={this.handlePolicyNotesAdd.bind(this)}
										showAddPolicyNoteProgressAlert={this.props.customerStatusV2Data.policyNotesToAddLoader}
										showAddPolicyNoteSuccessAlert={this.props.customerStatusV2Data.policyNotesToAddSuccess}
										showAddPolicyNoteErrorAlert={!!this.props.customerStatusV2Data.policyNotesToAddError}
										AddPolicyNoteError={this.props.customerStatusV2Data.policyNotesToAddError}
									/>
								</BoxInc>

								<BoxInc
									title="Quotes"
									hasPermision={hasAuthourity('VIEW_QUOTES')}
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.handleCreateQuote.bind(this)}
										hasPermision={hasAuthourity('BUY_NEW_POLICY')}
									>
										Create New Quote
									</ButtonInc>]}
								>
									<Quotes
										quotes={this.props.customerStatusV2Data.quotes}
										loading={this.props.customerStatusV2Data.get_quotes_loader}
									/>
								</BoxInc>

								<BoxInc
									title="Premiums"
									hasPermision={hasAuthourity('VIEW_PREMIUMS')}
								>
									<Premiums
										premiums={this.props.customerStatusV2Data.premiums}
										policies={this.props.customerStatusV2Data.policies}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
										loading={this.props.customerStatusV2Data.get_premiums_loader}
									/>

								</BoxInc>

								<BoxInc
									expanded={this.state.expanded}
									title="Payments"
									hasPermision={hasAuthourity('VIEW_PAYMENTS')}
									setExpanded={(expanded) => {
										this.setState(prevState => ({
											expanded: expanded,
										}))
									}}
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={(event) => {
											event.stopPropagation()
											this.setState(prevState => ({
												openAddPaymentDialog: true,
												expanded: true
											}))

										}}
										hasPermision={hasAuthourity('PREMIUM_UPDATE')}
									>
										Make Payment
									</ButtonInc>]}
								>
									<Payments
										payments={this.props.customerScreenData.payments}
										premiums={this.props.customerStatusV2Data.premiums}
										loading={this.props.customerScreenData.get_payments_loader}
										policies={this.props.customerStatusV2Data.policies}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
									/>

									<MakePayment
										policies={this.props.customerStatusV2Data.policies || []}
										openAddPaymentDialog={this.state.openAddPaymentDialog}
										handleCloseAddPaymentDialog={() => {
											this.setState({
												openAddPaymentDialog: false
											})
										}}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentProgressAlert={this.props.customerStatus.add_payment_loader}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
									/>

								</BoxInc>

								<BoxInc
									title="Letters of Undertaking"
									expanded={false}
									hasPermision={hasAuthourity('VIEW_LOUS')}
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.toggleCreateLOUDialog.bind(this)}
										hasPermision={hasAuthourity('LOU_CREATE')}
									>
										Create LOU
									</ButtonInc>]}
								>
									<PreAuth>

										<DisplayLOUS
											toggleViewLOUDialog={this.toggleViewLOUDialog.bind(this)}
											toggleEditLOUDialog={this.toggleEditLOUDialog.bind(this)}
											lous={this.props.LouData.lous}
											loader={this.props.LouData.getLousLoader}
											templateDocuments={templateDocuments}
											setPolicyToDownloadDocumentFor={(lou) => this.setState({ louToDownload: lou })}
											setLouToView={this.setLouToView.bind(this)}
											setLouToEdit={this.setLouToEdit.bind(this)}
											setLouToDelete={this.setLouToDelete.bind(this)}
										/>
										<CreateLOU
											open={this.state.openCreateLOUDialog}
											close={this.toggleCreateLOUDialog.bind(this)}
											createLou={this.createLou.bind(this)}
											searchhospital={this.searchhospital.bind(this)}
											searchedHospitals={this.props.LouData.searchedHospitals}
											searchDiagnosis={this.searchDiagnosis.bind(this)}
											searchedDiagnosis={this.props.LouData.searchedDiagnosis}
											policies={this.props.customerStatusV2Data.policies}

											showCreateLOUProgressAlert={this.props.LouData.createLouLoader}
											showCreateLOUSuccessAlert={this.props.LouData.createLouSuccess}
											showCreateLOUErrorAlert={!!this.props.LouData.createLouError}
											error={this.props.LouData.createLouError}

											resetCreateLOUSuccessAlert={() => { this.props.dispatch(resetCreateLOUSuccess()) }}
											resetCreateLOUFailureAlert={() => { this.props.dispatch(resetCreateLOUFailure()) }}

											lous={this.props.LouData.lous}
										/>
										<ViewLOU
											close={() => { this.props.dispatch(resetLouToView()) }}
											open={!!this.props.LouData.louToView}
											lou={this.props.LouData.louToView}

										/>
										<EditLOU
											open={this.state.openEditLOUDialog}
											close={() => { this.props.dispatch(resetLOUEdit()) }}
											policies={this.props.customerStatusV2Data.policies}
											louToEdit={this.props.LouData.louToEdit}
											editLou={this.editLou.bind(this)}

											showEditLOUProgressAlert={this.props.LouData.loader}
											showEditLOUSuccessAlert={this.props.LouData.editLouSuccess}
											showEditLOUErrorAlert={!!this.props.LouData.editLouErrors}
											error={this.props.LouData.editLouErrors}

											resetEditLOUSuccessAlert={() => { this.props.dispatch(resetLouEditSuccess()) }}
											resetEditLOUFailureAlert={() => { this.props.dispatch(resetLouEditError()) }}
										/>

										<DeleteLOU
											lou={this.props.LouData.louToDelete}
											deleteLou={this.deleteLou.bind(this)}
											resetLouToDelete={() => { this.props.dispatch(resetLouToDelete()) }}

											showDeleteLouProgressAlert={this.props.LouData.deleteLouLoader}
											showDeleteLouSuccessAlert={this.props.LouData.deleteLouSuccess}
											showDeleteLouErrorAlert={!!this.props.LouData.deleteLouError}
											deleteLouError={this.props.LouData.deleteLouError}

											resetDeleteLouSuccessAlert={() => { this.props.dispatch(resetDeleteLouSuccess()) }}
											resetDeleteLouErrorAlert={() => { this.props.dispatch(resetDeleteLouError()) }}
										/>

										<DownloadDocument
											labels={this.state.labels}
											identifier="LOU"
											entityDocument={
												this.props.documentGenerationData.entityDocument
											}
											entity={this.state.louToDownload}
											resetEntity={() => this.setState({ louToDownload: null })}
											templates={templateDocuments}
											getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('LOU'))}
											getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
											isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
											downloadDocument={guid => {
												this.props.dispatch(
													documentActions.downloadDocumentRequest({
														entity_guid: this.state.louToDownload.guid,
														template_guid: guid,
													})
												);
											}}
											resetGetTemplateDocuments={() => {
												this.props.dispatch(documentActions.resetGetTemplateDocuments());
											}}
											resetDownloadDocument={() => {
												this.props.dispatch(documentActions.resetDocumentEntity());
											}}
											showDownloadDocumentProgressAlert={
												this.props.documentGenerationData.downloadDocumentLoader
											}
											showDownloadDocumentSuccessAlert={
												this.props.documentGenerationData.downloadDocumentSuccess
											}
											showDownloadDocumentErrorAlert={
												!!this.props.documentGenerationData.downloadDocumentError
											}
											downloadDocumentError={
												this.props.documentGenerationData.downloadDocumentError
											}
											resetDownloadDocumentSuccessAlert={() => {
												this.props.dispatch(
													documentActions.resetDownloadDocumentSuccess()
												);
											}}
											resetDownloadDocumentErrorAlert={() => {
												this.props.dispatch(
													documentActions.resetDownloadDocumentError()
												);
											}}

											goToTemplatesPage={() => {
												hashHistory.push('admin/view_doc_templates');
											}}
										/>

									</PreAuth>



								</BoxInc>

								<BoxInc
									title="Claims"
									hasPermision={hasAuthourity('VIEW_CLAIMS')}
								>
									<Claims
										customerClaimsDcp={this.props.claimInitiationData.customer_claims_dcp}
									/>
								</BoxInc>

								<BoxInc
									title="SMS Activity"
									hasPermision={hasAuthourity('VIEW_SMS_ACTIVITIES')}
								>
									<SmsActivity
										smses={this.props.customerStatusV2Data.smsActivities}
									/>
								</BoxInc>

							</Container>
						</Grid>

					</Grid>

				</>
			)
		} else {
			return (
				<>
					<PolicyHeader openRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)} />
					<RegisterCustomer
						openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
						toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
						registerCustomer={this.registerCustomer.bind(this)}
						registrationData={this.props.registrationData}
						resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
						resetRegistrationError={this.resetRegistrationError.bind(this)}
					/>
				</>
			)
		}
	}
}
export default connect((state) => ({
	documentGenerationData: state.documentGeneration,
	claimInitiationData: state.claimInitiationData,
	customerStatusV2Data: state.customerStatusV2Data,
	customerStatus: state.customerStatus,
	customerScreenData: state.customerScreen,
	quoteData: state.quoteData,
	customerData: state.currentCustomer,
	registrationData: state.customerRegistration,
	globalData: state.global,
	LouData: state.LouData,
}))(CustomerStatusv2);