import * as React from 'react';
import { hashHistory } from 'react-router';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';


//components
import UserProfile from '../UserProfile/UserProfile';
import BboxxPolicies from '../Policies/index'
import BbboxxQuotes from '../Quotes/index'
import BboxxClaims from '../Claims/index'
import BboxxCustomerAudits from '../Audits/index'
import { hasAuthourity } from '../../../../../lib/access.es6';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NavigationWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const makeAClaim = () => {
    hashHistory.push('admin/claim-initiation-v2');

  }
  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: "auto" }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "18%" }}
          className={classes.tabs}
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Policies" {...a11yProps(1)} />
          <Tab label="Quotes" {...a11yProps(2)} />
          <Tab label="Claims"  {...a11yProps(3)} />
          <Tab label={"Audits "}  {...a11yProps(4)} />

        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.customerProfile}</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end",
                display: hasAuthourity('EDIT_BBOX_CUSTOMER') ? "block" : "none"

              }}
            >
              <ButtonInc
                onClick={props.toggleEditCustomerDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                }}
              >
                <span style={{ color: "#000000" }}>{props.labels.editUser}</span>
                <EditOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>


          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <UserProfile
                customerData={props.customerData}
                loading={props.loading}
                labels={props.labels}
                getAgentDetails={props.getAgentDetails}
                profileData={props.profileData}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Policies</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BboxxPolicies />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Quotes</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BbboxxQuotes />
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={3} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Claims</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end",
                display: hasAuthourity('INITIATE_CLAIM') ? "block" : "none"
              }}
            >
              <ButtonInc
                onClick={makeAClaim}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",

                }}
              >
                <span style={{ color: "#000000" }}>{"Make a claim"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>


          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BboxxClaims />
            </Grid>
          </Grid>
        </TabPanel>




        <TabPanel value={value} index={4} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Audits</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BboxxCustomerAudits

              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  )
}

export default NavigationWrapper