import * as types from "../../../../../../lib/actionTypes.es6"



export const getCustomerAuditsDcpRequest = (guid) =>{
  return{
    type: types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_REQUEST,
    payload: guid,
  }
}

export const getCustomerAuditsDcpSuccess = (payload) =>{
  return{
    type: types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_SUCCESS,
    payload: payload,
  }
}

export const getCustomerAuditsDcpFailure = (error) =>{
  return{
    type: types.GET_CUSTOMER_AUDITS_PROCESSING_DCP_FAILURE,
    payload: error,
  }
}