/*
 * This is the main entry point for the Javascript application.
 */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import * as Sentry from "@sentry/react";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import orange from '@material-ui/core/colors/orange';
import routes from './routes';
import { store } from './store.es6';
import * as serviceWorker from './serviceWorker';
import { getEnviroment, getEnvironmentInformation } from './lib/utils.es6';
import { getPartnerGuid, getUserEmail, getUserFullname, getUserGuid } from './lib/access.es6';
import posthog from 'posthog-js';


// Sentry.init({
//   dsn: "https://40c8c67b8132a4a88bd4e2672e7bd71f@o4507351677403136.ingest.de.sentry.io/4507352904433744",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
    
//     /^https:\/\/inclusivity-aspin\.com\/.*/,
//     /^https:\/\/engine\.inclusivity-aspin\.com\/.*/,
//     /^https:\/\/api\.inclusivity-aspin\.com\/.*/,
//     /^https:\/\/auth\.inclusivity-aspin\.com\/.*/,

//     /^https:\/\/staging\.inclusivity-aspin\.com\/.*/,
//     /^https:\/\/engine.staging\.inclusivity-aspin\.com\/.*/,
//     /^https:\/\/auth\.staging.aspin-inclusivity\.com\/.*/


//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Sentry.setUser({
//   email: getUserEmail(),
//   id: getUserGuid(),
//   parnter: getPartnerGuid(),
// });


const enviroment = getEnviroment();
const isOnProdEnvironment = ['prod'].includes(enviroment);

if (isOnProdEnvironment) {
  //initialize post hog
  posthog.init("phc_cRjiVgmQMXF94mFG5co5wt13ZbL5slKTZ2esJ4ImI94", {
    api_host: "https://app.posthog.com",
    //capture_pageview: false
  })
}




// react dev tool browser is useful for debugging
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(store);
}
// #FF8349

const orange700 = orange['700'];
const orange400 = orange['400'];

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#ff8349',
    primary2Color: orange700,
    pickerHeaderColor: orange400,
  },
});

function App() {

  if (isOnProdEnvironment) {
    const [identified, setIdentified] = useState(false);

    useEffect(() => {
      if (!identified) {
        posthog.identify(
          getUserGuid(),
          {
            email: `${getUserEmail()}`,
            name: `${getUserFullname()}`,
            partner: `${getPartnerGuid()}`

          }
        );
        setIdentified(true);
      }
    }, [identified]);
  }

  const EnvironmentalInformation = getEnvironmentInformation();
  if (!EnvironmentalInformation) {
    return (
      <center>
        <h1 style={{ color: 'red' }}>
          {' '}
          Environment could not be determined, contact administrator
        </h1>
      </center>
    );
  }
  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>{routes}</Provider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

ReactDOM.render(
  // pass two args, JSX and the app element
  <App />,
  document.getElementById('root') // where to render
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
