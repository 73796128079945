import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import customeravatar from '../../../../Assets/customeravatar.png'
import Button from '@mui/material/Button';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';
import { getPartnerGuid, hasAuthourity } from '../../../../lib/access.es6';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { formatDateLocale } from '../../../../lib/utils.es6';
import ButtonInc from '../../../../shared-ui/ButtonInc';

const Principal = (props) => {
	const classes = useStyle();

	if (!props.customerData) {
		return noPrincipalFound(classes)
	}

	const [open, setOpen] = useState(false);
	const [nationalid, setnationalID] = useState(props.customerData.national_id);
	const [phonenumber, setPhonenumber] = useState(props.customerData.msisdn);
	const [fullname, setFullname] = useState(props.customerData.full_name);
	const [dateofbirth, setDatebirth] = useState(props.customerData.date_of_birth)

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "nationalid") {
			setnationalID(value)
		}
		if (name === "phonenumber") {
			setPhonenumber(value)
		}
		if (name === "fullname") {
			setFullname(value)
		}
		if (name === "dateofbirth") {
			setDatebirth(value)
		}

	}
	const handleEditUser = () => {
		const payload = {
			"guid": props.customerData.guid,
			"date_of_birth": dateofbirth,
			"msisdn": phonenumber,
			"full_name": fullname,
			"national_id": nationalid,
			"beneficiary_name": '',
			"partner_guid": getPartnerGuid(),
		}
		console.log("edit payload", payload);
		props.editCustomer(payload);
	}



	const resetAfterSucesfulyEdit = () =>{
		props.resetAfterSucesfulyEdit();
		handleClose();
  }

  const resetAfterFailedEdit = () =>{
		props.resetAfterFailedEdit();
		handleClose();
  }

	if (props.customerData) {
		return (
			<>
				<div className={classes.principletitle}>
					<h2>Principal</h2>
				</div>
				<div className={classes.principalwrapper}>
					<div className={classes.customeravatar}>
						<img src={customeravatar} alt="customer avatar" />
					</div>
					<div className={classes.principalname}>
						<h3>{props.customerData.full_name}</h3>
					</div>
					<div className={classes.principaldetails}>
						<h4>Details</h4>
						<p><strong>Gender: </strong> {props.customerData.gender || "N/A"}</p>
						<p><strong>National ID : </strong>{props.customerData.national_id || "N/A"}</p>
						<p><strong> Phone Number : </strong>{props.customerData.msisdn || "N/A"}</p>
						<p><strong> Date Of Birth  : </strong>{formatDateLocale(props.customerData.date_of_birth) || "N/A"}</p>
						<p><strong> Registration Channel : </strong> {props.customerData.registration_channel || "N/A"}</p>
						<p><strong> Created  : </strong>{formatDateLocale(props.customerData.created_at) || "N/A"}</p>
					</div>
					<div className={classes.editprincipalbtn}>
						{props.showEditButton && <Button variant="contained" endIcon={<CreateOutlinedIcon />} onClick={handleClickOpen}
							style={{marginRight: '1%'}}
						>
							Edit Details
						</Button>}

						{props.showAutofillButton && <Button variant="contained" endIcon={<ExitToAppIcon />} onClick={()=> {props.toggleAutoFillPrincipal()}}
						
						>
							Auto fill 
						</Button>}
						{props.showClaimButton && <ButtonInc 
						variant="contained" 
						endIcon={<NoteAltOutlinedIcon />} 
						onClick={()=> {props.handleMakeClaim()}}
						hasPermision={hasAuthourity('CREATE_CLAIM')}
						>
							Make  Claim
						</ButtonInc>}
					</div>
				</div>
				<section>
				<AlertDialog
          custom
          show={props.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>updating customer</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!props.customerEditedSuccessfully}
          size="sm"
          title={'Customer details updated'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => {resetAfterSucesfulyEdit() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>


        <AlertDialog
          show={!!props.customerEditedFailed}
          danger
          title={'Error updating customer details'}
          onConfirm={() => { resetAfterFailedEdit() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.errors ? props.errors.message : ''}
        </AlertDialog>
					<Dialog open={open} onClose={handleClose}>
						<DialogTitle className={classes.paymentmodaltitle}>
							<div >
								<h3>Edit Customer Information</h3>
							</div>
						</DialogTitle>
						<DialogContent className={classes.paymentinputwrapper}>
							<DialogContentText>
								Fill the below details to edit customer information
							</DialogContentText>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6}>
									<TextField
										required
										name='nationalid'
										id="nationalid"
										label="National Id"
										value={nationalid}
										onChange={handleChange}
										fullWidth
										className={classes.paymentinputs}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<TextField
										required
										name='phonenumber'
										id="phonenumber"
										label="Phone Number"
										value={phonenumber}
										onChange={handleChange}
										fullWidth
										className={classes.paymentinputs}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<TextField
										required
										name='fullname'
										id="fullname"
										label="Full Name"
										value={fullname}
										onChange={handleChange}
										fullWidth
										className={classes.paymentinputs}
									/>

								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<TextField
										required
										name='dateofbirth'
										id="dateofbirth"
										label="date of Birth"
										value={dateofbirth}
										onChange={handleChange}
										fullWidth
										type='date'
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</Grid>

						</DialogContent>
						<DialogActions className={classes.paymentbtns}>
							<Button variant="contained" onClick={handleEditUser}>Update User</Button>
							<Button variant="outlined" onClick={handleClose}>Cancel</Button>
						</DialogActions>
					</Dialog>
				</section>
			</>
		)
	} else {
		return (
			<>
				<div className={classes.noresultwrapper}>
					<div className={classes.principletitle}>
						<div className={classes.principalwrapper}>
							<div className={classes.principalwrapper}>
								<h4> No current user data</h4>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

}

export default Principal


function noPrincipalFound(classes) {
	return <>
		<div className={classes.noresultwrapper}>
			<div className={classes.principletitle}>
				<div className={classes.principalwrapper}>
					<div className={classes.principalwrapper}>
						<h4> No current user data</h4>
					</div>
				</div>
			</div>
		</div>
	</>;
}