import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, InputAdornment } from '@mui/material';
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ButtonInc from '../../../../../../../shared-ui/ButtonInc';
import { BankData } from '../../../../../lib/BankData';
import { getPartner } from '../../../../../../../lib/access.es6';
import ModalInc from '../../../../../../../shared-ui/ModalInc'
import AlertDialog from '../../../../../../../components/AlertDialog/index.es6';



const formatDateToDDMMYY = (isoDate) => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const EditDebitOrder = (props) => {

  if (!props.debitOrder) {
    return ""
  }

  const classes = useStyle();
  const [accountHolder, setAccountHolder] = useState(props.debitOrder.account_holder_name)
  const [bankdetails] = useState(BankData);
  const [banks, setBanks] = useState('')
  const [branchDetails, setBranchDetails] = useState([]);
  const [branches, setBranches] = useState('')
  const [branchCode, setBranchCode] = useState(props.debitOrder.branch_code);
  const [AccountNumber, setAccountNumber] = useState(props.debitOrder.bank_account_number);
  const [debitOrderStartDate, setDebitOrderStartDate] = useState('')
  const [debitOrderDay, setDebitOrderDay] = useState(props.debitOrder.at)
  const [amount, setAmount] = useState(props.debitOrder.amount_in_cents);
  const [acountNumberError, setAcountNumberError] = useState(null)

  const currencyCode = getPartner().country.currency_code

  useEffect(() => {
    if (props.debitOrder.bank_name) {
      const selectedBank = bankdetails.find(bank => bank.bank_name === props.debitOrder.bank_name);
      if (selectedBank) {
        setBanks(selectedBank);
        setBranchDetails(selectedBank.branch_details);
      }
    }
  }, [props.debitOrder.bank_name, bankdetails]);

  useEffect(() => {
    if (props.debitOrder.branch_name) {
      const selectedBranch = branchDetails.find(branch => branch.name === props.debitOrder.branch_name);
      if (selectedBranch) {
        setBranches(selectedBranch);
        setBranchCode(selectedBranch.code);
      }
    }
  }, [props.debitOrder.branch_name, branchDetails]);

  useEffect(() => {
    if (props.debitOrder.start_date) {
      const formattedDate = formatDateToDDMMYY(props.debitOrder.start_date);
      console.log('Formatted Start Date:', formattedDate);
      setDebitOrderStartDate(formattedDate);
    }
  }, [props.debitOrder.start_date]);



  useEffect(() => {

    ValidatorForm.addValidationRule('isValidAccountNumber', value => {
      if (!banks) {
        setAcountNumberError('please choose a bank first')
        return false
      }

      const acountNumberLength = banks.digit_account
      if (value.length === acountNumberLength) {
        return true
      } else {
        setAcountNumberError(`Please enter a ${acountNumberLength} digit account number`)
      }


    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidAccountNumber');
    };
  }, [AccountNumber, banks])


  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'accountHolder':
        setAccountHolder(value);
        break;
      case 'bankName':
        setBanks(value);
        const selectedBank = bankdetails.find(bank => bank.bank_name === value.bank_name);
        if (selectedBank) {
          setBranchDetails(selectedBank.branch_details);
        }
        break;
      case 'branchName':
        setBranches(value);
        const selectedBranch = branchDetails.find(branch => branch.name === value.name);
        if (selectedBranch) {
          setBranchCode(selectedBranch.code);
        }
        break;
      case 'branchCode':
        setBranchCode(value);
        break;
      case 'AccountNumber':
        setAccountNumber(value);
        break;
      case 'debitOrderStartDate':
        setDebitOrderStartDate(value);
        if (value) {
          const getDebitDay = value.slice(-2);
          setDebitOrderDay(parseInt(getDebitDay, 10));
        }
        break;
      case 'debitOrderDay':
        setDebitOrderDay(value);
        break;
      case 'amount':
        setAmount(value);
        break;
      default:
        break;
    }
  };

  const reset = () => {
    setAccountHolder('');
    setBanks('');
    setBranches('');
    setAccountNumber('');
    setDebitOrderStartDate('');
    setDebitOrderDay('');
    setAmount('');
  }
  const editDebitDetails = () => {
    const payload = {
      "guid": props.debitOrder.guid,
      "start_date": debitOrderStartDate,
      "msisdn": props.customer.currentCustomer.msisdn,
      "bank_account_number": AccountNumber,
      "bank_name": banks.bank_name,
      "branch_code": branchCode,
      "policy_guid": props.policy.guid,
      "mno_reference": props.customer.currentCustomer.msisdn + AccountNumber,
      "granularity": "Month",
      "at": Number(debitOrderDay),
      "amount_in_cents": amount,
      "account_holder_name": accountHolder,
      "branch_name": branches.name,
      "additional_details": {}

    }
    props.editDebitOrder(payload);
    reset()
  }


  return (
    <>
      <AlertDialog
        custom
        show={props.loader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating debit order</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.debitOrderEditedSuccessfully}
        size="sm"
        title={'debit order details updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.resetAfterSucesfulyEdit() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.debitEditedFailed}
        danger
        title={'Error updating debit order details'}
        onConfirm={() => { props.resetAfterFailedEdit() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.errors ? props.errors.message : ''}
      </AlertDialog>

      <section className={classes.registerinputwrapper}>
        <ModalInc
          modalTitle="Edit  Debit"
          subTitle=" Fill the below details to add edit  debit order"
          open={props.openEditDebitOrderDialog}
          onClose={props.toggleEditDebitOrderDialog}
          fullWidth
          maxWidth="md"
        >
          <ValidatorForm onSubmit={() => editDebitDetails()} >

            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="accountHolder"
                  fullWidth
                  label="Account Holder"
                  name='accountHolder'
                  value={accountHolder}
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['required']}
                  errorMessages={['Please add account holder name']}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <SelectValidator
                  labelId="select-bank-name"
                  id="select-bank-name"
                  value={banks}
                  name='bankName'
                  type="text"
                  label="Bank Name"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select bank name']}
                  style={{ width: "100%" }}
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }}
                >
                  {
                    bankdetails.map((bank, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }} value={bank}>{bank.bank_name}</MenuItem>
                    ))
                  }
                </SelectValidator>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <SelectValidator
                  labelId="select-branch-name"
                  id="select-branch-name"
                  value={branches}
                  name='branchName'
                  type="text"
                  label="Branch Name"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select branch name']}
                  style={{ width: "100%" }}
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }}
                >
                  {
                    bankdetails && branchDetails.map((branch, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        value={branch}
                      >
                        {branch.name}
                      </MenuItem>
                    ))
                  }


                </SelectValidator>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="branchCode"
                  fullWidth
                  label="Branch Code"
                  name='branchCode'
                  value={branchCode}
                  disabled
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['required']}
                  errorMessages={['Please add branch code']}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="AccountNumber"
                  fullWidth
                  label="Account Number"
                  name='AccountNumber'
                  type='text'
                  value={AccountNumber}
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['isValidAccountNumber']}
                  errorMessages={[acountNumberError]}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="debitOrderStartDate"
                  label="Debit Order Start Date"
                  name='debitOrderStartDate'
                  value={debitOrderStartDate}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={["Debit order start date is required",]}
                  style={{ width: "100%" }}
                />
              </Grid>
              {/* <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="policy"
                  fullWidth
                  label="Policy"
                  name='policy'
                  type='text'
                  value={policyNumber}
                  disabled
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
              </Grid> */}
              <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="debitOrderDay"
                  fullWidth
                  label="Day"
                  name='debitOrderDay'
                  type='text'
                  disabled
                  value={debitOrderDay}
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['required', 'minNumber:1', 'maxNumber:31']}
                  errorMessages={['This field is required', 'Debit Day must be between 1 to 31', 'Debit Day must be between 1 to 31']}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <TextValidator
                  id="amount"
                  fullWidth
                  label="Amount"
                  name='amount'
                  type='text'
                  value={amount}
                  InputProps={{
                    startAdornment: <InputAdornment
                      sx={{
                        paddingTop: "32px",
                      }}
                      position="start"
                    >{currencyCode ? currencyCode : ''}</InputAdornment>,
                    style: {
                      background: "#F7F7F7"
                    }
                  }}
                  disabled
                  onChange={handleChange}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={6} md={6}>

              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={8}>
                    <ButtonInc
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ marginTop: "20px", float: "right" }}
                      type='submit'
                    >
                      Edit Details
                    </ButtonInc>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ButtonInc
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ marginTop: "20px" }}
                      onClick={props.toggleEditDebitOrderDialog}
                    >
                      Go back
                    </ButtonInc>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </ValidatorForm>
        </ModalInc>
      </section>

    </>
  )
}

export default EditDebitOrder