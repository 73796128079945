import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
 
} from "@mui/material";
import ButtonInc from "../../../shared-ui/ButtonInc";
import { formatDateLocale } from "../../../lib/utils.es6";
import { useStyle } from "./Style";
import { Grid } from '@mui/material';

export const ViewAssessment = ({ assessments, open, onClose }) => {
  const classes = useStyle();
  const [assessment, setAssessment] = React.useState(null);

  useEffect(() => {
    if (assessments.length > 0) {
      const latestAssessment = assessments.reduce((prev, curr) => {
        return new Date(curr.created_at) > new Date(prev.created_at) ? curr : prev;
      });
      setAssessment(latestAssessment);
    } else {
      setAssessment(assessments[0]);
    }
  }, [assessments]);

  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const renderNestedArray = (array) => {
    return (
      <ul>
        {array.map((item, index) => (
          <li key={index}>
            {item}
          </li>
        ))}
      </ul>
    );
  };
  

  const renderResultRows = () => {
    return Object.entries(assessment.result)
      .filter(([key, _]) => key !== 'status')  // Filter out the 'status' key
      .map(([resultKey, resultValue]) => (
        <tr key={resultKey} >
          <td><strong>{formatKey(resultKey)}</strong></td>
          <td>
            {Array.isArray(resultValue) ? renderNestedArray(resultValue) : resultValue}
          </td>
        </tr>
      ));
  };
  
  

  const calculateDuration = () => {
    if (!assessment) return "0s";

    const { created_at, updated_at } = assessment;
    const created = new Date(created_at);
    const updated = new Date(updated_at);
    const duration = (updated - created) / 1000; // duration in seconds
    return `${duration.toFixed(2)}s`;
  };

  const assessmentDuration = calculateDuration();

  return (
    <div>
      {open && assessment && (
        <Dialog open={open} onClose={onClose} maxWidth="md">
          <div className={classes.dialgHeaderWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <h3>ASPin AI Claim Assessor</h3>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className={classes.claimDetailHeading}>
            <Grid item xs={12} md={3} sm={3}>
              <span>Claim Ref</span>
              <p>{assessment.claim_guid}</p>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <span>Assessed at</span>
              <p>{formatDateLocale(assessment.created_at)}</p>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <span>Status</span>
              <p style={{
                color: assessment.result.status === "Succeeded" ? "green" : "red",
              }}>{assessment.result.status}</p>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <span>Assessed duration</span>
              <p style={{ color: "green" }} >{assessmentDuration}</p>
            </Grid>
          </Grid>
          <DialogContent>
            <div className={classes.resultsTable}>
              <table>
              {renderResultRows()}
              </table>
           
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={6}></Grid>
              <Grid item xs={12} md={6} sm={6}>
              <ButtonInc onClick={onClose}  style={{
                float: "right",
                marginRight: "40px",
              }}>
              Close Assessor
            </ButtonInc>
              </Grid>
            </Grid>
        
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
