import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import 'react-table/react-table.css';
import LocalizedStrings from 'react-localization';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { formatStatus } from '../../lib/utils.es6';
import '../../containers/SmsTemplate/sms-template.css'
import { getPartnerGuid, getPartner, hasAuthourity } from '../../lib/access.es6';

export class SmsTemplateCreateUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: new LocalizedStrings(localisedText),
            code: '',
            template: '',
            language: '',
            flash: false,
            systemGenerated: false,
            replyTo: false,
            screen: '',
            selectedParameters: [],
            errorList: [],
            codeUpdate: null,
            templateUpdate: null,
            languageUpdate: null,
            flashUpdate: null,
            systemGeneratedUpdate: null,
            screenUpdate: null,
            replyToUpdate: null,
            anchorEl: null,
            selectedParametersUpdate: null,
            openCreateSmsTemplateDialog: false,
            smsTemplateToDelete: null,
            smsTemplateToUpdate: null,
        };
        this.handleInputChanges = this.handleInputChanges.bind(this)
    }

    componentWillMount() {
        this.updateLanguage();
    }

    componentDidUpdate() {
        this.updateLanguage();
    }

    updateLanguage() {
        updateCurrentLanguage(
            this.props.globalData.language,
            'SmsTemplateCreateUpdate',
            this.state.labels
        );
    }

    getPartnerLanguages() {
        const partner = getPartner()
        return partner.languages
    }

    displayValidationErrors(errorMessage, errorMessage2) {
        const { errorList } = this.state;

        if (!errorList || errorList.length === 0) {
            return ""
        }

        if (errorList.includes(errorMessage)) {
            return errorMessage
        } else if (errorList.includes(errorMessage2)) {
            return errorMessage2
        } else {
            for (let index = 0; index < errorList.length; index++) {
                if (errorList[index].includes(errorMessage)) {
                    return errorList[index]
                }
            }
            return ""
        }
    }

    toggle(event) {
        this.setState({ [event.target.name]: event.target.checked })
    }

    handleInputChanges(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSelectSupportedParameters(parameter) {
        let { selectedParameters } = this.state;
        selectedParameters.push(parameter)
        this.setState({ selectedParameters })
    }

    handleSelectSupportedParametersUpdate(parameter) {
        let { selectedParametersUpdate } = this.state;
        selectedParametersUpdate.push(parameter)
        this.setState({ selectedParametersUpdate })
    }

    handleRemoveParameter(index) {
        let { selectedParameters } = this.state;
        selectedParameters.splice(index, 1)
        this.setState({ selectedParameters })
    }

    handleRemoveParameterUpdate(index) {
        let { selectedParametersUpdate } = this.state;
        selectedParametersUpdate.splice(index, 1)
        this.setState({ selectedParametersUpdate })
    }

    countOfParametersInTemplate() {
        const temp = this.state.template
        const count = (temp.match(/%s/g) || []).length;
        return count;
    }

    countOfParametersInTemplateUpdate() {
        const templateUdpate = this.state.templateUpdate;
        const template = this.props.smsTemplateToUpdate.template

        let temp;
        if (templateUdpate === null) {
            temp = template
        } else {
            temp = templateUdpate
        }

        const count = (temp.match(/%s/g) || []).length;
        return count;
    }
    convertParametersToList(parameters) {
        if (parameters == null) return []
        let parameter = parameters.split(",").join("").split(" ");
        return parameter
    }

    convertParametersListToStringWithCommas(parametersList) {
        return parametersList.join(", ")
    }

    inializeSelectedParams() {
        const op = this.props.smsTemplateToUpdate.parameters
        const parameters = this.convertParametersToList(op)
        if (this.state.selectedParametersUpdate) {
            return this.state.selectedParametersUpdate
        }
        this.setState({ selectedParametersUpdate: parameters })
        return parameters

    }

    resetCreateSmsTemplate() {
        this.setState({
            openCreateSmsTemplateDialog: false,
            code: '',
            template: '',
            language: '',
            flash: false,
            systemGenerated: false,
            replyTo: false,
            screen: '',
            selectedParameters: [],
            errorList: [],
        })
    }
    openDialogCreateSmsTemplate() {
        this.setState({ openCreateSmsTemplateDialog: true })
    }

    updateSmsTemplatePrompt() {
        this.setState({ anchorEl: null })
    }

    resetUpdateSmsTemplate() {
        this.setState({
            codeUpdate: null,
            templateUpdate: null,
            languageUpdate: null,
            flashUpdate: null,
            systemGeneratedUpdate: null,
            screenUpdate: null,
            selectedParametersUpdate: null,
            replyToUpdate: null
        })
    }

    closeDialogUpdateSmsTemplate() {
        this.setState({
            codeUpdate: null,
            templateUpdate: null,
            languageUpdate: null,
            flashUpdate: null,
            replyToUpdate: null,
            systemGeneratedUpdate: null,
            screenUpdate: null,
            selectedParametersUpdate: null,
            errorList: []
        })
        this.props.closeDialogUpdateSmsTemplate()
    }

    prepareCreateSmsTemplatePayload() {
        const { code, template, language, screen, flash, systemGenerated, selectedParameters, labels, replyTo } = this.state;
        const errorList = []

        if (!code) {
            errorList.push(labels.codeMustNotBeEmpty)
        }
        if (!template) {
            errorList.push(labels.templateMustNotBeEmpty)
        }
        if (!language) {
            errorList.push(labels.pleaseSelectLanguage)
        }

        if (this.countOfParametersInTemplate() !== selectedParameters.length) {
            errorList.push(labels.paramsDoNotMatch)
        }
        if (errorList.length > 0) {
            this.setState({ errorList })
            return
        }
        this.setState({ errorList })

        const smsTemplate = {
            language,
            partner_guid: getPartnerGuid(),
            template,
            code,
            active: true,
            screen,
            flash,
            system_generated: systemGenerated,
            reply_to: replyTo
        }
        if (this.convertParametersListToStringWithCommas(selectedParameters).length > 0) {
            smsTemplate.parameters = this.convertParametersListToStringWithCommas(selectedParameters)
        }
        this.props.createSmsTemplate(smsTemplate)
        this.resetCreateSmsTemplate()

    }

    prepareUpdateSmsTemplatePayload() {
        const { codeUpdate, templateUpdate, languageUpdate, flashUpdate, systemGeneratedUpdate, screenUpdate, selectedParametersUpdate, labels, replyToUpdate } = this.state
        const { code, template, language, flash, system_generated, screen, parameters, guid, reply_to } = this.props.smsTemplateToUpdate
        const payload = {
            codeUpdate: codeUpdate !== null ? codeUpdate : code,
            templateUpdate: templateUpdate === null ? template : templateUpdate,
            languageUpdate: languageUpdate !== null ? languageUpdate : language,
            flashUpdate: flashUpdate !== null ? flashUpdate : flash,
            systemGeneratedUpdate: systemGeneratedUpdate !== null ? systemGeneratedUpdate : system_generated,
            screenUpdate: screenUpdate || screen,
            selectedParametersUpdate: selectedParametersUpdate || parameters,
            replyToUpdate: replyToUpdate !== null ? replyToUpdate: reply_to
        }

        const errorList = []

        if (payload.codeUpdate.length === 0) {
            errorList.push(labels.codeMustNotBeEmpty)
        }
        if (!payload.templateUpdate) {
            errorList.push(labels.templateMustNotBeEmpty)
        }
        if (!payload.languageUpdate) {
            errorList.push(labels.pleaseSelectLanguage)
        }

        if (this.countOfParametersInTemplateUpdate() !== selectedParametersUpdate.length) {
            errorList.push(labels.paramsDoNotMatch)
        }
        if (errorList.length > 0) {
            this.setState({ errorList })
            return
        }
        this.setState({ errorList })

        const smsTemplate = {
            "code": payload.codeUpdate,
            "template": payload.templateUpdate,
            "active": true,
            "flash": payload.flashUpdate,
            "language": payload.languageUpdate,
            "system_generated": payload.systemGeneratedUpdate,
            "parameters": this.convertParametersListToStringWithCommas(selectedParametersUpdate),
            "partner_guid": getPartnerGuid(),
            "guid": guid,
            "reply_to": payload.replyToUpdate
        }
        console.log("update: ", smsTemplate);
        this.props.updateSmsTemplate(smsTemplate)
        this.resetCreateSmsTemplate()
    }

    createSmsTemplateDialog() {
        const partnerLanguages = this.getPartnerLanguages()
        let languages = partnerLanguages.length > 0 ? partnerLanguages : ['en']
        const labels = this.state.labels
        const smsTemplatePlaceholder = labels.smsTemplatePlaceholder
        let supportedParameters = this.props.supportedParameters

        return <Dialog
            open={this.state.openCreateSmsTemplateDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">{labels.createSmsTemplate}</DialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off">
                    <p style={{ color: "red", marginBottom: "0px" }} > {this.displayValidationErrors(labels.codeMustNotBeEmpty)} </p>
                    <div className="inputStyles"><TextField
                        id="code"
                        name="code"
                        placeholder="Code e.g. KYC_UPDATE"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleInputChanges}
                        value={this.state.code}
                        style={{ marginTop: "0px" }}
                    /></div>
                    <p style={{ color: "red" }} > {this.displayValidationErrors(labels.templateMustNotBeEmpty)} </p>
                    <textarea
                        placeholder={smsTemplatePlaceholder}
                        onChange={this.handleInputChanges}
                        value={this.state.template}
                        name="template"
                    ></textarea>

                    <br />
                    <FormControl style={{ marginRight: "30px", minWidth: "100px" }}>
                        <p style={{ color: "red", marginBottom: "0px", display: (this.state.errorList.includes(labels.pleaseSelectLanguage) ? 'block' : 'none') }} > {this.displayValidationErrors(labels.pleaseSelectLanguage)} </p>
                        <InputLabel id="demo-simple-select-label">{labels.lang}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.language}
                            onChange={this.handleInputChanges}
                            name="language"
                        > {
                                languages.map(language => (
                                    <MenuItem value={language}>{language}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        id="outlined-name"
                        placeholder="screen"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleInputChanges}
                        value={this.state.screen}
                        name="screen"
                    />
                    <FormControlLabel style={{ marginRight: "30px", marginLeft: "30px" }}
                        control={
                            <Checkbox
                                checked={this.state.flash}
                                onChange={this.toggle.bind(this)}
                                value="flash"
                                name="flash"
                                style={{ color: (this.state.flash ? "orange" : '') }}
                            />
                        }
                        label={labels.flash}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.systemGenerated}
                                onChange={this.toggle.bind(this)}
                                value="systemGenerated"
                                name="systemGenerated"
                                style={{ color: (this.state.systemGenerated ? "orange" : '') }}
                            />
                        }
                        label={labels.systemGenerated}
                    />
                    <FormControlLabel
                      control={
                          <Checkbox
                            checked={this.state.replyTo}
                            onChange={this.toggle.bind(this)}
                            value="replyTo"
                            name="replyTo"
                            style={{ color: (this.state.replyTo ? "orange" : '') }}
                          />
                      }
                      label={labels.canReplyTo}
                    />
                    <div style={{ display: this.state.selectedParameters.length > 0 ? 'block' : 'none' }}>
                        <hr></hr>
                        <label>{labels.selectedParameters} - <span style={{ fontSize: "80%", color: "orange" }}> {labels.clickToRemoveParameter}</span></label>
                        <div>

                            {this.state.selectedParameters.map((parameter, index) => (
                                <Chip
                                    key={index}
                                    label={formatStatus(parameter)}
                                    style={{ backgroundColor: "orange", color: "white", margin: "2px" }}
                                    onClick={this.handleRemoveParameter.bind(this, index)} />
                            ))}
                        </div>
                    </div>
                    <hr></hr>
                    <p style={{ color: "red", marginBottom: "0px" }} > {this.displayValidationErrors(labels.paramsDoNotMatch)} </p>
                    <label>{labels.supportedParameters} - <span style={{ fontSize: "80%", color: "orange" }}> {labels.clickToSelectParameter}</span></label>
                    <div >
                        <br />
                        {supportedParameters.map(parameter => (
                            <Chip
                                key={parameter}
                                label={formatStatus(parameter)}
                                style={{ border: "1px solid orange", backgroundColor: "#f3f3f3", color: "#000", margin: "2px" }}
                                onClick={this.handleSelectSupportedParameters.bind(this, parameter)} />
                        ))}
                    </div>
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={this.prepareCreateSmsTemplatePayload.bind(this)} style={{ backgroundColor: "green", color: "white" }} variant="contained">
                    {labels.createSmsTemplate}
                </Button>
                <Button onClick={this.resetCreateSmsTemplate.bind(this)} style={{ backgroundColor: "orange", color: "white" }} variant="contained" autoFocus>
                    {labels.cancel}
                </Button>
            </DialogActions>
        </Dialog>

    }


    updateSmsTemplateDialog(args) {
        const languages = this.getPartnerLanguages()
        const labels = this.state.labels
        const smsTemplatePlaceholder = labels.smsTemplatePlaceholder
        const selectedParameters = this.inializeSelectedParams();
        let supportedParameters = this.props.supportedParameters

        return <Dialog
            open={true}
            onClose={this.closeDialogUpdateSmsTemplate.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">{"Update SMS Template"}</DialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off">
                    <p style={{ color: "red", marginBottom: "0px" }} > {this.displayValidationErrors(labels.codeMustNotBeEmpty)} </p>
                    <TextField
                        id="code"
                        placeholder="Code e.g. KYC_UPDATE"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        name="codeUpdate"
                        onChange={this.handleInputChanges.bind(this)}
                        defaultValue={this.props.smsTemplateToUpdate.code}
                        value={this.state.codeUpdate}
                        style={{ marginTop: "0px" }}
                    />
                    <p style={{ color: "red" }} > {this.displayValidationErrors(labels.templateMustNotBeEmpty)} </p>
                    <textarea
                        placeholder={smsTemplatePlaceholder}
                        name="templateUpdate"
                        onChange={this.handleInputChanges.bind(this)}
                        defaultValue={this.props.smsTemplateToUpdate.template}
                        value={this.state.templateUpdate}
                    ></textarea>

                    <br />
                    <FormControl style={{ marginRight: "30px", minWidth: "100px" }}>
                        <p style={{ color: "red", marginBottom: "0px", display: (this.state.errorList.includes(labels.pleaseSelectLanguage) ? 'block' : 'none') }} > {this.displayValidationErrors(labels.pleaseSelectLanguage)} </p>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="languageUpdate"
                            onChange={this.handleInputChanges.bind(this)}
                            value={(this.state.languageUpdate ||this.props.smsTemplateToUpdate.language)}
                        > {
                                languages.map(language => (
                                    <MenuItem value={language}>{language}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        id="outlined-name"
                        placeholder="screen"
                        margin="normal"
                        variant="outlined"
                        name="screenUpdate"
                        onChange={this.handleInputChanges.bind(this)}
                        defaultValue={this.props.smsTemplateToUpdate.screen}
                        value={this.state.screenUpdate}
                    />
                    <FormControlLabel style={{ marginRight: "30px", marginLeft: "30px" }}
                        control={
                            <Checkbox
                                checked={(this.state.flashUpdate === null ? this.props.smsTemplateToUpdate.flash : this.state.flashUpdate)}
                                onChange={this.toggle.bind(this)}
                                value="flashUpdate"
                                name="flashUpdate"
                                style={{ color: (((this.state.flashUpdate === null ? this.props.smsTemplateToUpdate.flash : this.state.flashUpdate)) ? "orange" : '') }}
                            />
                        }
                        label={labels.flash}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(this.state.systemGeneratedUpdate === null ? this.props.smsTemplateToUpdate.system_generated : this.state.systemGeneratedUpdate)}
                                onChange={this.toggle.bind(this)}
                                value="systemGeneratedUpdate"
                                name="systemGeneratedUpdate"
                                style={{ color: (((this.state.systemGeneratedUpdate === null ? this.props.smsTemplateToUpdate.system_generated : this.state.systemGeneratedUpdate)) ? "orange" : '') }}
                            />
                        }
                        label={labels.systemGenerated}
                    />
                    <FormControlLabel
                      control={
                          <Checkbox
                            checked={(this.state.replyToUpdate === null ? this.props.smsTemplateToUpdate.reply_to : this.state.replyToUpdate)}
                            onChange={this.toggle.bind(this)}
                            value="replyToUpdate"
                            name="replyToUpdate"
                            style={{ color: (((this.state.replyToUpdate === null ? this.props.smsTemplateToUpdate.reply_to : this.state.replyToUpdate)) ? "orange" : '') }}
                          />
                      }
                      label={labels.canReplyTo}
                    />

                    <div style={{ display: (this.state.selectedParametersUpdate === null || this.state.selectedParametersUpdate.length > 0) ? 'block' : 'none' }}>
                        <hr></hr>
                        <label>{labels.selectedParameters} - <span style={{ fontSize: "80%", color: "orange" }}> {labels.clickToRemoveParameter}</span></label>
                        <div>
                            {(selectedParameters).map((parameter, index) => (
                                <Chip
                                    key={index}
                                    label={formatStatus(parameter)}
                                    style={{ backgroundColor: "orange", color: "white", margin: "2px" }}
                                    onClick={this.handleRemoveParameterUpdate.bind(this, index)} />
                            ))}
                        </div>
                    </div>
                    <hr></hr>
                    <p style={{ color: "red", marginBottom: "0px" }} > {this.displayValidationErrors(labels.paramsDoNotMatch)} </p>
                    <label>{labels.supportedParameters} - <span style={{ fontSize: "80%", color: "orange" }}> {labels.clickToSelectParameter}</span></label>
                    <div >
                        <br />
                        {supportedParameters.map(parameter => (
                            <Chip
                                key={parameter}
                                label={formatStatus(parameter)}
                                style={{ border: "1px solid orange", backgroundColor: "#f3f3f3", color: "#000", margin: "2px" }}
                                onClick={this.handleSelectSupportedParametersUpdate.bind(this, parameter)} />
                        ))}
                    </div>
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={this.prepareUpdateSmsTemplatePayload.bind(this)} style={{ backgroundColor: "green", color: "white" }} variant="contained">
                    {labels.updateSmsTemplate}
                </Button>
                <Button onClick={this.closeDialogUpdateSmsTemplate.bind(this)} style={{ backgroundColor: "orange", color: "white" }} variant="contained" autoFocus>
                    {labels.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    }

    render() {
        const labels = this.state.labels;

        if (this.props.type === "create") {
            return (
                <div>

                    {   hasAuthourity('CREATE_SMS_TEMPLATE') ?
                        <Button style={{ backgroundColor: "orange", color: "white", marginLeft: "25px" }} onClick={this.openDialogCreateSmsTemplate.bind(this)}><AddIcon />{labels.createSmsTemplate}</Button> : ''
                    }
                    {
                        this.createSmsTemplateDialog()
                    }
                </div>
            );
        }
        if (this.props.type === "update") { return (<div> {this.updateSmsTemplateDialog()} </div>); }

    }

}
export default (SmsTemplateCreateUpdate);
