import React, { useState, useEffect } from 'react'
import { Box, Grid, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "../../Style";
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper'
import { getPartner, hasAuthourity } from '../../../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../../../lib/utils.es6';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

const DebitOrders = (props) => {

  useEffect(() => {
    const year = new Date().getFullYear();
    const month = new Date().getUTCMonth() + 1;
    const day = new Date().getDate() + 1;
    const currentDate = `${year}-${month}-${day}`;
    const payload = {
      msisdn: props.customer.currentCustomer.msisdn,
      status: 'Active',
      from: '2000-01-1',
      to: currentDate,
    };

    props.getAllDebitOrders(
      payload
    )

  }, [])

  const classes = useStyle();

  const [expanded, setExpanded] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDebitAction, setSelectedDebitAction] = React.useState(null)

  const open = Boolean(anchorEl);

  const handleClick = (event, debit) => {
    setAnchorEl(event.currentTarget);
    setSelectedDebitAction(debit);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const debitOrderDisplay = props.newDebitOrderData 
  const currencyCode = getPartner().country.currency_code


  if (props.debitOrderloader) {
    return <SkeletonWrapper loading={props.debitOrderloader} />

  }


  if (debitOrderDisplay.length > 0) {

    return (
      <>
        {
          debitOrderDisplay.map((debit, index) => (
            <>
              <Grid container className={classes.policyWrapper} key={index} >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{debit.bank_name || "N/A"}</h6>
                          <p> Bank Name</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                          <Box display="flex" justifyContent="flex-end">
                            <ul>
                              <li className={debit.status === "Active" ? classes.activePill : classes.inactivePill}> <span>{debit.status === "Active" ? `Active` : `Inactive`}</span></li>
                              <li className={classes.actionbtnpolicy} style={{
                                display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                              }}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) => handleClick(event, debit)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {
                                  selectedDebitAction && selectedDebitAction.guid === debit.guid && <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",

                                    }} onClick={() => {
                                      setAnchorEl(null);
                                      props.setDebitOrderToEdit(debit)
                                      props.toggleEditDebitOrderDialog();
                                    }} >Edit Debit Order</MenuItem>
                                    <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                    }} onClick={() => { setAnchorEl(null); props.setDebitOrderToDelete(debit) }} >Cancel Debit Order</MenuItem>


                                  </Menu>
                                }

                              </li>
                            </ul>

                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Account Number </p>
                          <h6> {debit.bank_account_number || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> To Be Deducted On </p>
                      <h6>{debit.at || "N/A"}
                      </h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Created At </p>
                      <h6>{formatDateLocale(debit.created_at) || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Granuality </p>
                      <h6>{debit.granularity || "N/A"}</h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={4} sm={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>Phone Number</p>
                              <h6>{debit.msisdn || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                          <p> Start Date</p>
                          <h6>{formatDateLocale(debit.start_date) || "N/A"}</h6>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                          <p> Updated At</p>
                          <h6>{formatDateLocale(debit.updated_at) || "N/A"}</h6>
                        </Grid>

                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>

                        </Grid>
                        <Grid container xs={12} sm={6} md={6}>
                          <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.policyBalance}>
                              <p style={{
                                textAlign: "center"
                              }}>Account Holder </p>
                              <h6 style={{
                                textAlign: "center"
                              }}>{debit.account_holder_name || "N/A"}</h6>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.policyBalance}>
                              <p style={{
                                textAlign: "center"
                              }}>Branch Name</p>
                              <h6 style={{
                                textAlign: "center"
                              }}>{debit.branch_name || "N/A"}</h6>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <div className={classes.policyBalance}>
                              <p>Amount</p>
                              <h6>{`${currencyCode} ${debit.amount_in_cents}` || "0.00"}   </h6>
                            </div>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={4} sm={4}>
                      <div className={classes.policyBalance}>
                        <p>Amount</p>
                        <h6>{`${currencyCode} ${debit.amount_in_cents}` || "0.00"}   </h6>
                      </div>
                    </Grid> */}

                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              debitOrderDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>
      </>
    )
  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default DebitOrders