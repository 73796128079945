import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  customerdatawrapper:{
    borderBottom: "1px solid #CCCCCC",
    "& h2":{
      fontFamily: 'open sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "15px",
      marginTop: "20px"
    },
    "& p":{
      paddingTop: "10px",
      fontFamily: 'open sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      marginBottom: "20px"
    }
  },
  tabs: {
    "& button": {
      fontFamily: 'open sans',
      fontStyle: "normal",
      fontWeight: "400 !important",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      textTransform: "capitalize",
      alignItems: "start"
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#032A37",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#000",
      background: "#F8FAFC",
      fontWeight: "bold !important",
      borderRadius: "8px",
    }
  },
  tabPanels:{
    width: "100%",
  },
  tabheading:{
    borderBottom: "1px solid #CCCCCC",
    "& h1":{
      paddingTop: "10px",
      fontFamily: 'open sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "15px",
    }
    //paddingBottom: "15px"
  }

}));