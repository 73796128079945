import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner } from '../../lib/access.es6';

const ViewPackageDetailsInc = (props) => {
  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code
  const packageDetails = (props.package && props.package.quotation.additional_details) ? props.package.quotation.additional_details.packages : null

  if (props.package && packageDetails) {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} >
            <div className={classes.policieswrapper}>
              <table>
                <tr>
                  <th>Item Label</th>
                  <th> Item Description</th>
                  <th>Item Color</th>
                  <th>Item Quantity</th>
                  <th>Item ImageUrl</th>
                  <th>Package Height Cm</th>
                  <th>package Lenght Cm</th>
                  <th>Package Total Cost</th>
                  <th>Package Weight Kg</th>
                  <th>Package Width Cm</th>

                </tr>

                {
                  packageDetails.map((entry, index) => (
                    (
                      <>
                        <tr key={index}>
                          <td>{entry.itemLabel}</td>
                          <td>{entry.itemDescription}</td>
                          <td>{entry.itemColor}</td>
                          <td> {entry.itemQuantity}</td>
                          <td><a href={entry.itemImageUrl} target='_blank' rel="noreferrer" >View Image</a>  </td>
                          <td> {entry.packageHeightCm}</td>
                          <td> {entry.packageLenghtCm}</td>
                          <td> {currencyCode + " " + entry.packageTotalCost.toLocaleString()}</td>
                          <td> {entry.packageWeightKg}</td>
                          <td> {entry.packageWidthCm}</td>
                        </tr>
                      </>
                    )
                  ))
                }
              </table>
              <br />

            </div>
          </Grid>
        </Grid>
      </>
    )
  } else {
    return (
      <div className={classes.policieswrapper}>No Package Details Found</div>
    )
  }


}

export default ViewPackageDetailsInc